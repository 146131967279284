import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';
import { Row, Col, Card } from 'react-bootstrap';

import useBalance from 'hooks/useBalance';
import useOpenCharges from 'hooks/useOpenCharges';

import { StoreState } from 'store/createStore';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableNoData from 'components/TableNoData';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import Error from 'components/Error';
import PanelCard from 'components/PanelCard';

import formatCurrency from 'utils/formatCurrency';
import filter from 'utils/filter';

import { Container, Info } from './styles';

interface Charge {
  email1: string;
  site: string;
  descricao: string;
  valor: string;
  vencimento: string;
}

const OpenCharges: React.FC = () => {
  const { t } = useTranslation();

  const client = useSelector((state: StoreState) => state.client.info);

  const { balance } = useBalance(client.idCliente, client.tipoCobranca);
  const { charges, loading, error } = useOpenCharges(client.idRepresentante);

  const [openChargesCount, setOpenChargesCount] = useState(0);

  const [filterText, setFilterText] = useState('');

  const [filteredCharges, setFilteredCharges] = useState<Charge[]>([]);

  useEffect(() => {
    setFilteredCharges(
      filter(
        charges,
        ['email1', 'site', 'descricao', 'valor', 'vencimento'],
        filterText,
      ),
    );
  }, [charges, filterText]);

  useEffect(() => {
    const totalOpenCharges = charges.length;

    setOpenChargesCount(totalOpenCharges);
  }, [charges]);

  const columns = [
    {
      name: t('common:customer'),
      selector: 'email1',
      sortable: true,
    },
    {
      name: t('common:site'),
      selector: 'site',
      sortable: true,
    },
    {
      name: t('common:description'),
      selector: 'descricao',
      sortable: true,
      cell: (row: Charge) => {
        const { descricao } = row;
        return <div className="my-2">{descricao}</div>;
      },
    },
    {
      name: t('common:value'),
      selector: 'valor',
      sortable: true,
      maxWidth: '200px',
      cell: (row: Charge) => formatCurrency(parseFloat(row.valor)),
      sortFunction: (a: Charge, b: Charge) =>
        Number(a.valor) > Number(b.valor) ? 1 : -1,
    },
    {
      name: t('common:dueDate'),
      selector: 'vencimento',
      sortable: true,
      maxWidth: '200px',
      cell: (row: Charge) =>
        format(parseISO(row.vencimento), 'dd/MM/yyyy', {
          locale: pt,
        }),
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />

      <Row className="my-4">
        <Col className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('pages:openCharges.balance')}</h6>
                <Info className="blue">
                  {!loading && balance.formattedBalance}
                </Info>
              </div>
            </Card.Body>
          </PanelCard>
        </Col>
        <Col className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('pages:openCharges.charges')}</h6>
                <Info className="blue">{!loading && openChargesCount}</Info>
              </div>
            </Card.Body>
          </PanelCard>
        </Col>
      </Row>

      <TableWrapper>
        <TableHeader
          title={t('pages:openCharges.title')}
          description={t('pages:openCharges.description')}
          helpContent={
            <div>
              <p>{t('pages:openCharges.helpContent.p1')}</p>
            </div>
          }
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>
        <DataTable
          noHeader
          columns={columns}
          data={filteredCharges}
          progressPending={loading}
          progressComponent={<Loading />}
          noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          className="datatables-table"
          pagination
          paginationComponentOptions={{
            rowsPerPageText: t('common:registersPerPage'),
            rangeSeparatorText: t('common:of'),
          }}
        />
      </TableWrapper>
    </Container>
  );
};

export default OpenCharges;
