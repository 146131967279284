import React, { useEffect, useState } from 'react';
import { FaServer } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

import { StoreState } from 'store/createStore';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import Error from 'components/Error';
import TableNoData from 'components/TableNoData';
import TableHeader from 'components/TableHeader';

import useActiveServices from 'hooks/useActiveServices';

import filter from 'utils/filter';
import formatCurrency from 'utils/formatCurrency';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import ActiveServiceActions from './components/ActiveServiceActions';

import { Container } from './styles';

const List: React.FC = () => {
  const { t } = useTranslation();

  const client = useSelector((state: StoreState) => state.client.info);

  const [filterText, setFilterText] = useState('');

  const [toggleCleared, setToggleCleared] = useState(false);

  const [filteredServices, setFilteredServices] = useState<Service[]>([]);

  const [deleting, setDeleting] = useState(false);

  const { services, loading, error } = useActiveServices(
    client.idRepresentante,
  );

  type Service = typeof services[number];

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: Service[];
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  useEffect(() => {
    setFilteredServices(
      filter(
        services,
        ['email1', 'site', 'servico', 'valorFormatado'],
        filterText,
      ),
    );
  }, [services, filterText]);

  async function deleteActiveService() {
    try {
      const acitveServicesIds = selectedRowsInfo.selectedRows.map(
        ({ idClienteServico }) => idClienteServico,
      );

      const { isConfirmed } = await swal.fire({
        icon: 'warning',
        title: t('common:warning'),
        text: t('pages:activeServices.deleteWarning', {
          count: selectedRowsInfo.selectedCount,
        }),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setDeleting(true);

      await api.delete(
        `clientes/v1/representante/${client.idRepresentante}/cliente/servico`,
        {
          params: { idsClientesServicos: acitveServicesIds },
        },
      );

      setToggleCleared(prev => !prev);

      toast.fire(t('pages:activeServices.deleteSuccess'));
    } catch (err) {
      swal.fire({
        title: t('common:label.operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setDeleting(false);
    }
  }

  const columns = [
    {
      name: t('common:customer'),
      selector: 'email1',
      sortable: true,
    },
    {
      name: t('common:site'),
      selector: 'site',
      sortable: true,
    },
    {
      name: t('common:service'),
      selector: 'servico',
      sortable: true,
    },
    {
      name: t('common:value'),
      selector: 'valor',
      sortable: true,
      maxWidth: '200px',
      cell: (row: Service) => formatCurrency(parseFloat(row.valor)),
      sortFunction: (a: Service, b: Service) =>
        Number(a.valor) > Number(b.valor) ? 1 : -1,
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        icon={<FaServer color="#FFFFFF" size={24} />}
        title={t('titles:services.title')}
        description={t('titles:services.description')}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:activeServices.title')}
          description={t('pages:activeServices.description')}
          helpContent={
            <div>
              <p>{t('pages:activeServices.helpContent.p1')}</p>
            </div>
          }
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            clearSelectedRows={toggleCleared}
            columns={columns}
            data={filteredServices}
            progressPending={loading}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            contextComponent={
              <ActiveServiceActions
                loading={deleting}
                deleteActiveService={() => deleteActiveService()}
              />
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            className="datatables-table"
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default List;
