import { useQuery } from 'react-query';

import api from 'services/api';

import formatCurrency from 'utils/formatCurrency';

interface Service {
  idClienteServico: number;
  email1: string;
  site: string;
  servico: string;
  valor: string;
  valorFormatado: string;
}

interface ServiceItemResponse {
  idClienteServico: number;
  email1: string;
  site: string;
  servico: string;
  valor: string;
}

interface ServicesResponse {
  data: ServiceItemResponse[];
}

function formatActiveService(service: ServiceItemResponse): Service {
  return {
    valorFormatado: formatCurrency(Number(service.valor)),
    ...service,
  };
}

async function loadActiveServices(idRepresentante: number): Promise<Service[]> {
  const servicesResponse = await api.get<ServicesResponse>(
    `clientes/v1/representante/${idRepresentante}/cliente/servico`,
    {
      params: {
        idServicoCategoria: 4,
      },
    },
  );

  const services = servicesResponse.data.data.map(formatActiveService);

  return services;
}

const initialData: Service[] = [];

export default function useActiveServices(idRepresentante: number) {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<Service[]>(
    'services',
    () => loadActiveServices(idRepresentante),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const services = data || initialData;

  return {
    services,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
