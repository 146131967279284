import produce from 'immer';

import { ClientAction, ClientState } from './types';

const initialState: ClientState = {
  info: {
    idCliente: 0,
    idEntidadeFinanceira: 0,
    idRepresentante: 0,
    email1: '',
    nome: '',
    responsavel: '',
    cpfCnpj: '',
    tipoCobranca: 'MULTIPLA',
    tipoPessoa: '',
  },
  loading: false,
  error: false,
};

export default function client(
  state = initialState,
  action: ClientAction,
): ClientState {
  return produce(state, draft => {
    switch (action.type) {
      case '@client/CLIENT_SUCCESS':
        draft.info = action.payload.client;
        draft.loading = false;
        break;
      default:
        break;
    }

    return draft;
  });
}
