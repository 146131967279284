import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaWindowRestore } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableButton from 'components/TableButton';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import TableNoData from 'components/TableNoData';

import filter from 'utils/filter';

import useCustomers from 'hooks/useCustomers';

import { StoreState } from 'store/createStore';

import CustomerActions from './components/CustomerActions';

import { Container } from './styles';

const Home: React.FC = () => {
  const { t } = useTranslation();

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { customers, loading } = useCustomers(idRepresentante);

  type Customer = typeof customers[number];

  const [filterText, setFilterText] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: Customer[];
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  useEffect(() => {
    const filtered = filter(customers, ['cliente', 'email1'], filterText);

    setFilteredCustomers(filtered);
  }, [customers, filterText]);

  const columns = [
    {
      name: t('common:name'),
      selector: 'client.nome',
      cell: (row: Customer) => row.cliente.nome,
      sortable: true,
    },
    {
      name: t('common:email'),
      selector: 'email1',
      cell: (row: Customer) => row.cliente.email1,
      sortable: true,
    },
    {
      name: t('common:date'),
      selector: 'dataAtivacao',
      cell: (row: Customer) =>
        format(parseISO(row.dataAtivacao), 'dd/MM/yyyy', {
          locale: pt,
        }),
      sortable: true,
      width: '120px',
    },
  ];

  return (
    <Container>
      <PageTitle
        icon={<FaWindowRestore color="#FFFFFF" size={24} />}
        title={t('titles:customers.title')}
        description={t('titles:customers.description')}
      />

      <TableWrapper>
        <TableHeader
          startOpen="help"
          title={t('pages:customers.title')}
          description={t('pages:customers.description')}
          helpContent={
            <div>
              <p className="font-weight-bold">
                {t('pages:customers.helpContent.p1')}
              </p>
              <p>{t('pages:customers.helpContent.p2')}</p>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://assine.hostnet.com.br/?rep=${idRepresentante}`}
              >
                {`https://assine.hostnet.com.br/?rep=${idRepresentante}`}
              </a>
              <br />
              <br />
              <p>{t('pages:customers.helpContent.p3')}</p>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://assine.hostnet.com.br/?rep=${idRepresentante}`}
              >
                {`https://assine.hostnet.com.br/?rep=${idRepresentante}&redirect=http://URL_AQUI/`}
              </a>
            </div>
          }
        />
        <TableSubHeader>
          <TableButton forwardedAs={Link} to="/clientes/cadastrar">
            {t('pages:customers.newCustomer')}
          </TableButton>
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            dense
            pagination
            defaultSortField="dataAtivacao"
            defaultSortAsc={false}
            selectableRows
            columns={columns}
            data={filteredCustomers}
            progressPending={loading}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            contextComponent={
              <CustomerActions
                selectedCount={selectedRowsInfo.selectedCount}
                selectedRows={selectedRowsInfo.selectedRows}
                loading={loading}
              />
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default Home;
