import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Spinner } from 'react-bootstrap';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';

import {
  FaHome,
  FaUsers,
  FaWindowRestore,
  FaGlobeAmericas,
} from 'react-icons/fa';

import PageTitle from 'components/PageTitle';
import PanelCard from 'components/PanelCard';
import Loading from 'components/Loading';
import Error from 'components/Error';
import SiteResource from 'components/SiteResource';

import { StoreState } from 'store/createStore';

import api from 'services/api';

import useCustomers from 'hooks/useCustomers';
import useSites from 'hooks/useSites';
import useDomains from 'hooks/useDomains';
import useActiveServices from 'hooks/useActiveServices';
import useCommissionBalance from 'hooks/useCommissionBalance';
import useOpenCharges from 'hooks/useOpenCharges';

import filter from 'utils/filter';
import formatCurrency from 'utils/formatCurrency';

import { Container, Info, BasicLink } from './styles';

interface CredenciamentoInfo {
  idCredenciamento: number;
  credenciamento: string;
  empresa: boolean;
  recebimento: string;
}

interface AccreditationItemResponse {
  idRepresentanteCredenciamento: number;
  idRepresentante: number;
  idCredenciamento: number;
  ativo: boolean;
  dataInicio: string;
  empresa: boolean;
  recebimento: string;
  status: string;
  credenciamento: CredenciamentoInfo;
}

interface AccreditationsResponse {
  data: AccreditationItemResponse[];
}

interface Accreditation {
  idRepresentanteCredenciamento: number;
  idRepresentante: number;
  idCredenciamento: number;
  ativo: boolean;
  dataInicio: string;
  empresa: boolean;
  recebimento: string;
  status: string;
  credenciamento: CredenciamentoInfo;
}

const Home: React.FC = () => {
  const { t } = useTranslation();

  const client = useSelector((state: StoreState) => state.client.info);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [accreditation, setAccreditation] = useState<Accreditation[]>([]);

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { customers, loading: loadingCustomers } = useCustomers(
    idRepresentante,
  );
  const { sites, loading: loadingSites } = useSites(idRepresentante);
  const { domains, loading: loadingDomains } = useDomains(idRepresentante);

  const { services, loading: loadingServices } = useActiveServices(
    client.idRepresentante,
  );
  const {
    commissionBalance,
    loading: loadingCommissions,
  } = useCommissionBalance(client.idRepresentante);
  const { charges, loading: loadingCharges } = useOpenCharges(
    client.idRepresentante,
  );

  const [domainsSize, setDomainsSize] = useState<number>(0);
  const [
    activeServicesTotalValue,
    setActiveServicesTotalValue,
  ] = useState<number>(0);

  const [chargesTotalValue, setChargesTotalValue] = useState<number>(0);

  const [activeSitesLength, setActiveSitesLength] = useState<number>(0);

  useEffect(() => {
    let length = 0;
    if (domains.enom && domains.registrobr && domains.rrpproxy) {
      length =
        domains.enom.length +
        domains.registrobr.length +
        domains.rrpproxy.length;
    }
    setDomainsSize(length);
  }, [domains]);

  useEffect(() => {
    let total = 0;
    services.map(service => {
      total += parseFloat(service.valor);
      return true;
    });
    setActiveServicesTotalValue(total);
  }, [services]);

  useEffect(() => {
    let total = 0;
    charges.map(charge => {
      total += parseFloat(charge.valor);
      return true;
    });
    setChargesTotalValue(total);
  }, [charges]);

  useEffect(() => {
    const filteredSites = filter(sites, ['ativo'], 'true');
    setActiveSitesLength(filteredSites.length);
  }, [sites]);

  const formatAccreditations = useCallback(
    (accreditationResponse: AccreditationItemResponse[]) => {
      const formatedAccreditations = accreditationResponse.map(
        credenciamento => {
          return {
            idRepresentanteCredenciamento:
              credenciamento.idRepresentanteCredenciamento,
            idRepresentante: credenciamento.idRepresentante,
            idCredenciamento: credenciamento.idCredenciamento,
            ativo: credenciamento.ativo,
            dataInicio: format(
              parseISO(credenciamento.dataInicio),
              'dd/MM/yyyy',
              {
                locale: pt,
              },
            ),
            credenciamento: credenciamento.credenciamento,
            empresa: credenciamento.empresa,
            recebimento: credenciamento.recebimento,
            status: credenciamento.status,
          };
        },
      );

      return formatedAccreditations;
    },
    [],
  );

  useEffect(() => {
    async function loadAccreditation() {
      try {
        setError(false);
        setLoading(true);

        const accreditationsResponse = await api.get<AccreditationsResponse>(
          `clientes/v1/representante/${client.idRepresentante}/credenciamento?filter[ativo]=1`,
        );

        const formatedAccreditations = formatAccreditations(
          accreditationsResponse.data.data,
        );

        setAccreditation(formatedAccreditations);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadAccreditation();
  }, [formatAccreditations, client.idRepresentante]);

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        icon={<FaHome color="#FFFFFF" size={24} />}
        title={t('titles:home.title')}
        description={t('titles:home.description')}
      />

      <div className="my-4">
        <Row>
          <div className="col-xl-4 col-md-12 mb-4 mb-xl-0">
            <SiteResource
              name={t('common:customer_plural')}
              value={`${customers.length}`}
              color="blue"
              icon={<FaUsers size="24" />}
              loading={loadingCustomers}
            />
          </div>
          <div className="col-xl-4 col-md-12 mb-4 mb-xl-0">
            <SiteResource
              name={t('common:site_plural')}
              value={`${activeSitesLength}`}
              color="violet"
              icon={<FaWindowRestore size="18" />}
              loading={loadingSites}
            />
          </div>
          <div className="col-xl-4 col-md-12 mb-4 mb-xl-0">
            <SiteResource
              name={t('pages:home.register_plural')}
              value={`${domainsSize}`}
              color="green"
              icon={<FaGlobeAmericas size="24" />}
              loading={loadingDomains}
            />
          </div>
        </Row>
      </div>

      {/* */}
      <Row className="my-4">
        <Col xl={4} md={6} className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6 className="m-0">{t('pages:home.received')}</h6>
                <Info className="blue">
                  {loadingCommissions ? (
                    <Spinner animation="border" />
                  ) : (
                    formatCurrency(parseFloat(commissionBalance.recebido))
                  )}
                </Info>
              </div>
              <BasicLink to="/financeiro/comissoes">
                {t('pages:home.receivedLink')}
              </BasicLink>
            </Card.Body>
          </PanelCard>
        </Col>

        <Col xl={4} md={6} className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6 className="m-0">{t('pages:home.openCharges')}</h6>
                <Info className="violet">
                  {loadingCharges ? (
                    <Spinner animation="border" />
                  ) : (
                    formatCurrency(chargesTotalValue)
                  )}
                </Info>
              </div>
              <BasicLink to="/financeiro/cobrancas">
                {t('pages:home.openChargesLink')}
              </BasicLink>
            </Card.Body>
          </PanelCard>
        </Col>

        <Col xl={4} md={6}>
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6 className="m-0">{t('pages:home.activeServices')}</h6>
                <Info className="green">
                  {loadingServices ? (
                    <Spinner animation="border" />
                  ) : (
                    formatCurrency(activeServicesTotalValue)
                  )}
                </Info>
              </div>
              <BasicLink to="/servicos/ativos">
                {t('pages:home.activeServicesLink')}
              </BasicLink>
            </Card.Body>
          </PanelCard>
        </Col>
      </Row>
      {/* */}

      {loading ? (
        <Loading />
      ) : (
        <Row className="my-4">
          {accreditation.map(accredit => (
            <Col className="mb-4 mb-xl-0">
              <PanelCard>
                <Card.Body>
                  <div className="mb-4">
                    <h6>Desde {accredit.dataInicio}</h6>
                    <Info className="blue">
                      {accredit.credenciamento.credenciamento}
                    </Info>
                  </div>
                </Card.Body>
              </PanelCard>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default Home;
