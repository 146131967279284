import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { FaWindowRestore } from 'react-icons/fa';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import Error from 'components/Error';
import FormHeader from 'components/FormHeader';
import FormWrapper from 'components/FormWrapper';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import swal from 'services/swal';
import hashIds from 'services/hashIds';
import history from 'services/history';
import api from 'services/api';
import toast from 'services/toast';

import useSites from 'hooks/useSites';
import useCustomer from 'hooks/useCustomer';
import useMarketingProducts from 'hooks/useMarketingProducts';

import formatCurrency from 'utils/formatCurrency';

import { StoreState } from 'store/createStore';

interface MatchParams {
  idCustomer: string;
  idProduct: string;
}

type ConfirmProductSaleProps = RouteComponentProps<MatchParams>;

type FormValues = {
  idSite: number;
  price: string;
  installments: number;
  setup: 'PAGAMENTO' | 'DEBITO' | 'PROX_VENCIMENTO';
};

type SaleProductResponse = {
  data: {
    info: string;
    url: string | null;
  };
};

const ConfirmProductSale: React.FC<ConfirmProductSaleProps> = ({ match }) => {
  const { t } = useTranslation();

  const idCustomer = Number(hashIds.decode(match.params.idCustomer));
  const idProduct = Number(hashIds.decode(match.params.idProduct));

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const {
    marketingProducts,
    isLoading: loadingProducts,
    isError: productsError,
  } = useMarketingProducts();

  const {
    customer,
    isLoading: loadingCustomer,
    isError: customerError,
  } = useCustomer(idRepresentante, idCustomer);

  const { sites, loading: loadingSites, error: sitesError } = useSites(
    idRepresentante,
  );

  const product = marketingProducts.find(
    marketingProduct => marketingProduct.idProduto === idProduct,
  );

  const customerSites = sites.filter(
    site => site.idCliente === idCustomer && site.ativo,
  );

  const productNotAvailableWithoutSite =
    product && product.requisito === 'SITE' && customerSites.length === 0;

  const error = productsError || customerError || sitesError;
  const loading = loadingProducts || loadingCustomer || loadingSites;

  async function submitForm(values: FormValues) {
    try {
      const selectedSite = customerSites.find(
        customerSite => customerSite.idSite === values.idSite,
      );

      const saleProductResponse = await api.post<SaleProductResponse>(
        'clientes/v1/representante/venda/produto',
        {
          idRepresentante,
          idProduto: idProduct,
          idCliente: idCustomer,
          ...(selectedSite && { site: selectedSite.site }),
        },
      );

      const { url } = saleProductResponse.data.data;

      if (url !== null) {
        swal.fire(
          t('pages:marketingProductSale.successRegisteredSale'),
          t('pages:marketingProductSale.willBeRedirected'),
          'success',
        );

        window.location.href = url;
      } else {
        toast.fire(t('pages:marketingProductSale.saleSuccess'));

        history.push('/clientes');
      }
    } catch (err) {
      swal.fire({
        title: t('pages:marketingProductSale.saleFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (!product) {
    return <Error />;
  }

  return (
    <div>
      <PageTitle
        icon={<FaWindowRestore color="#FFFFFF" size={24} />}
        title={t('titles:customers.title')}
        description={t('titles:customers.description')}
      />

      <FormWrapper>
        <FormHeader
          title={`${t('pages:marketingProductSale.title')} ${customer.email1}`}
          description={t('pages:marketingProductSale.description')}
          helpContent={t('pages:marketingProductSale.helpContent.p1')}
          startOpen
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            idSite: customerSites.length > 0 ? customerSites[0].idSite : 0,
            installments: 1,
            price: `${product.valor.toFixed(2)}`,
            setup: 'PAGAMENTO',
          }}
          validationSchema={Yup.object().shape({
            price: Yup.string().required(t('validations:requiredField')),
            installments: Yup.number().required(t('validations:requiredField')),
          })}
          onSubmit={submitForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:marketingProductSale.formSubtitle')}
                />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:customer')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="customer"
                      value={customer.email1}
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:product')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="productName"
                      value={product.produto}
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>

                {customerSites.length > 0 && (
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('common:site')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        as="select"
                        name="idSite"
                        onChange={e => {
                          props.setFieldValue('idSite', Number(e.target.value));
                        }}
                      >
                        {customerSites.map(site => (
                          <option
                            key={`customer-site-${site.idSite}`}
                            value={site.idSite}
                          >
                            {site.site}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                )}

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:price')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="productPrice"
                      value={formatCurrency(Number(props.values.price))}
                      plaintext
                      readOnly
                    />
                    {props.errors.price && (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {props.errors.price}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Form.Group>
              </Card.Body>

              {productNotAvailableWithoutSite ? (
                <Form.Group as={Row}>
                  <Form.Label column sm={2} />
                  <Col sm={10}>
                    <div className="mb-4">
                      Esse produto requer a contratação de uma hospedagem de
                      site.
                    </div>
                    <PanelButton
                      variant="secondary"
                      forwardedAs={Link}
                      to={`/automacao-marketing/produtos/${match.params.idProduct}/venda`}
                      disabled={props.isSubmitting}
                    >
                      Escolher outro produto
                    </PanelButton>
                  </Col>
                </Form.Group>
              ) : (
                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton
                    className="mr-1"
                    disabled={props.isSubmitting || !props.isValid}
                    onClick={() => {
                      props.setFieldValue('setup', 'PAGAMENTO');
                      props.submitForm();
                    }}
                  >
                    {props.isSubmitting
                      ? t('common:sending')
                      : t('pages:marketingProductSale.buttons.chargeNow')}
                  </PanelButton>

                  <PanelButton
                    variant="secondary"
                    forwardedAs={Link}
                    to={`/automacao-marketing/produtos/${match.params.idProduct}/venda`}
                    disabled={props.isSubmitting}
                  >
                    {t('common:back')}
                  </PanelButton>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </div>
  );
};

export default withRouter(ConfirmProductSale);
