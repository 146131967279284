import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const Container = styled.div`
  .export-type {
    font-size: 14px;
  }

  .pdf-label {
    color: #ff5370;
  }
`;

export const CsvButton = styled(Button)`
  color: #22794f;
  border-radius: 2px;

  font-size: 11px;
  padding: 8px 14px;

  background: transparent;
  border-color: #30a96e;
  transition: all ease-in 0.3s;

  &:hover,
  &:focus,
  &:active {
    border-color: #43c988 !important;
    background-color: #43c988 !important;
  }

  &.btn-danger:hover {
    background-color: var(--color-salmon-pink);
  }
`;

export const PdfButton = styled(Button)`
  color: #ff163d;
  border-radius: 2px;

  font-size: 11px;
  padding: 8px 14px;

  background: transparent;
  border-color: #ff5370;
  transition: all ease-in 0.3s;

  &:hover {
    border-color: #ff869a;
    background-color: #ff869a;
  }
`;
