import { useQuery } from 'react-query';

import api from 'services/api';

interface ServiceItemResponse {
  idServico: number;
  idServicoCategoria: number;
  servico: string;
  setup: string;
  valor: string;
  periodicidade: 'SETUP' | 'MENSAL';
  requisito: string;
  categoria: string;
}

interface ServicesResponse {
  data: ServiceItemResponse[];
}

interface Service {
  idServico: number;
  idServicoCategoria: number;
  servico: string;
  setup: number;
  valor: number;
  periodicidade: 'SETUP' | 'MENSAL';
  requisito: string;
  categoria: string;
}

const initialData: Service = {
  idServico: 0,
  idServicoCategoria: 0,
  servico: '',
  setup: 0,
  valor: 0,
  periodicidade: 'MENSAL',
  requisito: '',
  categoria: '',
};

async function loadService(
  idRepresentante: number,
  idService: number,
): Promise<Service> {
  const serviceResponse = await api.get<ServicesResponse>(
    `clientes/v1/representante/${idRepresentante}/servico`,
    {
      params: {
        'filter[idServico]': idService,
      },
    },
  );

  const formattedService = serviceResponse.data.data.map(
    ({
      idServico,
      idServicoCategoria,
      servico,
      setup,
      valor,
      periodicidade,
      requisito,
      categoria,
    }) => ({
      idServico,
      idServicoCategoria,
      servico,
      setup: Number(setup),
      valor: Number(valor),
      periodicidade,
      requisito,
      categoria,
    }),
  );

  return formattedService[0];
}

export default function useService(idRepresentante: number, idService: number) {
  const { data, ...rest } = useQuery<Service>(
    ['service', idService],
    () => loadService(idRepresentante, idService),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const service = data || initialData;

  return { service, ...rest };
}
