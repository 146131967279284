import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import * as Cookies from 'js-cookie';

import api from 'services/api';
import history from 'services/history';

import { ApiConfig } from 'config/Api';

import * as authActions from './actions';
import * as sitesActions from '../sites/actions';
import * as clientActions from '../client/actions';

interface AuthorizationResponse {
  user_id: number;
  refresh_token: string;
  access_token: string;
  expires_in: number;
}

interface Site {
  idSite: number;
  site: string;
  idPlano: string;
  plano: string;
  idCliente: string;
  valor: string;
  tecnologia: string;
  ftp: 'TRUE' | 'FALSE';
  memoria: string;
  cpu: string;
}

interface SitesResponse {
  data: Site[];
}

interface Client {
  idCliente: number;
  idEntidadeFinanceira: number;
  email1: string;
  nome: string;
  responsavel: string;
  cpfCnpj: string;
  tipoConta: string;
  tipoPessoa: string;
  tipoCobranca: 'MULTIPLA' | 'UNICA';
  representante?: {
    idRepresentante: number;
  };
}

interface ClientResponse {
  data: Client[];
}

type AuthorizationRequest = ActionType<typeof authActions.authorizationRequest>;

interface JwtPayload {
  uad?: string;
  uadKey?: string;
  nivel?: 'ADMINISTRADOR' | 'TECNICO';
  scopes: string[];
}

export function* authorize({ payload }: AuthorizationRequest) {
  try {
    const { token, scope } = payload;

    const decodedTokenInfo = jwtDecode<JwtPayload>(token);

    const usuarioAdicionalScope = decodedTokenInfo.scopes.includes(
      'usuarioAdicional',
    );

    if (usuarioAdicionalScope && decodedTokenInfo.nivel !== 'ADMINISTRADOR') {
      history.push('/acesso-indisponivel');
      return;
    }

    const secret = btoa(`${ApiConfig.CLIENT_ID}:${ApiConfig.CLIENT_SECRET}`);

    const headers = { Authorization: `Basic ${secret}` };

    const authResponse: AxiosResponse<AuthorizationResponse> = yield call(
      api.post,
      'oauth/token',
      {
        grant_type: 'hostnet_jwt',
        token,
        scope,
      },
      { headers },
    );

    const tokenInfo = authResponse.data;

    api.defaults.headers.Authorization = `Bearer ${tokenInfo.access_token}`;

    const siteRequestCall = call(api.get, `painel/v1/sites`);
    const clientRequestCall = call(api.get, `clientes/v1/cliente`);

    const [sitesResponse, clientResponse]: [
      AxiosResponse<SitesResponse>,
      AxiosResponse<ClientResponse>,
    ] = yield all([siteRequestCall, clientRequestCall]);

    const {
      idCliente,
      idEntidadeFinanceira,
      email1,
      nome,
      responsavel,
      cpfCnpj,
      tipoConta,
      tipoPessoa,
      tipoCobranca,
      representante,
    } = clientResponse.data.data[0];

    if (!representante) {
      history.push('/acesso-indisponivel');
      return;
    }

    const clientData = {
      idCliente,
      idEntidadeFinanceira,
      idRepresentante: representante.idRepresentante,
      email1,
      nome,
      responsavel,
      cpfCnpj,
      tipoConta,
      tipoPessoa,
      tipoCobranca,
    };

    const sites = sitesResponse.data.data.map(
      ({
        idSite,
        idPlano,
        idCliente: idClienteSite,
        site,
        plano,
        valor,
        tecnologia,
        ftp,
        memoria,
        cpu,
      }) => ({
        idSite,
        idPlano: parseInt(idPlano, 10),
        idCliente: parseInt(idClienteSite, 10),
        site,
        plano,
        valor,
        tecnologia,
        ftp: ftp === 'TRUE',
        memoria,
        cpu,
      }),
    );

    const authorizationData = {
      accessToken: tokenInfo.access_token,
      refreshToken: tokenInfo.refresh_token,
      userId: tokenInfo.user_id,
      expiresIn: tokenInfo.expires_in,
    };

    yield put(clientActions.clientSuccess({ client: clientData }));

    yield put(sitesActions.sitesSuccess({ sites }));

    yield put(authActions.authorizationSuccess(authorizationData));

    Cookies.set('auth', 'true');

    history.push('/home');
  } catch (err) {
    yield put(authActions.authorizationFailure());
  }
}

export function setToken({ payload }: ReturnType<typeof Object>) {
  if (!payload || !payload.auth) return;

  const { accessToken = null } = payload.auth;

  if (accessToken) {
    api.defaults.headers.Authorization = `Bearer ${accessToken}`;
  }
}

export function logout() {
  Cookies.remove('auth');

  localStorage.setItem('painelhostnetwebts:logout', 'true');

  const currentLocation = window.location.href;
  const returnUrl = encodeURIComponent(
    `/login/authorize?clientId=painel4&state=${currentLocation}`,
  );

  window.location.href = `https://id.hostnet.com.br/login/?returnUrl=${returnUrl}`;
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/AUTHORIZATION_REQUEST', authorize),
  takeLatest('@auth/LOGOUT', logout),
]);
