export default function refreshStore() {
  const currentVersion = '81b5391b6c835237c87ae98fdf3a729c222';

  const versionKey = localStorage.getItem('hostnetreactapp:versionKey');

  if (versionKey && versionKey !== currentVersion) {
    localStorage.clear();
    window.location.reload();
  }

  if (!versionKey) {
    localStorage.setItem('hostnetreactapp:versionKey', currentVersion);
  }
}
