import { useQuery } from 'react-query';

import api from 'services/api';

type CongelamentoStatus = 'INATIVO' | 'EMATRASO' | 'CONGELADO';

type SiteItemResponse = {
  idRepresentanteSite: number;
  idRepresentante: number;
  idSite: number;
  vinculo: string;
  ativo: boolean;
  dataAtivacao: string;
  dataDesativacao: string;
  site: {
    idSite: number;
    site: string;
    status: 'ATIVO' | 'CANCELADO';
    congelado: boolean;
    statusCongelamento: CongelamentoStatus | null;
    motivoCancelamento: string;
    dataCadastro: string;
    dataCancelamento: string;
    proxVencimento: string;
  };
  cliente: {
    idCliente: number;
    email1: string;
  };
};

type SitesResponse = {
  data: SiteItemResponse[];
};

type Site = {
  idSite: number;
  idCliente: number;
  idRepresentante: number;
  idRepresentanteSite: number;
  vinculo: string;
  ativo: boolean;
  site: string;
  clienteEmail: string;
  dataAtivacao: string;
  status: 'ATIVO' | 'CANCELADO';
  statusTxt: string;
  congelado: boolean;
  statusCongelamento: CongelamentoStatus | null;
  motivoCancelamento: string;
  proxVencimento: string;
  dataCadastro: string;
  dataDesativacao: string;
  dataCancelamento: string;
};

const congelamentoStatus = {
  INATIVO: 'Inativo',
  EMATRASO: 'Em atraso',
  CONGELADO: 'Congelado',
};

function formatActiveStatus() {
  return `Ativo`;
}

function formatCanceledStatus(siteItem: SiteItemResponse) {
  const { site } = siteItem;

  let statusText = `Cancelado`;

  if (site.motivoCancelamento) {
    statusText += ` - ${site.motivoCancelamento}`;
  }

  return statusText;
}

function formatCongeladoStatus(siteItem: SiteItemResponse) {
  const {
    site: { statusCongelamento },
  } = siteItem;

  let statusText = `Congelado`;

  if (statusCongelamento && statusCongelamento in congelamentoStatus) {
    const key = statusCongelamento as CongelamentoStatus;

    const statusComplement = congelamentoStatus[key];

    statusText = `Congelado - ${statusComplement}`;
  }

  return statusText;
}

function formatStatus(siteItem: SiteItemResponse) {
  const { site } = siteItem;

  if (site.status === 'ATIVO' && !site.congelado) {
    return formatActiveStatus();
  }

  if (site.status === 'CANCELADO') {
    return formatCanceledStatus(siteItem);
  }

  if (site.congelado) {
    return formatCongeladoStatus(siteItem);
  }

  return '';
}

function formatSite(siteItem: SiteItemResponse): Site {
  const {
    idSite,
    idRepresentante,
    idRepresentanteSite,
    ativo,
    dataAtivacao,
    dataDesativacao,
    vinculo,
    site,
    cliente,
  } = siteItem;

  return {
    idSite: Number(idSite),
    idCliente: Number(cliente.idCliente),
    idRepresentante: Number(idRepresentante),
    idRepresentanteSite: Number(idRepresentanteSite),
    vinculo,
    ativo,
    dataAtivacao,
    dataDesativacao,
    site: site.site,
    status: site.status,
    congelado: site.congelado,
    statusCongelamento: site.statusCongelamento,
    motivoCancelamento: site.motivoCancelamento,
    proxVencimento: site.proxVencimento,
    dataCadastro: site.dataCadastro,
    dataCancelamento: site.dataCancelamento,
    clienteEmail: cliente.email1,
    statusTxt: formatStatus(siteItem),
  };
}

async function loadSites(idRepresentante: number): Promise<Site[]> {
  const sitesResponse = await api.get<SitesResponse>(
    `clientes/v1/representante/${idRepresentante}/site`,
  );

  const sites = sitesResponse.data.data.map(formatSite);

  return sites;
}

const initialData: Site[] = [];

export default function useSites(idRepresentante: number) {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<Site[]>(
    'sites',
    () => loadSites(idRepresentante),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const sites = data || initialData;

  return {
    sites,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
