import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';

import { StoreState } from 'store/createStore';

import api from 'services/api';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableNoData from 'components/TableNoData';
import TableButton from 'components/TableButton';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import Error from 'components/Error';

import formatCurrency from 'utils/formatCurrency';
import filter from 'utils/filter';

import { Container } from './styles';

interface Credit {
  emailClienteBeneficiario: string;
  descricao: string;
  valor: string;
  dataTransferencia: string;
}

interface CreditsResponse {
  data: Credit[];
}

const CreditTransfer: React.FC = () => {
  const { t } = useTranslation();

  const client = useSelector((state: StoreState) => state.client.info);

  const [filterText, setFilterText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [credits, setCredits] = useState<Credit[]>([]);

  const [filteredCredits, setFilteredCredits] = useState<Credit[]>([]);

  useEffect(() => {
    async function loadCreditTransfer() {
      try {
        setError(false);
        setLoading(true);

        const creditsResponse = await api.get<CreditsResponse>(
          `financeiro/v1/transferencia?filter[idClienteEmissor]=${client.idCliente}`,
        );

        setCredits(creditsResponse.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadCreditTransfer();
  }, [client]);

  useEffect(() => {
    setFilteredCredits(
      filter(
        credits,
        ['emailClienteBeneficiario', 'descricao', 'valor', 'dataTransferencia'],
        filterText,
      ),
    );
  }, [credits, filterText]);

  const columns = [
    {
      name: t('common:customer'),
      selector: 'emailClienteBeneficiario',
      sortable: true,
    },
    {
      name: t('common:description'),
      selector: 'descricao',
      sortable: true,
      cell: (row: Credit) => {
        const { descricao } = row;
        return <div className="my-2">{descricao}</div>;
      },
    },
    {
      name: t('common:value'),
      selector: 'valor',
      sortable: true,
      maxWidth: '200px',
      cell: (row: Credit) => formatCurrency(parseFloat(row.valor)),
      sortFunction: (a: Credit, b: Credit) =>
        Number(a.valor) > Number(b.valor) ? 1 : -1,
    },
    {
      name: t('common:date'),
      selector: 'dataTransferencia',
      sortable: true,
      maxWidth: '200px',
      cell: (row: Credit) =>
        format(parseISO(row.dataTransferencia), 'dd/MM/yyyy', {
          locale: pt,
        }),
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:creditTransfer.title')}
          description={t('pages:creditTransfer.description')}
          helpContent={
            <div>
              <p>{t('pages:creditTransfer.helpContent.p1')}</p>
            </div>
          }
        />
        <TableSubHeader>
          <div>
            <TableButton forwardedAs={Link} to="/financeiro/transferencia/nova">
              {t('pages:creditTransfer.request')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>
        <DataTable
          noHeader
          columns={columns}
          data={filteredCredits}
          progressPending={loading}
          progressComponent={<Loading />}
          noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          className="datatables-table"
          pagination
          paginationComponentOptions={{
            rowsPerPageText: t('common:registersPerPage'),
            rangeSeparatorText: t('common:of'),
          }}
        />
      </TableWrapper>
    </Container>
  );
};

export default CreditTransfer;
