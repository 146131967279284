import styled from 'styled-components';

export const Container = styled.div``;

export const Description = styled.dl`
  > dt {
    font-weight: 400;
  }
  > dd {
    font-weight: 600;
  }
`;
