import { useQuery } from 'react-query';

import api from 'services/api';

type MarketingProductItemRespose = {
  idProduto: number;
  idProdutoCategoria: number;
  categoria: string;
  produto: string;
  setup: string;
  valor: string;
  requisito: string;
};

type MarketingProductsResponse = {
  data: MarketingProductItemRespose[];
};

type MarketingProduct = {
  idProduto: number;
  idProdutoCategoria: number;
  categoria: string;
  produto: string;
  setup: number;
  valor: number;
  requisito: string;
};

function formatMarketingProduct(
  product: MarketingProductItemRespose,
): MarketingProduct {
  const {
    idProduto,
    idProdutoCategoria,
    categoria,
    produto,
    setup,
    valor,
    requisito,
  } = product;

  return {
    idProduto,
    idProdutoCategoria,
    categoria,
    produto,
    setup: Number(setup),
    valor: Number(valor),
    requisito,
  };
}

async function loadMarketingProducts(): Promise<MarketingProduct[]> {
  const marketingProductsResponse = await api.get<MarketingProductsResponse>(
    `clientes/v1/produto`,
    {
      params: {
        ativos: 1,
        idsProdutoCategoria: [38, 41, 42],
        tipo: 'SERVICO_ADICIONAL',
      },
    },
  );

  let marketingProducts = marketingProductsResponse.data.data.map(
    formatMarketingProduct,
  );

  const smsProducts = marketingProducts.filter(
    product => product.idProdutoCategoria === 42 && product.valor > 0,
  );

  const smsProduct = smsProducts.reduce((prev, curr) =>
    prev.valor < curr.valor ? prev : curr,
  );

  smsProduct.produto = 'Mautic SMS';

  marketingProducts = marketingProducts.filter(
    product => product.idProdutoCategoria !== 42,
  );

  return [...marketingProducts, ...[smsProduct]];
}

const initialData: MarketingProduct[] = [];

export default function useMarketingProducts() {
  const { data, ...rest } = useQuery<MarketingProduct[]>(
    'marketingProducts',
    () => loadMarketingProducts(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const marketingProducts = data || initialData;

  return {
    marketingProducts,
    ...rest,
  };
}
