import React from 'react';
import { useTranslation } from 'react-i18next';

import TableActionsWrapper from 'components/TableActionsWrapper';
import TableButton from 'components/TableButton';

interface ActiveServiceActionsProps {
  loading: boolean;
  deleteActiveService: () => void;
}

const ActiveServiceActions: React.FC<ActiveServiceActionsProps> = ({
  loading,
  deleteActiveService,
}) => {
  const { t } = useTranslation();

  return (
    <TableActionsWrapper loading={loading}>
      <>
        <TableButton
          className="mx-1"
          variant="danger"
          size="sm"
          disabled={loading}
          onClick={() => deleteActiveService()}
        >
          {t('common:delete')}
        </TableButton>
      </>
    </TableActionsWrapper>
  );
};

export default ActiveServiceActions;
