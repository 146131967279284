import React from 'react';
import { Switch } from 'react-router-dom';

import Auth from 'pages/Auth';
import Home from 'pages/Home';
import Unavailable from 'pages/Unavailable';
import Registration from 'pages/Registration';
import Customers from 'pages/Customers';
import NewCustomers from 'pages/Customers/New';
import Domains from 'pages/Domains';
import Financial from 'pages/Financial';
import WithdrawList from 'pages/Financial/Withdraw/List';
import WithdrawNew from 'pages/Financial/Withdraw/New';
import Commission from 'pages/Financial/Commission';
import Royalties from 'pages/Financial/Royalties';
import OpenCharges from 'pages/Financial/OpenCharges';
import CreditTransfer from 'pages/Financial/CreditTransfer/List';
import CreditTransferNew from 'pages/Financial/CreditTransfer/New';
import Helpdesk from 'pages/Helpdesk';
import ActiveServices from 'pages/Services/Active';
import CustomizedServices from 'pages/Services/Customized/List';
import CustomizedServicesNew from 'pages/Services/Customized/New';
import CustomizedServicesEdit from 'pages/Services/Customized/Edit';
import Ticket from 'pages/Ticket';
import Sites from 'pages/Sites';
import Dashboard from 'pages/Dashboard';
import SaleNew from 'pages/Sale/New';
import ServiceSale from 'pages/Sale/Service';
import ProductSale from 'pages/Sale/Product';
import MarketingProducts from 'pages/Marketing/Products/List';
import MarketingProductSelectCustomer from 'pages/Marketing/Products/Sale/SelectCustomer';
import MarketingProductConfirm from 'pages/Marketing/Products/Sale/Confirm/Product';
import MarketingEmailLimitProductConfirm from 'pages/Marketing/Products/Sale/Confirm/EmailLimitProduct';
import MarketingSmsProductConfirm from 'pages/Marketing/Products/Sale/Confirm/Sms';
import MarketingServices from 'pages/Marketing/Services/List';
import MarketingServiceSelectCustomer from 'pages/Marketing/Services/Sale/SelectCustomer';
import MarketingServiceConfirm from 'pages/Marketing/Services/Sale/Confirm';
import MarketingActiveServices from 'pages/Marketing/ActiveServices';

// import guards from './guards';

import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Auth} />
    <Route path="/acesso-indisponivel" component={Unavailable} />
    <Route path="/home" component={Home} isPrivate />
    <Route path="/cadastro" component={Registration} isPrivate />
    <Route path="/clientes/cadastrar" component={NewCustomers} isPrivate />
    <Route path="/clientes" component={Customers} isPrivate />
    <Route path="/dominios" component={Domains} isPrivate />
    <Route path="/financeiro/saques/novo" component={WithdrawNew} isPrivate />
    <Route path="/financeiro/saques" component={WithdrawList} isPrivate />
    <Route path="/financeiro/comissoes" component={Commission} isPrivate />
    <Route path="/financeiro/royalties" component={Royalties} isPrivate />
    <Route path="/financeiro/cobrancas" component={OpenCharges} isPrivate />
    <Route
      path="/financeiro/transferencias"
      component={CreditTransfer}
      isPrivate
    />
    <Route
      path="/financeiro/transferencia/nova"
      component={CreditTransferNew}
      isPrivate
    />
    <Route path="/financeiro" component={Financial} isPrivate />
    <Route path="/helpdesk/ticket/:id" component={Ticket} isPrivate />
    <Route path="/helpdesk" component={Helpdesk} isPrivate />
    <Route
      path="/servicos/customizados/cadastrar"
      component={CustomizedServicesNew}
      isPrivate
    />
    <Route
      path="/servicos/customizados/editar/:id"
      component={CustomizedServicesEdit}
      isPrivate
    />
    <Route
      path="/servicos/customizados"
      component={CustomizedServices}
      isPrivate
    />
    <Route path="/servicos/ativos" component={ActiveServices} isPrivate />

    <Route path="/sites" component={Sites} isPrivate />

    <Route path="/painel/:id" component={Dashboard} isPrivate />
    <Route
      path="/venda/:idCustomer/servico/:idService"
      component={ServiceSale}
      isPrivate
    />
    <Route
      path="/venda/:idCustomer/produto/:idProduct"
      component={ProductSale}
      isPrivate
    />
    <Route path="/venda/:id" component={SaleNew} isPrivate />

    <Route
      path="/automacao-marketing/produtos-sms/:idProduct/venda/:idCustomer"
      component={MarketingSmsProductConfirm}
      isPrivate
    />

    <Route
      path="/automacao-marketing/produtos-email/:idProduct/venda/:idCustomer"
      component={MarketingEmailLimitProductConfirm}
      isPrivate
    />

    <Route
      path="/automacao-marketing/produtos/:idProduct/venda/:idCustomer"
      component={MarketingProductConfirm}
      isPrivate
    />

    <Route
      path="/automacao-marketing/produtos/:idProduct/venda"
      component={MarketingProductSelectCustomer}
      isPrivate
    />

    <Route
      path="/automacao-marketing/produtos"
      component={MarketingProducts}
      isPrivate
    />

    <Route
      path="/automacao-marketing/servicos/:idService/venda/:idCustomer"
      component={MarketingServiceConfirm}
      isPrivate
    />

    <Route
      path="/automacao-marketing/servicos/:idService/venda"
      component={MarketingServiceSelectCustomer}
      isPrivate
    />

    <Route
      path="/automacao-marketing/servicos"
      component={MarketingServices}
      isPrivate
    />

    <Route
      path="/automacao-marketing/servicos-ativos"
      component={MarketingActiveServices}
      isPrivate
    />
  </Switch>
);

export default Routes;
