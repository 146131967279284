import { useQuery } from 'react-query';

import api from 'services/api';

interface MainProductItemResponse {
  codigo: string;
  produto: string;
  valor: string;
}

interface MainProductsResponse {
  data: MainProductItemResponse[];
}

interface MainProduct {
  codigo: string;
  produto: string;
  valor: number;
}

const initialData: MainProduct[] = [];

function formatProduct(productItem: MainProductItemResponse): MainProduct {
  return {
    codigo: productItem.codigo,
    produto: productItem.produto,
    valor: Number(productItem.valor),
  };
}

async function loadMainProducts(
  idRepresentante: number,
): Promise<MainProduct[]> {
  const mainProductsResponse = await api.get<MainProductsResponse>(
    `clientes/v1/representante/${idRepresentante}/produto`,
  );

  const mainProducts = mainProductsResponse.data.data.map(formatProduct);

  return mainProducts;
}

export default function useMainProducts(idRepresentante: number) {
  const { data, isError, isLoading, isFetching, isFetched, refetch } = useQuery<
    MainProduct[]
  >(
    ['mainProducts', idRepresentante],
    () => loadMainProducts(idRepresentante),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const mainProducts = data || initialData;

  return {
    mainProducts,
    isError,
    isLoading,
    isFetching,
    isFetched,
    refetch,
  };
}
