import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaArrowCircleLeft, FaCogs } from 'react-icons/fa';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import DataTable from 'react-data-table-component';
import pt from 'date-fns/locale/pt/index';

import { StoreState } from 'store/createStore';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import TableSubHeader from 'components/TableSubHeader';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import FilterInput from 'components/FilterInput';
import TableNoData from 'components/TableNoData';
import Loading from 'components/Loading';
import TableButton from 'components/TableButton';

import hashIds from 'services/hashIds';

import filter from 'utils/filter';

import useCustomers from 'hooks/useCustomers';
import useMarketingProducts from 'hooks/useMarketingProducts';

interface MatchParams {
  idProduct: string;
}

type SaleProductProps = RouteComponentProps<MatchParams>;

const Sale: React.FC<SaleProductProps> = ({ match }) => {
  const { t } = useTranslation();

  const idProduct = Number(hashIds.decode(match.params.idProduct));

  const [filterText, setFilterText] = useState('');

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { marketingProducts, isLoading } = useMarketingProducts();

  const { customers, loading: loadingCustomers } = useCustomers(
    idRepresentante,
  );

  const filteredCustomers = filter(
    customers,
    ['cliente', 'email1'],
    filterText,
  );
  const selectedProduct = marketingProducts.find(
    product => product.idProduto === idProduct,
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!selectedProduct) {
    return <Error />;
  }

  return (
    <div>
      <PageTitle
        icon={<FaCogs color="#FFFFFF" size={24} />}
        title={t('titles:marketing.title')}
        description={t('titles:marketing.description')}
      />
      <TableWrapper>
        <TableHeader
          title="Selecionar cliente"
          description={`Selecione o cliente para vender o produto: ${selectedProduct.produto}`}
          helpContent={<div />}
        />
        <TableSubHeader>
          <div>
            <TableButton forwardedAs={Link} to="/automacao-marketing/produtos">
              <div className="d-flex align-items-center justify-content-center">
                <FaArrowCircleLeft /> <span className="ml-1">Voltar</span>
              </div>
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            dense
            noHeader
            pagination
            defaultSortField="dataAtivacao"
            defaultSortAsc={false}
            columns={[
              {
                width: '110px',
                name: '',
                cell: ({ idCliente }) => {
                  let url = `/automacao-marketing/produtos/${hashIds.encode(
                    idProduct,
                  )}/venda/${hashIds.encode(idCliente)}`;

                  if (selectedProduct.idProdutoCategoria === 38) {
                    url = `/automacao-marketing/produtos-email/${hashIds.encode(
                      idProduct,
                    )}/venda/${hashIds.encode(idCliente)}`;
                  }

                  if (selectedProduct.idProdutoCategoria === 42) {
                    url = `/automacao-marketing/produtos-sms/${hashIds.encode(
                      idProduct,
                    )}/venda/${hashIds.encode(idCliente)}`;
                  }

                  return (
                    <TableButton
                      forwardedAs={Link}
                      className="btn-in-table"
                      to={url}
                    >
                      Selecionar
                    </TableButton>
                  );
                },
              },
              {
                name: t('common:name'),
                selector: 'client.nome',
                cell: row => row.cliente.nome,
                sortable: true,
              },
              {
                name: t('common:email'),
                selector: 'email1',
                cell: row => row.cliente.email1,
                sortable: true,
              },
              {
                name: t('common:date'),
                selector: 'dataAtivacao',
                cell: row =>
                  format(parseISO(row.dataAtivacao), 'dd/MM/yyyy', {
                    locale: pt,
                  }),
                sortable: true,
                width: '120px',
              },
            ]}
            data={filteredCustomers}
            progressPending={loadingCustomers}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </div>
  );
};

export default withRouter(Sale);
