import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import hashIds from 'services/hashIds';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

interface ServiceActionsProps {
  loading: boolean;
  selectedCount: number;
  idCustomer: number;
  selectedRows: {
    idServico: number;
  }[];
}

const ServiceActions: React.FC<ServiceActionsProps> = ({
  loading,
  idCustomer,
  selectedCount,
  selectedRows,
}) => {
  const { t } = useTranslation();

  const hashIdCustomer = hashIds.encode(idCustomer);
  const hashIdService = hashIds.encode(selectedRows[0].idServico);

  const url = `/venda/${hashIdCustomer}/servico/${hashIdService}`;

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 && (
        <>
          <TableButton
            forwardedAs={Link}
            to={url}
            size="sm"
            className="mx-1"
            disabled={loading}
          >
            {t('common:sell')}
          </TableButton>
        </>
      )}
    </TableActionsWrapper>
  );
};

export default ServiceActions;
