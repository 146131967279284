import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { StoreState } from 'store/createStore';

interface GuardProps {
  guards: ((storeState: StoreState) => boolean)[];
}

const Guard: React.FC<GuardProps> = ({ children, guards }) => {
  const store = useSelector((state: StoreState) => state);

  const guardReject = guards.some(guard => guard(store) === false);

  if (guardReject) {
    return <Redirect to="/home" />;
  }

  return <>{children}</>;
};

export default Guard;
