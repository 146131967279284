import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FaQuestion } from 'react-icons/fa';
import { Formik, FormikHelpers } from 'formik';
import { format, parseISO } from 'date-fns';
import { decode } from 'html-entities';
import pt from 'date-fns/locale/pt/index';
import * as Yup from 'yup';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import TableHeader from 'components/TableHeader';
import FormSubtitle from 'components/FormSubtitle';
import TableButton from 'components/TableButton';
import PanelButton from 'components/PanelButton';
import Alert from 'components/Alert';

import useTickets from 'hooks/useTickets';
import useSites from 'hooks/useSites';

import hashIds from 'services/hashIds';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';
import uploadFile from 'services/uploadFile';

import useTicket from './hooks/useTicket';
import useMessages from './hooks/useMessages';

import Message from './components/Message';
import ChangeNameModal from './components/ChangeNameModal';
import CloseTicketModal from './components/CloseTicketModal';
import ReopenTicketModal from './components/ReopenTicketModal';
import SensitiveDataModal from './components/SensitiveDataModal';

import { Container, Description } from './styles';

interface MatchParams {
  id: string;
}

interface NewMessageFormValues {
  message: string;
}

interface ReopenTicketFormValues {
  message: string;
}

interface CloseTicketFormValues {
  rating: number;
  comments: string;
}

interface UpdateSensitiveDataFormValues {
  sensitiveData: string;
}

interface UpdateOwnerNameFormValues {
  ownerName: string;
}

type TicketProps = RouteComponentProps<MatchParams>;

const Ticket: React.FC<TicketProps> = ({ match }) => {
  const { t } = useTranslation();

  const { id } = match.params;

  const idTicket = Number(hashIds.decode(id));

  const { idCliente, idRepresentante, nome: clientName } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { sites } = useSites(idRepresentante);
  const { refetch: refetchTickets } = useTickets(idRepresentante);

  const {
    messages,
    loading: loadingMessages,
    refetch: refetchMessages,
  } = useMessages(idRepresentante, idTicket);

  const {
    ticket,
    error,
    loading: loadingTicket,
    refetch: refetchTicket,
  } = useTicket(idRepresentante, idTicket);

  const [updating, setUpdating] = useState(false);
  const [siteTicket, setSiteTicket] = useState('-');

  const [attachment, setAttachment] = useState<File>();
  const [attachmentError, setAttachmentError] = useState(false);
  const [pendingEvaluation, setPendingEvaluation] = useState(false);

  const [showSensitiveData, setShowSensitiveData] = useState(false);
  const [showNameEditor, setShowNameEditor] = useState(false);
  const [showCloseEditor, setShowCloseEditor] = useState(false);
  const [showReopenEditor, setShowReopenEditor] = useState(false);

  useEffect(() => {
    setPendingEvaluation(
      !!messages.find(message => message.atendente && !message.avaliacao),
    );
  }, [messages]);

  useEffect(() => {
    const clientSite = sites.find(site => site.idSite === ticket.idSite);

    if (clientSite) {
      setSiteTicket(clientSite.site);
    }
  }, [sites, ticket.idSite]);

  function testAttachment(event: React.ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;

    if (!files) {
      setAttachment(undefined);
      setAttachmentError(false);

      return;
    }

    if (files.length === 0) {
      return;
    }

    const file = files[0];

    if (file.size > 4 * 1024 * 1024) {
      setAttachment(undefined);
      setAttachmentError(true);
    } else {
      setAttachment(file);
      setAttachmentError(false);
    }
  }

  async function reopenTicket(
    values: ReopenTicketFormValues,
    formikHelpers: FormikHelpers<ReopenTicketFormValues>,
  ) {
    try {
      setUpdating(true);

      await api.put(`helpdesk/v1/chamado/${idTicket}`, {
        idSituacao: '5',
        idRepresentante,
      });

      await refetchTicket();

      const anexos: {
        tipo: string;
        nome: string;
        caminho: string;
      }[] = [];

      const params = {
        idCliente,
        idTipo: 3,
        mensagem: values.message,
        formato: 'TXT',
        status: 'DISPONIVEL',
        anexos,
        idRepresentante,
      };

      let uploadFileError = false;

      if (attachment) {
        const attachInfo = await uploadFile.upload(attachment);

        if (attachInfo) {
          params.anexos = [attachInfo];
        } else {
          uploadFileError = true;
        }
      }

      await api.post(`helpdesk/v1/chamado/${ticket.idTicket}/mensagem`, params);

      await refetchMessages();

      refetchTickets();

      formikHelpers.resetForm();

      setShowReopenEditor(false);

      setAttachment(undefined);

      if (uploadFileError) {
        swal.fire({
          icon: 'warning',
          title: t('pages:ticket.attachWarningTitle'),
          html: t('pages:ticket.attachWarningText'),
        });
      } else {
        toast.fire(t('pages:ticket.addMessageSuccess'));
      }

      toast.fire(
        t('pages:ticket.changeStatusSuccess', {
          status: t('pages:ticket.open'),
        }),
      );
    } catch (err) {
      swal.fire({
        title: t('pages:ticket.changeStatusError'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  async function addNewMessage(
    values: NewMessageFormValues,
    formikHelpers: FormikHelpers<NewMessageFormValues>,
  ) {
    try {
      setUpdating(true);

      const anexos: {
        tipo: string;
        nome: string;
        caminho: string;
      }[] = [];

      const params = {
        idCliente,
        idTipo: 3,
        mensagem: values.message,
        formato: 'TXT',
        status: 'DISPONIVEL',
        anexos,
        idRepresentante,
      };

      let uploadFileError = false;

      if (attachment) {
        const attachInfo = await uploadFile.upload(attachment);

        if (attachInfo) {
          params.anexos = [attachInfo];
        } else {
          uploadFileError = true;
        }
      }

      await api.post(`helpdesk/v1/chamado/${ticket.idTicket}/mensagem`, params);

      await refetchMessages();

      refetchTickets();

      formikHelpers.resetForm();

      setAttachment(undefined);

      if (uploadFileError) {
        swal.fire({
          icon: 'warning',
          title: t('pages:ticket.attachWarningTitle'),
          html: t('pages:ticket.attachWarningText'),
        });
      } else {
        toast.fire(t('pages:ticket.addMessageSuccess'));
      }
    } catch (err) {
      swal.fire({
        title: t('pages:ticket.addMessageError'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  async function closeTicket(
    values: CloseTicketFormValues,
    formikHelpers: FormikHelpers<CloseTicketFormValues>,
  ) {
    try {
      setUpdating(true);

      const { rating, comments } = values;

      await api.put(`helpdesk/v1/chamado/${idTicket}`, {
        idAvaliacao: rating,
        idSituacao: 4,
        mensagemAvaliacao: comments,
        idRepresentante,
      });

      await refetchTicket();

      refetchTickets();

      formikHelpers.resetForm();

      toast.fire(
        t('pages:ticket.changeStatusSuccess', {
          status: t('pages:ticket.close'),
        }),
      );
    } catch (err) {
      swal.fire({
        title: t('pages:ticket.closeError'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
      setShowCloseEditor(false);
    }
  }

  async function updateOwnerName(values: UpdateOwnerNameFormValues) {
    try {
      if (values.ownerName === ticket.responsavel || ticket.idSituacao === 4) {
        setShowNameEditor(false);
        return;
      }

      setUpdating(true);

      await api.put(`helpdesk/v1/chamado/${ticket.idTicket}`, {
        responsavel: values.ownerName,
        idRepresentante,
      });

      toast.fire(t('pages:ticket.changeOwnerNameSuccess'));

      refetchTicket();

      setShowNameEditor(false);
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  async function updateSensitiveData(values: UpdateSensitiveDataFormValues) {
    try {
      if (
        values.sensitiveData === ticket.dadosSigilosos ||
        ticket.idSituacao === 4
      ) {
        setShowSensitiveData(false);
        return;
      }

      setUpdating(true);

      await api.put(`helpdesk/v1/chamado/${ticket.idTicket}`, {
        dadosSigilosos: values.sensitiveData,
        idRepresentante,
      });

      refetchTicket();

      toast.fire(t('pages:ticket.changeSensitiveDataSuccess'));

      setShowSensitiveData(false);
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  async function updateMessageRating(idMessage: number, rating: number) {
    try {
      setUpdating(true);

      await api.put(
        `helpdesk/v1/chamado/${ticket.idTicket}/mensagem/${idMessage}`,
        {
          idAvaliacao: rating + 1,
          idRepresentante,
        },
      );

      await refetchMessages();

      toast.fire(t('pages:ticket.messages.ratingSuccess'));
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  if (loadingTicket || loadingMessages) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        icon={<FaQuestion color="#FFFFFF" size={24} />}
        title={t('titles:helpdesk.title')}
        description={t('titles:helpdesk.description')}
      />

      <Card className="my-3 shadow-sm">
        <TableHeader
          title={t('pages:ticket.ticketTitle')}
          description={t('pages:ticket.ticketSubtitle')}
          helpContent={
            <div>
              <p>{t('pages:ticket.helpContent.p1')}</p>
              <p>{t('pages:ticket.helpContent.p2')}</p>
              <div>
                <ul>
                  <li>{t('pages:ticket.helpContent.li1')}</li>
                  <li>{t('pages:ticket.helpContent.li2')}</li>
                </ul>
              </div>
            </div>
          }
        />
        <Card.Body className="pt-0 pb-0">
          <FormSubtitle
            subTitle={t('pages:ticket.dataTitle', {
              idTicket: ticket.idTicket,
            })}
          />

          <Row className="pb-4">
            <Col sm={6}>
              <Description className="row">
                <dt className="col-xl-3">{t('common:subject')}</dt>
                <dd className="col-xl-9 mb-3">{ticket.assunto}</dd>
                <dt className="col-xl-3">{t('common:responsible')}</dt>
                <dd className="col-xl-9 mb-3">
                  {ticket.responsavel ? ticket.responsavel : '-'}{' '}
                  {ticket.idSituacao !== 4 && (
                    <TableButton
                      onClick={() => setShowNameEditor(true)}
                      className="ml-2 py-1 px-2 mt-2 mt-xl-0"
                    >
                      {t('common:edit')}
                    </TableButton>
                  )}
                </dd>
                <dt className="col-xl-3">{t('common:site')}</dt>
                <dd className="col-xl-9 mb-3">{siteTicket}</dd>
                <dt className="col-xl-3">{t('pages:helpdesk.opening')}</dt>
                <dd className="col-xl-9 mb-3">
                  {format(parseISO(ticket.abertura), "dd 'de' MMM yyyy", {
                    locale: pt,
                  })}
                </dd>
                <dt className="col-xl-3">{t('common:level')}</dt>
                <dd className="col-xl-9 mb-3">{decode(ticket.nivelTecnico)}</dd>
              </Description>
            </Col>
            <Col sm={6}>
              <Description className="row">
                <dt className="col-xl-3">{t('common:category')}</dt>
                <dd className="col-xl-9 mb-3">{ticket.categoria}</dd>

                <dt className="col-xl-3">{t('common:status')}</dt>
                <dd className="col-xl-9 mb-3">{ticket.situacao}</dd>

                <dt className="col-xl-3">{t('common:department')}</dt>
                <dd className="col-xl-9 mb-3">{ticket.departamento}</dd>

                <dt className="col-xl-3">{t('pages:ticket.clerk')}</dt>
                <dd className="col-xl-9 mb-3">{ticket.atendente}</dd>

                <dt className="col-xl-3">{t('pages:ticket.sensitiveData')}</dt>
                <dd className="col-xl-9 mb-3">
                  <TableButton
                    className="px-2 py-1 mt-2 mt-xl-0"
                    onClick={() => setShowSensitiveData(true)}
                  >
                    {t('pages:ticket.view')}{' '}
                    {ticket.idSituacao !== 4 ? `/ ${t('common:edit')}` : ''}
                  </TableButton>
                </dd>
              </Description>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="my-4 shadow-sm">
        <TableHeader
          title={t('pages:ticket.messages.title')}
          description={t('pages:ticket.messages.subtitle')}
        />

        <Card.Body className="pb-0 border-top">
          {messages.map(message => (
            <Message
              key={message.idMensagem}
              idMessage={message.idMensagem}
              author={message.atendente ? message.atendente : clientName}
              attachments={message.anexos}
              origin={message.atendente ? 'ATENDENTE' : 'CLIENTE'}
              message={message.mensagem}
              rated={message.avaliada}
              fetching={updating}
              updateMessageRating={updateMessageRating}
              date={format(
                parseISO(message.dataHora),
                "dd 'de' MMM yyyy 'às' HH:mm",
                {
                  locale: pt,
                },
              )}
            />
          ))}
        </Card.Body>

        {ticket.idSituacao !== 4 && pendingEvaluation && (
          <Card.Body className="pb-4">
            <Alert variant="warning">
              {t('pages:ticket.messages.ratingWarning')}
            </Alert>
          </Card.Body>
        )}

        {ticket.idSituacao === 4 && (
          <Card.Body className="pb-4">
            <Alert variant="info">
              {t('pages:ticket.closedTicketWarning')}
            </Alert>
          </Card.Body>
        )}

        {ticket.idSituacao !== 4 && !pendingEvaluation && (
          <Formik
            validateOnMount
            initialValues={{
              message: '',
            }}
            validationSchema={Yup.object().shape({
              message: Yup.string().required(
                t('pages:ticket.validation.message'),
              ),
            })}
            onSubmit={addNewMessage}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Card.Body className="pb-4">
                  <Form.Group controlId="newMessage.message">
                    <Form.Label>{t('common:message')}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="message"
                      value={props.values.message}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.message && !!props.errors.message
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="newMessage.attachment">
                    <Form.Label>{t('common:attachment')}</Form.Label>

                    <Form.File id="attachment-input" custom>
                      <Form.File.Input
                        className={attachmentError ? 'is-invalid' : ''}
                        name="attachment"
                        onChange={testAttachment}
                      />
                      <Form.File.Label
                        data-browse={t('pages:ticket.attachmentLabel')}
                      >
                        {attachment && attachment.name}
                      </Form.File.Label>
                    </Form.File>

                    <small className={attachmentError ? 'text-danger' : ''}>
                      {t('pages:ticket.maxAttachmentLength')}
                    </small>
                  </Form.Group>
                </Card.Body>

                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton
                    type="submit"
                    disabled={props.isSubmitting || !props.isValid}
                    className="mr-1"
                  >
                    {props.isSubmitting
                      ? t('common:sending')
                      : t('common:send')}
                  </PanelButton>

                  <PanelButton
                    variant="danger"
                    disabled={props.isSubmitting || pendingEvaluation}
                    onClick={() => setShowCloseEditor(true)}
                    className="mr-1"
                  >
                    {t('pages:ticket.closeTicket')}
                  </PanelButton>

                  <PanelButton
                    variant="secondary"
                    forwardedAs={Link}
                    to="/helpdesk"
                    disabled={props.isSubmitting}
                  >
                    {t('common:back')}
                  </PanelButton>
                </div>
              </Form>
            )}
          </Formik>
        )}

        {ticket.idSituacao === 4 && (
          <div className="border-top pt-2 pb-2 pl-3">
            <PanelButton
              disabled={loadingTicket}
              onClick={() => setShowReopenEditor(true)}
              className="mr-1"
            >
              {t('pages:ticket.reopen')}
            </PanelButton>

            <PanelButton
              variant="secondary"
              forwardedAs={Link}
              to="/helpdesk"
              disabled={loadingTicket}
            >
              {t('common:back')}
            </PanelButton>
          </div>
        )}
      </Card>

      <ChangeNameModal
        show={showNameEditor}
        idTicketStatus={ticket.idSituacao}
        ownerName={ticket.responsavel}
        fetching={updating}
        close={() => setShowNameEditor(false)}
        updateOwnerName={updateOwnerName}
      />

      <CloseTicketModal
        show={showCloseEditor}
        close={() => setShowCloseEditor(false)}
        fetching={updating}
        closeTicket={closeTicket}
      />

      <ReopenTicketModal
        show={showReopenEditor}
        fetching={updating}
        attachmentError={attachmentError}
        attachment={attachment}
        reopenTicket={reopenTicket}
        testAttachment={testAttachment}
        close={() => setShowReopenEditor(false)}
      />

      <SensitiveDataModal
        idTicketStatus={ticket.idSituacao}
        sensitiveData={ticket.dadosSigilosos}
        show={showSensitiveData}
        fetching={updating}
        updateSensitiveData={updateSensitiveData}
        close={() => setShowSensitiveData(false)}
      />
    </Container>
  );
};

export default withRouter(Ticket);
