import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Card, Row, Col, InputGroup } from 'react-bootstrap';
import * as Yup from 'yup';
import CurrencyInput from 'react-currency-input-field';

import useBalance from 'hooks/useBalance';
import useCustomers from 'hooks/useCustomers';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import swal from 'services/swal';
import history from 'services/history';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Error from 'components/Error';

import { Container } from './styles';

interface RequestCreditTransferFormValues {
  customer: string;
  valor: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const client = useSelector((state: StoreState) => state.client.info);

  const { customers } = useCustomers(client.idRepresentante);

  customers.sort((a, b) => (a.email1 > b.email1 ? 1 : -1));

  const { balance, refetch, error: balanceError } = useBalance(
    client.idCliente,
    client.tipoCobranca,
  );

  const submitForm = useCallback(
    async (values: RequestCreditTransferFormValues, formikHelpers) => {
      const idClienteEmissor = client.idCliente;
      const idClienteBeneficiario = values.customer;
      const valor = parseFloat(
        values.valor.replace(/\./, '').replace(/,/, '.'),
      );

      try {
        await api.post(`financeiro/v1/transferencia`, {
          idClienteEmissor,
          idClienteBeneficiario,
          valor,
        });

        formikHelpers.resetForm();
        refetch();

        swal.fire({
          icon: 'success',
          title: 'Sucesso',
          html: t('pages:creditTransferNew.Success'),
        });

        history.push('/financeiro/transferencias');
      } catch (err) {
        swal.fire({
          title: t('pages:creditTransferNew.Error'),
          html: err.response.data.error_description,
        });
      }
    },
    [client, t, refetch],
  );

  if (balanceError) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:creditTransferNew.title')}
          description={t('pages:creditTransferNew.description')}
          helpContent={
            <div>
              <p>{t('pages:creditTransferNew.helpContent.p1')}</p>
            </div>
          }
        />

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            customer: '',
            valor: '0,001',
          }}
          validationSchema={Yup.object().shape({
            customer: Yup.string().required(t('validations:requiredField')),
            valor: Yup.string().required(
              t('pages:creditTransferNew.invalidValue'),
            ),
          })}
          onSubmit={submitForm}
        >
          {props => {
            function handleOnValueChange(value: string | undefined): void {
              let valor = '';
              if (value !== '' && value !== undefined) {
                valor = value.search(/^[.,]/) === 0 ? `0${value}` : value;
              }
              props.setFieldValue('valor', valor);
            }

            return (
              <Form onSubmit={props.handleSubmit}>
                <Card.Body className="fieldset">
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('common:balance')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        plaintext
                        readOnly
                        value={balance.formattedBalance}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:creditTransferNew.balanceDescription')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('common:customer')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        as="select"
                        id="customer"
                        name="customer"
                        value={props.values.customer}
                        onChange={e => {
                          props.handleChange(e);
                        }}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.customer && !!props.errors.customer
                        }
                        disabled={props.isSubmitting}
                      >
                        <option key="" value="">
                          {}
                        </option>
                        {customers.map(customer => {
                          return (
                            <option
                              key={customer.idCliente}
                              value={customer.idCliente}
                            >
                              {customer.email1}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.customer}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  {balance.balance > 0 && (
                    <Form.Group as={Row}>
                      <Form.Label column sm={2}>
                        {t('common:value')}
                      </Form.Label>
                      <Col sm={10}>
                        <InputGroup className="mr-sm-2" size="sm">
                          <InputGroup.Prepend>
                            <InputGroup.Text>R$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <CurrencyInput
                            intlConfig={{ locale: 'pt-br' }}
                            id="value"
                            className="form-control"
                            name="value"
                            placeholder="0,00"
                            disabled={props.isSubmitting}
                            onValueChange={handleOnValueChange}
                            onBlur={props.handleBlur}
                            decimalsLimit={2}
                            decimalScale={2}
                            maxLength={12}
                            allowNegativeValue={false}
                            value={props.values.valor}
                          />
                        </InputGroup>
                        {props.errors.valor && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {props.errors.valor}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Group>
                  )}
                </Card.Body>
                <div className="border-top pt-2 pb-2 pl-3">
                  {balance.balance > 0 && (
                    <PanelButton
                      type="submit"
                      className="mr-1"
                      disabled={
                        parseFloat(props.values.valor) < 1 ||
                        props.isSubmitting ||
                        !props.isValid
                      }
                    >
                      {props.isSubmitting
                        ? t('common:sending')
                        : t('common:send')}
                    </PanelButton>
                  )}

                  <PanelButton
                    variant="secondary"
                    forwardedAs={Link}
                    to="/financeiro/transferencias"
                    disabled={props.isSubmitting}
                  >
                    {t('common:back')}
                  </PanelButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
