import React from 'react';
import { useSelector } from 'react-redux';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';
import * as Cookies from 'js-cookie';

import AuthLayout from 'pages/_layouts/auth';
import DefaultLayout from 'pages/_layouts/default';
import Guard from './Guard';

import { StoreState } from '../store/createStore';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  guards?: ((storeState: StoreState) => boolean)[];
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  guards = [],
  ...rest
}) => {
  const { authenticated } = useSelector((state: StoreState) => state.auth);

  const authCookie = Cookies.get('auth') === 'true';

  const userAuthenticated = authenticated && authCookie;

  if (isPrivate !== userAuthenticated) {
    return <Redirect to={{ pathname: isPrivate ? '/' : '/home' }} />;
  }

  const Layout = userAuthenticated ? DefaultLayout : AuthLayout;

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return (
          <Layout>
            <Guard guards={guards}>
              <Component />
            </Guard>
          </Layout>
        );
      }}
    />
  );
};

export default Route;
