import React from 'react';

import { AccordionLink, ChevronRightIcon } from './styles';

interface SideMenuAccordionLinkProps {
  text: string;
  to: string;
}

const SideMenuAccordionLink: React.FC<SideMenuAccordionLinkProps> = ({
  text,
  to,
}) => {
  return (
    <AccordionLink to={to}>
      <ChevronRightIcon />
      {text}
    </AccordionLink>
  );
};

export default SideMenuAccordionLink;
