import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';

import { useQuery } from 'react-query';

import api from 'services/api';
import formatCurrency from 'utils/formatCurrency';

type Commission = {
  idComissao: number;
  idCliente: number;
  emailCliente: string;
  idSite: number;
  site: string;
  valorComissao: number;
  valorComissaoFormatado: string;
  dataCriacao: string;
  dataCriacaoFormatada: string;
  proxVencimento: string;
  descricao: string;
  detalhes: string;
  status: string;
};

type CommissionsResponse = {
  data: {
    idComissao: number;
    idCliente: number;
    emailCliente: string;
    idSite: number;
    site: string;
    valorComissao: number;
    dataCriacao: string;
    proxVencimento: string;
    descricao: string;
    detalhes: string;
    status: string;
  }[];
};

async function loadCommissions(
  idRepresentante: number,
  initialDate = '',
  finalDate = '',
  status = ['EXECUTADO'],
): Promise<Commission[]> {
  const commissionsResponse = await api.get<CommissionsResponse>(
    `clientes/v1/representante/${idRepresentante}/comissao`,
    {
      params: {
        'filter[status]': status,
        ...(initialDate && { 'filter[dataInicial]': initialDate }),
        ...(finalDate && { 'filter[dataFinal]': finalDate }),
      },
    },
  );

  const commissions = commissionsResponse.data.data.map(
    ({
      idComissao,
      emailCliente,
      descricao,
      site,
      dataCriacao,
      valorComissao,
      ...rest
    }) => {
      const formattedDate = format(parseISO(dataCriacao), 'dd/MM/yyyy', {
        locale: pt,
      });

      const formattedCommission = formatCurrency(valorComissao);

      return {
        idComissao,
        emailCliente,
        site,
        valorComissaoFormatado: formattedCommission,
        dataCriacaoFormatada: formattedDate,
        dataCriacao,
        valorComissao,
        descricao,
        ...rest,
      };
    },
  );

  return commissions;
}

const initialData: Commission[] = [];

export default function useCommissions(
  idRepresentante: number,
  initialDate = '',
  finalDate = '',
  status = ['EXECUTADO'],
) {
  const { data, ...rest } = useQuery<Commission[]>(
    ['commissions', initialDate, finalDate, status],
    () => loadCommissions(idRepresentante, initialDate, finalDate, status),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const commissions = data || initialData;

  return { commissions, ...rest };
}
