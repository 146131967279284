import React, { useCallback, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { FaFileExport, FaQuestionCircle } from 'react-icons/fa';
import i18n from 'i18n';

import {
  Container,
  Title,
  Description,
  TableHeaderButton,
  Help,
  HelpContent,
  HelpLinks,
  LinkList,
  ExportTable,
} from './styles';

interface HelpLink {
  text: string;
  url: string;
}

interface TableHeaderProps {
  title: string;
  description: string;
  helpContent?: React.ReactNode;
  startOpen?: string;
  helpLinks?: HelpLink[];
  exportTableContent?: React.ReactNode;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  title,
  description,
  helpContent,
  startOpen = '',
  exportTableContent,
  helpLinks = [
    {
      text: i18n.t('common:learnMoreInHelpCenter'),
      url: 'https://www.hostnet.com.br/info/',
    },
  ],
}) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(startOpen);

  const handleTableHeaderButtonClick = useCallback(
    (selectedTab: string) => {
      if (selectedTab === activeTab) {
        setActiveTab('');
      } else {
        setActiveTab(selectedTab);
      }
    },
    [activeTab],
  );

  return (
    <Container>
      <Card.Body>
        <Row>
          <Col xs={9}>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Col>
          <Col xs={3}>
            <Row className="justify-content-end mr-1">
              {helpContent && (
                <TableHeaderButton
                  onClick={() => handleTableHeaderButtonClick('help')}
                  className={activeTab === 'help' ? 'active' : ''}
                >
                  <FaQuestionCircle
                    color="#2c4593"
                    size={23}
                    className="mb-1"
                  />
                  <div>{t('common:help')}</div>
                </TableHeaderButton>
              )}

              {exportTableContent && (
                <TableHeaderButton
                  onClick={() => handleTableHeaderButtonClick('exportTable')}
                  className={activeTab === 'exportTable' ? 'active' : ''}
                >
                  <FaFileExport color="#2c4593" size={23} className="mb-1" />
                  <div>Exportar</div>
                </TableHeaderButton>
              )}
            </Row>
          </Col>
        </Row>
      </Card.Body>

      <Help className={activeTab === 'help' && 'active'}>
        <Row>
          <Col xs={12} md={7}>
            <HelpContent>
              <Trans>{helpContent}</Trans>
            </HelpContent>
          </Col>
          <Col xs={12} md={5} className="border-left">
            <HelpLinks>
              <LinkList>
                {helpLinks.map(link => (
                  <li key={link.url} className="mb-2">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.url}
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </LinkList>
            </HelpLinks>
          </Col>
        </Row>
      </Help>

      <ExportTable className={activeTab === 'exportTable' ? 'active' : ''}>
        {exportTableContent}
      </ExportTable>
    </Container>
  );
};

export default TableHeader;
