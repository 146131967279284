import { Card, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.div`
  .blue {
    background-color: var(--color-blue);

    svg {
      color: var(--color-blue);
    }
  }

  .violet {
    background-color: var(--color-violet);

    svg {
      color: var(--color-violet);
    }
  }

  .green {
    background-color: var(--color-green);

    svg {
      color: var(--color-green);
    }
  }
`;

export const ResourceCard = styled(Card)`
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1),
    0 11px 6px -7px rgba(43, 43, 43, 0.1);
  border: none;
  transition: all 0.3s ease-in-out;

  min-height: 127px;
`;

export const Name = styled.div`
  font-size: 24px;
`;

export const IconWrapper = styled.div`
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 17px 0;
  border-radius: 50%;

  background-color: #fff;
  box-shadow: 0 8px 15px 0 rgba(51, 51, 51, 0.2);

  transition: all 0.3s ease-in-out;
`;

export const StyledSpinner = styled(Spinner)`
  display: block;
  margin: 2px 0 2px auto;
`;
