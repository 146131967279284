import React from 'react';
import { useTranslation } from 'react-i18next';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

interface WithdrawActionsProps {
  loading: boolean;
  deleteWithdraws: () => void;
}

const WithdrawActions: React.FC<WithdrawActionsProps> = ({
  loading,
  deleteWithdraws,
}) => {
  const { t } = useTranslation();

  return (
    <TableActionsWrapper loading={loading}>
      <TableButton
        className="mr-2"
        variant="danger"
        size="sm"
        disabled={loading}
        onClick={() => deleteWithdraws()}
      >
        {t('common:cancel')}
      </TableButton>
    </TableActionsWrapper>
  );
};

export default WithdrawActions;
