import { combineReducers } from 'redux';

import { StoreState } from '../createStore';

import auth from './auth/reducer';
import sites from './sites/reducer';
import client from './client/reducer';

export default combineReducers<StoreState>({
  auth,
  client,
  sites,
});
