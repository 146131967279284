import { useQuery } from 'react-query';

import api from 'services/api';

type CommissionBalance = {
  pendente: string;
  recebido: string;
  saldo: string;
};

type CommissionBalanceResponse = {
  data: CommissionBalance;
};

async function loadCommissions(
  idRepresentante: number,
): Promise<CommissionBalance> {
  const commissionsBalanceResponse = await api.get<CommissionBalanceResponse>(
    `clientes/v1/representante/${idRepresentante}/comissao/saldo`,
  );

  const commissionsBalance = commissionsBalanceResponse.data.data;

  return commissionsBalance;
}

const initialData: CommissionBalance = {
  pendente: '0',
  recebido: '0',
  saldo: '0',
};

export default function useCommissions(idRepresentante: number) {
  const {
    data,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<CommissionBalance>(
    'commissionBalance',
    () => loadCommissions(idRepresentante),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const commissionBalance = data || initialData;

  return {
    commissionBalance,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
