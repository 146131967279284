import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { format, parseISO, startOfDay, subYears } from 'date-fns';
import pt from 'date-fns/locale/pt/index';
import { Row, Col, Card, Form, InputGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import { StoreState } from 'store/createStore';

import formatCurrency from 'utils/formatCurrency';
import filter from 'utils/filter';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import Error from 'components/Error';
import TableIconButton from 'components/TableIconButton';
import PanelCard from 'components/PanelCard';
import TableNoData from 'components/TableNoData';
import TableExportContent from 'components/TableExportContent';

import useCommissionBalance from 'hooks/useCommissionBalance';
import useCommissions from 'hooks/useCommissions';

import swal from 'services/swal';

import CommissionModal from './components/CommissionModal';

import { Container, Info, TableHeaderButton } from './styles';

const List: React.FC = () => {
  const { t } = useTranslation();

  const client = useSelector((state: StoreState) => state.client.info);

  const minDate = startOfDay(subYears(new Date(), 3));
  const maxDate = startOfDay(new Date());

  const minDateISOString = minDate.toISOString().split('T')[0];
  const maxDateISOString = maxDate.toISOString().split('T')[0];

  const [fromDate, setFromDate] = useState(minDate);
  const [toDate, setToDate] = useState(maxDate);

  const fromDateISOString = fromDate.toISOString().split('T')[0];
  const toDateISOString = toDate.toISOString().split('T')[0];

  const [dateRangeValues, setDateRangeValues] = useState({
    fromDate: fromDateISOString,
    toDate: toDateISOString,
  });

  const [showCommission, setShowCommission] = useState(false);
  const [detailsModal, setDetailsModal] = useState('');

  const {
    commissions,
    isError: commissionsError,
    isFetching: commissionsFetching,
  } = useCommissions(
    client.idRepresentante,
    dateRangeValues.fromDate,
    dateRangeValues.toDate,
  );

  const {
    commissions: pendingCommissions,
    isError: pendingCommissionsError,
    isFetching: pendingCommissionsFetching,
  } = useCommissions(client.idRepresentante, '', '', [
    'PENDENTE',
    'APROVADO',
    'AGUARDANDO_QUITACAO',
  ]);

  const {
    commissionBalance,
    error: commissionBalanceError,
    fetching: commissionBalanceFetching,
  } = useCommissionBalance(client.idRepresentante);

  const [filterTextCommissions, setFilterTextCommissions] = useState('');
  const [
    filterTextPendingCommissions,
    setFilterTextPendingCommissions,
  ] = useState('');

  const filteredCommissions = filter(
    commissions,
    ['emailCliente', 'site', 'dataCriacao', 'valorComissao', 'descricao'],
    filterTextCommissions,
  );

  const filteredPendingCommissions = filter(
    pendingCommissions,
    ['emailCliente', 'site', 'dataCriacao', 'valorComissao', 'descricao'],
    filterTextPendingCommissions,
  );

  const openCommissionModal = useCallback(detalhes => {
    setDetailsModal(detalhes);
    setShowCommission(true);
  }, []);

  function updateDateRange() {
    if (fromDate > toDate) {
      swal.fire(
        'Datas inválidas',
        'A data inicial deve ser menor que a data final.',
      );

      return;
    }

    if (fromDate < minDate || fromDate > maxDate) {
      swal.fire(
        'Datas inválidas',
        'As datas informadas não estão dentro dos limites permitidos.',
      );

      return;
    }

    if (toDate < minDate || toDate > maxDate) {
      swal.fire(
        'Datas inválidas',
        'As datas informadas não estão dentro dos limites permitidos.',
      );

      return;
    }

    setDateRangeValues({
      fromDate: fromDateISOString,
      toDate: toDateISOString,
    });
  }

  const loading =
    commissionsFetching ||
    pendingCommissionsFetching ||
    commissionBalanceFetching;

  const error =
    commissionsError || pendingCommissionsError || commissionBalanceError;

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />

      <Row className="my-4">
        <Col className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('pages:commission.received')}</h6>
                <Info className="blue">
                  {!loading &&
                    formatCurrency(parseFloat(commissionBalance.recebido))}
                </Info>
              </div>
            </Card.Body>
          </PanelCard>
        </Col>
        <Col className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('pages:commission.pending')}</h6>
                <Info className="blue">
                  {!loading &&
                    formatCurrency(parseFloat(commissionBalance.pendente))}
                </Info>
              </div>
            </Card.Body>
          </PanelCard>
        </Col>
        <Col className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('pages:commission.balance')}</h6>
                <Info className="blue">
                  {!loading &&
                    formatCurrency(parseFloat(commissionBalance.saldo))}
                </Info>
              </div>
            </Card.Body>
          </PanelCard>
        </Col>
      </Row>

      <TableWrapper>
        <TableHeader
          title={t('pages:commission.pendingCommissions')}
          description={t('pages:commission.pendingCommissionsDescription')}
          helpContent={
            <div>
              <p>{t('pages:commission.helpContent.p1')}</p>
            </div>
          }
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput
              onChange={value => setFilterTextPendingCommissions(value)}
            />
          </div>
        </TableSubHeader>
        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            columns={[
              {
                name: t('pages:commission.client'),
                selector: 'emailCliente',
                width: '240px',
                sortable: true,
              },
              {
                name: t('common:site'),
                selector: 'site',
                width: '240px',
                sortable: true,
              },
              {
                name: t('pages:commission.commission'),
                width: '110px',
                selector: 'valorComissao',
                sortable: true,
                cell: (row: typeof pendingCommissions[number]) =>
                  formatCurrency(row.valorComissao),
              },
              {
                name: t('common:date'),
                width: '110px',
                selector: 'dataCriacao',
                sortable: true,
                cell: (row: typeof pendingCommissions[number]) =>
                  format(parseISO(row.dataCriacao), 'dd/MM/yyyy', {
                    locale: pt,
                  }),
              },
              {
                name: t('pages:commission.paymentDueDate'),
                width: '110px',
                selector: 'proxVencimento',
                sortable: true,
                cell: (row: typeof pendingCommissions[number]) =>
                  row.proxVencimento &&
                  format(parseISO(row.proxVencimento), 'dd/MM/yyyy', {
                    locale: pt,
                  }),
              },
              {
                name: t('common:description'),
                selector: 'descricao',
                cell: (row: typeof pendingCommissions[number]) => {
                  const { descricao } = row;
                  return <div className="my-2">{descricao}</div>;
                },
              },
            ]}
            data={filteredPendingCommissions}
            progressPending={pendingCommissionsFetching}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          />
        </div>
      </TableWrapper>
      <TableWrapper>
        <TableHeader
          title={t('pages:commission.receivedCommissions')}
          description={t('pages:commission.receivedCommissionsDescription')}
          helpContent={
            <div>
              <p>{t('pages:commission.helpContent.p2')}</p>
            </div>
          }
          exportTableContent={
            <TableExportContent
              data={commissions}
              headers={[
                {
                  key: 'idComissao',
                  label: 'id',
                },
                {
                  key: 'emailCliente',
                  label: 'emailCliente',
                },
                {
                  key: 'site',
                  label: 'site',
                },
                {
                  key: 'dataCriacaoFormatada',
                  label: 'dataCriacao',
                },
                {
                  key: 'valorComissaoFormatado',
                  label: 'valorComissao',
                },
                {
                  key: 'descricao',
                  label: 'descricao',
                },
              ]}
              fileName={`comissoes-${fromDateISOString}-${toDateISOString}`}
            />
          }
        />
        <TableSubHeader>
          <div className="d-flex mb-sm-3 mb-md-0 mb-xs-3">
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">De</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled={loading}
                defaultValue={fromDateISOString}
                value={fromDateISOString}
                type="date"
                min={minDateISOString}
                max={maxDateISOString}
                onChange={e => {
                  if (e.target.value === '') {
                    setFromDate(minDate);
                    return;
                  }

                  setFromDate(new Date(e.target.value));
                }}
              />
            </InputGroup>
            <InputGroup className="ml-2" size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Até</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled={loading}
                value={toDateISOString}
                type="date"
                min={minDateISOString}
                max={maxDateISOString}
                onChange={e => {
                  if (e.target.value === '') {
                    setToDate(maxDate);
                    return;
                  }

                  setToDate(new Date(e.target.value));
                }}
              />
            </InputGroup>
            <TableHeaderButton
              className="ml-2"
              disabled={loading}
              onClick={() => updateDateRange()}
            >
              {loading ? 'Carregando...' : 'Buscar'}
            </TableHeaderButton>
          </div>
          <div className="d-flex">
            <FilterInput onChange={value => setFilterTextCommissions(value)} />
          </div>
        </TableSubHeader>
        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            columns={[
              {
                name: '',
                cell: (row: typeof filteredCommissions[number]) => (
                  <div>
                    <TableIconButton
                      tooltipConfig={{
                        id: 'displayDns',
                        placement: 'right',
                        content: t('pages:commission.seeDetails'),
                      }}
                      onClick={() => openCommissionModal(row.detalhes)}
                    >
                      <FaMoneyBillAlt />
                    </TableIconButton>
                  </div>
                ),
                width: '80px',
              },
              {
                name: t('pages:commission.client'),
                selector: 'emailCliente',
                width: '240px',
                sortable: true,
              },
              {
                name: t('common:site'),
                selector: 'site',
                width: '240px',
                sortable: true,
              },
              {
                name: t('pages:commission.commission'),
                width: '110px',
                selector: 'valorComissao',
                sortable: true,
                cell: (row: typeof filteredCommissions[number]) =>
                  formatCurrency(row.valorComissao),
              },
              {
                name: t('common:date'),
                width: '110px',
                selector: 'dataCriacao',
                sortable: true,
                cell: (row: typeof filteredCommissions[number]) =>
                  format(parseISO(row.dataCriacao), 'dd/MM/yyyy', {
                    locale: pt,
                  }),
              },
              {
                name: t('common:description'),
                selector: 'descricao',
                cell: (row: typeof filteredCommissions[number]) => {
                  const { descricao } = row;
                  return <div className="my-2">{descricao}</div>;
                },
              },
            ]}
            data={filteredCommissions}
            progressPending={loading}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          />
        </div>
      </TableWrapper>

      <CommissionModal
        open={showCommission}
        details={detailsModal}
        hide={() => setShowCommission(false)}
      />
    </Container>
  );
};

export default List;
