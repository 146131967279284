import { useQuery } from 'react-query';

import api from 'services/api';

type CustomerItemResponse = {
  idRepresentanteCliente: number;
  idRepresentante: number;
  idCliente: number;
  ativo: boolean;
  dataAtivacao: string;
  dataDesativacao: string;
  cliente: {
    idCliente: number;
    email1: string;
    nome: string;
    responsavel: string;
    dataCadastro: string;
    tipoCobranca: 'UNICA' | 'MULTIPLA';
    proxVencimento: string;
  };
};

type CustomerResponse = {
  data: CustomerItemResponse[];
};

type Customer = {
  idRepresentanteCliente: number;
  idRepresentante: number;
  idCliente: number;
  ativo: boolean;
  email1: string;
  dataAtivacao: string;
  dataDesativacao: string;
  cliente: {
    idCliente: number;
    email1: string;
    nome: string;
    responsavel: string;
    dataCadastro: string;
    tipoCobranca: 'UNICA' | 'MULTIPLA';
    proxVencimento: string;
  };
};

const initialData: Customer = {
  idRepresentanteCliente: 0,
  idRepresentante: 0,
  idCliente: 0,
  ativo: true,
  email1: '',
  dataAtivacao: '2000-01-01',
  dataDesativacao: '',
  cliente: {
    idCliente: 0,
    email1: '',
    nome: '',
    responsavel: '',
    dataCadastro: '2000-01-01',
    tipoCobranca: 'UNICA',
    proxVencimento: '2000-01-01',
  },
};

function formatCustomer(customer: CustomerItemResponse): Customer {
  const {
    idRepresentanteCliente,
    idRepresentante,
    idCliente,
    cliente,
    ...customerRest
  } = customer;

  return {
    idCliente: Number(idCliente),
    idRepresentante: Number(idRepresentante),
    idRepresentanteCliente: Number(idRepresentanteCliente),
    email1: cliente.email1,
    cliente,
    ...customerRest,
  };
}

async function loadCustomer(
  idRepresentante: number,
  idCustomer: number,
): Promise<Customer> {
  const customersResponse = await api.get<CustomerResponse>(
    `clientes/v1/representante/${idRepresentante}/cliente`,
    {
      params: {
        'filter[ativo]': 1,
        'filter[idCliente]': idCustomer,
      },
    },
  );

  const customers = customersResponse.data.data.map(formatCustomer);

  return customers[0];
}

export default function useCustomer(
  idRepresentante: number,
  idCustomer: number,
) {
  const { data, ...rest } = useQuery<Customer>(
    ['customer', idCustomer],
    () => loadCustomer(idRepresentante, idCustomer),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const customer = data || initialData;

  return {
    customer,
    ...rest,
  };
}
