import { useQuery } from 'react-query';

import api from 'services/api';

type MarketingServiceItemResponse = {
  idServico: number;
  idServicoCategoria: number;
  categoria: string;
  servico: string;
  setup: string;
  valor: string;
  periodicidade: string;
  requisito: string;
};

type MarketingServicesResponse = {
  data: MarketingServiceItemResponse[];
};

type MarketingService = {
  idServico: number;
  idServicoCategoria: number;
  categoria: string;
  servico: string;
  setup: number;
  valor: number;
  periodicidade: string;
  requisito: string;
};

function formatMarketingService(
  service: MarketingServiceItemResponse,
): MarketingService {
  const { setup, valor, ...rest } = service;

  return {
    setup: Number(setup),
    valor: Number(valor),
    ...rest,
  };
}

async function loadMarketingServices(
  idRepresentante: number,
): Promise<MarketingService[]> {
  const marketingInstallationServicesCall = api.get<MarketingServicesResponse>(
    `clientes/v1/representante/${idRepresentante}/servico`,
    {
      params: {
        'filter[idServicoCategoria]': 6,
      },
    },
  );

  const marketingIntegrationServicesCall = api.get<MarketingServicesResponse>(
    `clientes/v1/representante/${idRepresentante}/servico`,
    {
      params: {
        'filter[idServicoCategoria]': 7,
      },
    },
  );

  const [
    marketingInstallationServicesResponse,
    marketingIntegrationServicesResponse,
  ] = await Promise.all([
    marketingInstallationServicesCall,
    marketingIntegrationServicesCall,
  ]);

  const marketingInstallationServices = marketingInstallationServicesResponse.data.data.map(
    formatMarketingService,
  );

  const marketingIntegrationServices = marketingIntegrationServicesResponse.data.data.map(
    formatMarketingService,
  );

  return [...marketingInstallationServices, ...marketingIntegrationServices];
}

const initialData: MarketingService[] = [];

export default function useMarketingServices(idRepresentante: number) {
  const { data, ...rest } = useQuery<MarketingService[]>(
    'marketingServices',
    () => loadMarketingServices(idRepresentante),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const marketingServices = data || initialData;

  return {
    marketingServices,
    ...rest,
  };
}
