import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FaServer } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import { StoreState } from 'store/createStore';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import TableNoData from 'components/TableNoData';
import TableHeader from 'components/TableHeader';
import TableButton from 'components/TableButton';

import api from 'services/api';
import swal from 'services/swal';

import filter from 'utils/filter';

import useCustomizedServices from 'hooks/useCustomizedServices';

import toast from 'services/toast';
import CustomizedServiceActions from './components/CustomizedServiceActions';

import { Container } from './styles';

const List: React.FC = () => {
  const { t } = useTranslation();

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { customizedServices, isLoading, refetch } = useCustomizedServices(
    idRepresentante,
  );

  type CustomizedService = typeof customizedServices[number];

  const [filterText, setFilterText] = useState('');
  const [filteredCustomizedServices, setFilteredCustomizedServices] = useState<
    typeof customizedServices
  >([]);

  const [deleting, setDeleting] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof customizedServices;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  useEffect(() => {
    const filtered = filter(
      customizedServices,
      ['servico', 'valorFormatado', 'setupFormatado'],
      filterText,
    );

    setFilteredCustomizedServices(filtered);
  }, [customizedServices, filterText]);

  async function deleteCustomizedService() {
    try {
      const servicesIds = selectedRowsInfo.selectedRows.map(
        ({ idServico }) => idServico,
      );

      const { isConfirmed } = await swal.fire({
        icon: 'warning',
        title: t('common:warning'),
        text: t('pages:customizedServices.deleteWarning', {
          count: selectedRowsInfo.selectedCount,
        }),
        showCancelButton: true,
      });

      if (!isConfirmed || servicesIds.length === 0) {
        return;
      }

      setDeleting(true);

      const [serviceId] = servicesIds;

      await api.put(
        `clientes/v1/representante/${idRepresentante}/servico/${serviceId}`,
        {
          ativo: false,
        },
      );

      await refetch();

      setToggleCleared(prev => !prev);

      setDeleting(false);

      toast.fire(t('pages:customizedServices:deleteSuccess'));
    } catch (err) {
      swal.fire({
        title: t('common:label.operationFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  const columns = [
    {
      name: 'Item',
      selector: 'servico',
      sortable: true,
    },
    {
      name: t('pages:customizedServices.singlePayment'),
      selector: 'setup',
      cell: (row: CustomizedService) =>
        row.periodicidade === 'SETUP'
          ? row.setupFormatado
          : t('pages:customizedServices.doesNotHave'),
      sortable: true,
      maxWidth: '200px',
    },
    {
      name: t('pages:customizedServices.monthlyPayment'),
      selector: 'valor',
      cell: (row: CustomizedService) =>
        row.periodicidade === 'MENSAL'
          ? row.valorFormatado
          : t('pages:customizedServices.doesNotHave'),
      sortable: true,
      maxWidth: '200px',
    },
  ];

  return (
    <Container>
      <PageTitle
        icon={<FaServer color="#FFFFFF" size={24} />}
        title={t('titles:services.title')}
        description={t('titles:services.description')}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:customizedServices.title')}
          description={t('pages:customizedServices.description')}
          helpContent={
            <div>
              <p>{t('pages:customizedServices.helpContent.p1')}</p>
            </div>
          }
        />
        <TableSubHeader>
          <div>
            <TableButton
              forwardedAs={Link}
              to="/servicos/customizados/cadastrar"
            >
              {t('pages:customizedServices.newCustomizedService')}
            </TableButton>
            <TableButton forwardedAs={Link} to="/clientes">
              {t('pages:customizedServices.sellCustomizedService')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={columns}
            clearSelectedRows={toggleCleared}
            data={filteredCustomizedServices}
            progressPending={isLoading}
            selectableRowDisabled={() => deleting}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            contextComponent={
              <CustomizedServiceActions
                selectedCount={selectedRowsInfo.selectedCount}
                selectedRows={selectedRowsInfo.selectedRows}
                loading={deleting}
                deleteCustomizedService={() => deleteCustomizedService()}
              />
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            className="datatables-table"
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default List;
