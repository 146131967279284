import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FaCogs } from 'react-icons/fa';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import TableNoData from 'components/TableNoData';
import Loading from 'components/Loading';
import TableButton from 'components/TableButton';

import { StoreState } from 'store/createStore';

import useCustomersServices from 'hooks/useCustomersServices';

import swal from 'services/swal';
import toast from 'services/toast';
import api from 'services/api';

import formatCurrency from 'utils/formatCurrency';

// import { Container } from './styles';

const ActiveServices: React.FC = () => {
  const { t } = useTranslation();

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { customersServices, isLoading, refetch } = useCustomersServices(
    idRepresentante,
  );

  const [deleting, setDeleting] = useState(false);

  async function deleteActiveService(idCustomerService: number) {
    try {
      const { isConfirmed } = await swal.fire({
        icon: 'warning',
        title: t('common:warning'),
        text: t('pages:activeServices.deleteWarning', {
          count: 1,
        }),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setDeleting(true);

      await api.delete(
        `clientes/v1/representante/${idRepresentante}/cliente/servico`,
        {
          params: {
            idClienteServico: idCustomerService,
          },
        },
      );

      await refetch();

      toast.fire(t('pages:activeServices.deleteSuccess'));
    } catch (err) {
      swal.fire({
        title: t('common:label.operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setDeleting(false);
    }
  }

  return (
    <div>
      <PageTitle
        icon={<FaCogs color="#FFFFFF" size={24} />}
        title={t('titles:marketing.title')}
        description={t('titles:marketing.description')}
      />

      <TableWrapper>
        <TableHeader
          title="Serviços ativos"
          description="Serviços ativos de automação de marketing"
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => value} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            dense
            noHeader
            pagination
            columns={[
              {
                width: '110px',
                name: '',
                cell: ({ idClienteServico }) => {
                  return (
                    <TableButton
                      disabled={deleting}
                      onClick={() => deleteActiveService(idClienteServico)}
                      className="btn-in-table btn-danger"
                    >
                      Remover
                    </TableButton>
                  );
                },
              },
              {
                name: 'Cliente',
                selector: 'email1',
              },
              {
                name: 'Site',
                selector: 'site',
              },
              {
                name: 'Serviço',
                selector: 'servico',
              },
              {
                name: 'Valor',
                selector: 'valor',
                sortable: true,
                cell: row => formatCurrency(row.valor),
              },
            ]}
            data={customersServices}
            progressPending={isLoading}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            className="datatables-table"
          />
        </div>
      </TableWrapper>
    </div>
  );
};

export default ActiveServices;
