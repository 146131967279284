import { useQuery } from 'react-query';

import api from 'services/api';

type CustomerServiceItemResponse = {
  idClienteServico: number;
  idServico: number;
  idServicoCategoria: number;
  idRepresentante: number;
  idCliente: number;
  email1: string;
  idSite: number;
  site: string;
  servico: string;
  valor: string;
  periodicidade: string;
  status: string;
  dataSolicitacao: string;
};

type CustomersServicesResponse = {
  data: CustomerServiceItemResponse[];
};

type CustomerService = {
  idClienteServico: number;
  idServico: number;
  idServicoCategoria: number;
  idRepresentante: number;
  idCliente: number;
  email1: string;
  idSite: number;
  site: string;
  servico: string;
  valor: number;
  periodicidade: string;
  status: string;
  dataSolicitacao: string;
};

const initialData: CustomerService[] = [];

function formatCustomerService(
  customerService: CustomerServiceItemResponse,
): CustomerService {
  const {
    idClienteServico,
    idServico,
    idServicoCategoria,
    idRepresentante,
    idCliente,
    email1,
    idSite,
    site,
    servico,
    valor,
    periodicidade,
    status,
    dataSolicitacao,
  } = customerService;

  return {
    idClienteServico,
    idServico,
    idServicoCategoria,
    idRepresentante,
    idCliente,
    email1,
    idSite,
    site,
    servico,
    valor: Number(valor),
    periodicidade,
    status,
    dataSolicitacao,
  };
}

async function loadCustomersServices(
  idRepresentante: number,
): Promise<CustomerService[]> {
  const customersRequestCatogory6 = api.get<CustomersServicesResponse>(
    `clientes/v1/representante/${idRepresentante}/cliente/servico`,
    {
      params: {
        idServicoCategoria: 6,
        status: ['SOLICITADO', 'PROCESSANDO', 'REVISANDO', 'CONCLUIDO'],
      },
    },
  );

  const customersRequestCategory7 = api.get<CustomersServicesResponse>(
    `clientes/v1/representante/${idRepresentante}/cliente/servico`,
    {
      params: {
        idServicoCategoria: 7,
        status: ['SOLICITADO', 'PROCESSANDO', 'REVISANDO', 'CONCLUIDO'],
      },
    },
  );

  const [
    customersResponseCatogory6,
    customersResponseCategory7,
  ] = await Promise.all([customersRequestCatogory6, customersRequestCategory7]);

  const servicesCategory6 = customersResponseCatogory6.data.data.map(
    formatCustomerService,
  );
  const servicesCategory7 = customersResponseCategory7.data.data.map(
    formatCustomerService,
  );

  return [...servicesCategory6, ...servicesCategory7];
}

export default function useCustomersServices(idRepresentante: number) {
  const { data, ...rest } = useQuery<CustomerService[]>(
    'customersServices',
    () => loadCustomersServices(idRepresentante),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const customersServices = data || initialData;

  return {
    customersServices,
    ...rest,
  };
}
