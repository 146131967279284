import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import i18n from 'i18n';

import api from 'services/api';
import swal from 'services/swal';

import * as actions from './actions';

interface SiteItemResponse {
  idSite: number;
  site: string;
  idPlano: string;
  plano: string;
  idCliente: string;
  valor: string;
  tecnologia: string;
  ftp: 'TRUE' | 'FALSE';
  memoria: string;
  cpu: string;
}

interface SitesResponse {
  data: SiteItemResponse[];
}

function* loadSitesRequest() {
  try {
    const sitesResponse: AxiosResponse<SitesResponse> = yield call(
      api.get,
      `painel/v1/sites`,
    );

    const sites = sitesResponse.data.data.map(
      ({
        idSite,
        idPlano,
        idCliente: idClienteSite,
        site,
        plano,
        valor,
        tecnologia,
        ftp,
        memoria,
        cpu,
      }) => ({
        idSite,
        idPlano: parseInt(idPlano, 10),
        idCliente: parseInt(idClienteSite, 10),
        site,
        plano,
        valor,
        tecnologia,
        ftp: ftp === 'TRUE',
        memoria,
        cpu,
      }),
    );

    yield put(actions.sitesSuccess({ sites }));
  } catch (err) {
    yield put(actions.loadSitesFailed());

    swal.fire({
      html: i18n.t('common:operationFailed'),
    });
  }
}

export default all([takeLatest('@sites/LOAD_SITES_REQUEST', loadSitesRequest)]);
