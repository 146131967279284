import TableButton from 'components/TableButton';
import styled from 'styled-components';

export const Container = styled.div``;

export const Info = styled.div`
  font-size: 24px;
  font-weight: 700;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.blue {
    color: var(--color-blue);
  }
`;

export const TableHeaderButton = styled(TableButton)`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
`;
