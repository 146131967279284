import { useQuery } from 'react-query';

import api from 'services/api';

type DomainGroupItemResponse = {
  enom: Domain[];
  expirados: Domain[];
  registrobr: Domain[];
  rrpproxy: Domain[];
};

type DomainGroupResponse = {
  data: DomainGroupItemResponse;
};

type DomainGroup = {
  enom: Domain[];
  expirados: Domain[];
  registrobr: Domain[];
  rrpproxy: Domain[];
};

type Domain = {
  contato: number;
  dominio: string;
  emailCliente: string;
  expiracao: string;
  idCliente: number;
  status: string;
};

const initialData: DomainGroup = {
  enom: [],
  expirados: [],
  registrobr: [],
  rrpproxy: [],
};
async function loadDomains(idRepresentante: number): Promise<DomainGroup> {
  const domainsResponse = await api.get<DomainGroupResponse>(
    `painel/v1/representante/${idRepresentante}/dominio`,
  );

  const domains = domainsResponse.data.data;

  return domains;
}

export default function useDomains(idRepresentante: number) {
  const {
    data,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<DomainGroup>('domains', () => loadDomains(idRepresentante), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    retry: false,
  });

  const domains = data || initialData;

  return {
    domains,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
