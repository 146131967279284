import { useQuery } from 'react-query';

import api from 'services/api';

interface Charge {
  email1: string;
  site: string;
  descricao: string;
  valor: string;
  vencimento: string;
}

interface ChargesResponse {
  data: Charge[];
}

async function loadOpenCharges(idRepresentante: number): Promise<Charge[]> {
  const chargesResponse = await api.get<ChargesResponse>(
    `financeiro/v1/representante/${idRepresentante}/cobranca`,
  );

  const charges = chargesResponse.data.data;

  return charges;
}

const initialData: Charge[] = [];

export default function useOpenCharges(idRepresentante: number) {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<Charge[]>(
    'openCharges',
    () => loadOpenCharges(idRepresentante),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const charges = data || initialData;

  return {
    charges,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
