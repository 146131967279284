import { applyMiddleware, createStore, Middleware, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { AuthAction, AuthState } from './modules/auth/types';
import { SitesAction, SitesState } from './modules/sites/types';
import { ClientAction, ClientState } from './modules/client/types';

export interface StoreState {
  auth: AuthState;
  client: ClientState;
  sites: SitesState;
}

export type StoreAction = AuthAction | ClientAction | SitesAction;

export default (
  reducers: Reducer<StoreState, StoreAction>,
  middlewares: Middleware[],
) => {
  const enhancer = applyMiddleware(...middlewares);

  return createStore(reducers, composeWithDevTools(enhancer));
};
