import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const StyledTableButton = styled(Button)`
  color: #212529;

  font-size: 11px;
  padding: 8px 14px;

  background: #fff;
  border-color: #ced4da;
  transition: all ease-in 0.3s;

  &.btn-primary:hover {
    background-color: var(--color-azure-blue);
  }

  &.btn-danger:hover {
    background-color: var(--color-salmon-pink);
  }

  &.btn-in-table {
    padding: 0.25rem 0.5rem;
  }
`;
