import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

import hashIds from 'services/hashIds';

interface SiteActionsProps {
  selectedCount: number;
  loading: boolean;
  selectedRows: {
    idSite: number;
    idCliente: number;
  }[];
}

const SiteActions: React.FC<SiteActionsProps> = ({
  loading,
  selectedCount,
  selectedRows,
}) => {
  const { t } = useTranslation();

  const siteHashId = hashIds.encode(selectedRows[0].idSite);
  const customerHashId = hashIds.encode(selectedRows[0].idCliente);

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 && (
        <>
          <TableButton
            forwardedAs={Link}
            to={`/painel/${customerHashId}?site=${siteHashId}`}
            className="mx-1"
            target="_blank"
            disabled={loading}
          >
            {t('pages:sites.customerDashboard')}
          </TableButton>
          <TableButton
            forwardedAs={Link}
            to={`/venda/${customerHashId}?site=${siteHashId}`}
            className="mx-1"
            disabled={loading}
          >
            {t('common:sell')}
          </TableButton>
        </>
      )}
    </TableActionsWrapper>
  );
};

export default SiteActions;
