import { useQuery } from 'react-query';

import api from 'services/api';

interface MessageItemResponse {
  idMensagem: number;
  atendente: string;
  mensagem: string;
  avaliacao: string;
  dataHora: string;
  tipo: string;
  anexos: {
    anexo: string;
    caminho: string;
  }[];
}

interface MessagesResponse {
  data: MessageItemResponse[];
}

interface Message {
  idMensagem: number;
  atendente: string;
  mensagem: string;
  avaliacao: string;
  avaliada: boolean;
  dataHora: string;
  tipo: string;
  mensagemAtendente: boolean;
  anexos: {
    anexo: string;
    caminho: string;
  }[];
}

const initialData: Message[] = [];

function formatMessage(messageItem: MessageItemResponse): Message {
  return {
    idMensagem: messageItem.idMensagem,
    atendente: messageItem.atendente,
    mensagem: messageItem.mensagem,
    avaliacao: messageItem.avaliacao,
    avaliada: !!messageItem.avaliacao,
    dataHora: messageItem.dataHora,
    tipo: messageItem.tipo,
    anexos: messageItem.anexos,
    mensagemAtendente: !!messageItem.atendente,
  };
}

async function loadMessages(
  idRepresentante: number,
  idTicket: number,
): Promise<Message[]> {
  const messagesResponse = await api.get<MessagesResponse>(
    `helpdesk/v1/chamado/${idTicket}/mensagem`,
    {
      params: {
        status: 'DISPONIVEL',
        tipos: [3],
        idRepresentante,
      },
    },
  );

  const messages = messagesResponse.data.data.map(formatMessage);

  return messages;
}

export default function useMessages(idRepresentante: number, idTicket: number) {
  const { data, isError, isLoading, isFetching, isFetched, refetch } = useQuery<
    Message[]
  >(['messages', idTicket], () => loadMessages(idRepresentante, idTicket), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    retry: false,
  });

  const messages = data || initialData;

  return {
    messages,
    error: isError,
    loading: isLoading,
    isFetching,
    isFetched,
    refetch,
  };
}
