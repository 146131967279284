import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { FaWindowRestore } from 'react-icons/fa';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { addDays, format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import Error from 'components/Error';
import FormHeader from 'components/FormHeader';
import FormWrapper from 'components/FormWrapper';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';
import BrlCurrencyInput from 'components/BrlCurrencyInput';

import swal from 'services/swal';
import hashIds from 'services/hashIds';
import api from 'services/api';

import formatCurrency from 'utils/formatCurrency';

import useSites from 'hooks/useSites';
import useService from 'hooks/useService';
import useCustomer from 'hooks/useCustomer';

import { StoreState } from 'store/createStore';

import toast from 'services/toast';
import history from 'services/history';

import { Container } from './styles';

interface MatchParams {
  idCustomer: string;
  idService: string;
}

type ServiceProps = RouteComponentProps<MatchParams>;

type FormValues = {
  idSite: number;
  price: string;
  installments: number;
  dueDate: string;
  setup: 'PAGAMENTO' | 'DEBITO' | 'PROX_VENCIMENTO';
};

type DueDate = {
  date: string;
  formattedDate: string;
};

type SaleServiceResponse = {
  data: {
    info: string;
    url: string | null;
  };
};

const Service: React.FC<ServiceProps> = ({ match }) => {
  const { t } = useTranslation();

  const idCustomer = Number(hashIds.decode(match.params.idCustomer));
  const idService = Number(hashIds.decode(match.params.idService));

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const {
    service,
    isLoading: loadingServices,
    isError: servicesError,
  } = useService(idRepresentante, idService);

  const {
    customer,
    isLoading: loadingCustomer,
    isError: customerError,
  } = useCustomer(idRepresentante, idCustomer);

  const { sites, loading: loadingSites, error: sitesError } = useSites(
    idRepresentante,
  );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [customerSites, setCustomerSites] = useState<typeof sites>([]);
  const [dueDates, setDueDates] = useState<DueDate[]>([]);
  const [installments, setInstallments] = useState(0);

  const [
    displayDebitOnNextChargeButton,
    setDisplayDebitOnNextChargeButton,
  ] = useState(false);

  const [
    displayDebitOnFinancialStatementButton,
    setDisplayDebitOnFinancialStatementButton,
  ] = useState(false);

  const [displayNextBillingDate, setDisplayNextBillingDate] = useState(false);

  useEffect(() => {
    const someError = servicesError || customerError || sitesError;

    setError(someError);
  }, [customerError, servicesError, sitesError]);

  useEffect(() => {
    const someLoading = loadingServices || loadingCustomer || loadingSites;

    setLoading(someLoading);
  }, [loadingCustomer, loadingServices, loadingSites]);

  useEffect(() => {
    const filteredSites = sites.filter(
      site => site.idCliente === idCustomer && site.ativo,
    );

    setCustomerSites(filteredSites);
  }, [idCustomer, sites]);

  useEffect(() => {
    const currentDate = new Date();

    const dates = [];

    for (let i = 3; i <= 10; i += 1) {
      const dueDate = addDays(currentDate, i);

      const formattedDate = `${format(dueDate, 'dd/MM/yyyy', {
        locale: pt,
      })} daqui a ${i} dias`;

      const date = format(dueDate, 'yyyy-MM-dd');

      dates.push({
        date,
        formattedDate,
      });
    }

    setDueDates(dates);
  }, []);

  useEffect(() => {
    const { periodicidade, idServicoCategoria } = service;

    const categoriesAllowedToPayInInstallments = [1, 2, 4];

    const validCategory = categoriesAllowedToPayInInstallments.includes(
      idServicoCategoria,
    );

    const validFrequency = periodicidade === 'SETUP';
    const canPayInInstallments = validCategory && validFrequency;
    const maxInstallments = canPayInInstallments ? 10 : 0;

    setInstallments(maxInstallments);
  }, [service]);

  useEffect(() => {
    const shouldDisplayDebitOnNextChargeButton =
      service.periodicidade === 'MENSAL' && service.idServicoCategoria === 4;

    const shouldDisplayDebitOnFinancialStatementButton =
      service.idServicoCategoria === 4;

    const shouldDisplayNextBillingDate =
      service.periodicidade === 'MENSAL' && service.idServicoCategoria === 4;

    setDisplayDebitOnNextChargeButton(shouldDisplayDebitOnNextChargeButton);
    setDisplayDebitOnFinancialStatementButton(
      shouldDisplayDebitOnFinancialStatementButton,
    );
    setDisplayNextBillingDate(shouldDisplayNextBillingDate);
  }, [service.idServicoCategoria, service.periodicidade]);

  function generateInstallmentsOptions(price: string) {
    const formattedPrice = price.replace(',', '.');

    const installmentsArray = Array.from(Array(installments).keys()).map(
      v => v + 1,
    );

    const installmentOptions = installmentsArray.map(installmentCount => {
      const installmentValue = (
        parseFloat(formattedPrice) / installmentCount
      ).toFixed(2);

      return (
        <option
          key={`installment-${installmentCount}`}
          value={installmentCount}
        >
          {`${installmentCount}x de ${formatCurrency(
            Number(installmentValue),
          )}`}
        </option>
      );
    });

    return installmentOptions;
  }

  function getNextBillingDate(idSite: number) {
    const { tipoCobranca } = customer.cliente;

    let billingDate = '';

    if (tipoCobranca === 'UNICA') {
      billingDate = customer.cliente.proxVencimento;
    }

    if (tipoCobranca === 'MULTIPLA') {
      const selectedSite = customerSites.find(site => site.idSite === idSite);

      if (selectedSite) {
        billingDate = selectedSite.proxVencimento;
      }
    }

    if (billingDate === '') {
      return '';
    }

    return format(parseISO(billingDate), 'dd/MM/yyyy');
  }

  async function submitForm(values: FormValues) {
    try {
      let formattedPrice = values.price.replace(/,/, '.');
      formattedPrice = Number(formattedPrice).toFixed(2);

      let selectedSite = null;

      if (values.idSite !== 0) {
        selectedSite = sites.find(site => site.idSite === values.idSite);
      }

      const saleServiceResponse = await api.post<SaleServiceResponse>(
        'clientes/v1/representante/venda/servico',
        {
          idRepresentante,
          idServico: idService,
          idCliente: idCustomer,
          valor: formattedPrice,
          vencimento: values.dueDate,
          parcelas: values.installments,
          setup: values.setup,
          ...(selectedSite && { site: selectedSite.site }),
        },
      );

      const { url } = saleServiceResponse.data.data;

      if (url !== null) {
        swal.fire(
          t('pages:serviceSale.successRegisteredSale'),
          t('pages:serviceSale.willBeRedirected'),
          'success',
        );

        window.location.href = url;
      } else {
        toast.fire(t('pages:serviceSale.saleSuccess'));

        history.push('/clientes');
      }
    } catch (err) {
      swal.fire({
        title: t('pages:serviceSale.saleFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        icon={<FaWindowRestore color="#FFFFFF" size={24} />}
        title={t('titles:customers.title')}
        description={t('titles:customers.description')}
      />

      <FormWrapper>
        <FormHeader
          title={`${t('pages:serviceSale.title')} ${customer.email1}`}
          description={t('pages:serviceSale.description')}
          helpContent={t('pages:serviceSale.helpContent.p1')}
          startOpen
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            idSite: customerSites.length > 0 ? customerSites[0].idSite : 0,
            installments: 1,
            price: `${service.valor.toFixed(2)}1`,
            dueDate: dueDates.length > 0 ? dueDates[0].date : '',
            setup: 'PAGAMENTO',
          }}
          validationSchema={Yup.object().shape({
            price: Yup.string().required(t('validations:requiredField')),
            installments: Yup.number().required(t('validations:requiredField')),
            dueDate: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={submitForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('pages:serviceSale.formSubtitle')} />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:service')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="serviceName"
                      value={service.servico}
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>

                {customerSites.length > 0 && (
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('common:site')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        as="select"
                        name="idSite"
                        onChange={e =>
                          props.setFieldValue('idSite', Number(e.target.value))
                        }
                      >
                        {customerSites.map(site => (
                          <option
                            key={`customer-site-${site.idSite}`}
                            value={site.idSite}
                          >
                            {site.site}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                )}

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:price')}
                  </Form.Label>
                  <Col sm={10}>
                    <BrlCurrencyInput
                      onValueChange={value =>
                        props.setFieldValue('price', value)
                      }
                      value={props.values.price}
                    />

                    <Form.Text className="text-muted">
                      {t('pages:serviceSale.priceTip')}
                    </Form.Text>

                    {props.errors.price && (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {props.errors.price}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Form.Group>

                {installments > 0 && (
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('common:installments')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        as="select"
                        name="installments"
                        onChange={props.handleChange}
                      >
                        {generateInstallmentsOptions(props.values.price)}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                )}

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:dueDate')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      name="dueDate"
                      onChange={props.handleChange}
                    >
                      {dueDates.map(dueDate => (
                        <option
                          key={`due-date-${dueDate.date}`}
                          value={dueDate.date}
                        >
                          {dueDate.formattedDate}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>

                {displayNextBillingDate && (
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('common:nextDueDate')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        id="nextDueDate"
                        value={getNextBillingDate(props.values.idSite)}
                        plaintext
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                )}
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                  onClick={() => {
                    props.setFieldValue('setup', 'PAGAMENTO');
                    props.submitForm();
                  }}
                >
                  {props.isSubmitting
                    ? t('common:sending')
                    : t('pages:serviceSale.buttons.chargeNow')}
                </PanelButton>

                {displayDebitOnFinancialStatementButton && (
                  <PanelButton
                    className="mr-1"
                    disabled={props.isSubmitting || !props.isValid}
                    onClick={() => {
                      props.setFieldValue('setup', 'DEBITO');
                      props.submitForm();
                    }}
                  >
                    {service.periodicidade === 'MENSAL'
                      ? t(
                          'pages:serviceSale.buttons.addDebitToFinancialStatementNow',
                        )
                      : t(
                          'pages:serviceSale.buttons.addDebitToChargeOnNextInvoice',
                        )}
                  </PanelButton>
                )}

                {displayDebitOnNextChargeButton && (
                  <PanelButton
                    className="mr-1"
                    disabled={props.isSubmitting || !props.isValid}
                    onClick={() => {
                      props.setFieldValue('setup', 'PROX_VENCIMENTO');
                      props.submitForm();
                    }}
                  >
                    {t('pages:serviceSale.buttons.addDebitOnNextCharge')}
                  </PanelButton>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Service);
