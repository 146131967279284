import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import PanelButton from 'components/PanelButton';

interface CommissionModalProps {
  open: boolean;
  details: string;
  hide: () => void;
}

const CommissionModal: React.FC<CommissionModalProps> = ({
  open,
  details,
  hide,
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={open} onHide={hide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('pages:commission.commissionDetails')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {details ? (
          <pre>{details}</pre>
        ) : (
          <p className="text-muted">{t('pages:commission.emptyDetails')}</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <PanelButton variant="primary" onClick={hide}>
          {t('common:close')}
        </PanelButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CommissionModal;
