import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { StoreState } from 'store/createStore';

import { authorizationRequest } from 'store/modules/auth/actions';

import { Error, Loading } from './styles';

/**
 * Componente responsável por rotear os acessos feitos por usuários
 * não autenticados. Essa rota é acessada em 3 ocasiões diferentes:
 *
 * 1) Primeiro acesso: Quando o usuário acessa o endereço do Painel de Controle
 * e ainda não está autenticado. Nesse caso ele será redirecionado para a
 * autenticação do Hostnet ID.
 *
 * 2) Autorização: Quando o usuário já autenticou no Hostnet ID e foi enviado
 * de volta para o Painel de Controle. Nesse caso ele irá retornar para esta
 * rota para passar pelo processo de autorização. Para entrar nesse processo
 * é preciso que um token seja enviado na URL.
 *
 * 3) Logout: Quando o usuário executa o logout ele cairá nessa rota por
 * não estar mais autenticado, mas nesse caso verificamos se existe o valor
 * "logout" no localStorage para verificar se ele acabou de executar um logout.
 * Caso tenha o valor, será enviado para deslogar também no Hostnet ID.
 *
 * @author Vitor Sekiguchi<vitor@hostnet.com.br>
 */
const Auth: React.FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const { loading, error } = useSelector((state: StoreState) => state.auth);

  const hasHostnetIdToken = useCallback(() => {
    const urlSearchParams = new URLSearchParams(search);
    return urlSearchParams.get('hostnetid_token') !== null;
  }, [search]);

  const getHostnetIdToken = useCallback(() => {
    const urlSearchParams = new URLSearchParams(search);
    return urlSearchParams.get('hostnetid_token') ?? '';
  }, [search]);

  const getScope = useCallback(() => {
    const urlSearchParams = new URLSearchParams(search);
    return urlSearchParams.get('scope') ?? '';
  }, [search]);

  const redirectToHostnetId = useCallback(() => {
    const currentLocation = window.location.href;

    window.location.href = `https://id.hostnet.com.br/login/authorize?clientId=painel4_representante&state=${encodeURIComponent(
      currentLocation,
    )}`;
  }, []);

  const redirectToHostnetIdLogout = useCallback(() => {
    const currentLocation = window.location.href;
    const returnUrl = encodeURIComponent(
      `/login/authorize?clientId=painel4&state=${currentLocation}`,
    );

    window.location.href = `https://id.hostnet.com.br/login/?returnUrl=${returnUrl}`;
  }, []);

  useEffect(() => {
    const logout = localStorage.getItem('painelhostnetwebts:logout') === 'true';
    localStorage.removeItem('painelhostnetwebts:logout');

    if (hasHostnetIdToken()) {
      const hostnetIdToken = getHostnetIdToken();
      const scope = getScope();
      dispatch(authorizationRequest({ token: hostnetIdToken, scope }));
    } else if (logout) {
      redirectToHostnetIdLogout();
    } else {
      redirectToHostnetId();
    }
  }, [
    dispatch,
    getHostnetIdToken,
    getScope,
    hasHostnetIdToken,
    redirectToHostnetId,
    redirectToHostnetIdLogout,
    search,
  ]);

  if (loading) {
    return <Loading>carregando...</Loading>;
  }

  if (error) {
    return <Error>Falha na autenticação</Error>;
  }

  return <Loading>carregando...</Loading>;
};

export default Auth;
