import { useQuery } from 'react-query';

import api from 'services/api';

interface TicketItemResponse {
  idTicket: number;
  idCliente: number;
  idSituacao: number;
  idSite: number;
  idDepartamento: number;
  departamento: string;
  atendente: string;
  assunto: string;
  nivelTecnico: string;
  categoria: string;
  situacao: string;
  responsavel: string;
  abertura: string;
  fechamento: string;
  dadosSigilosos: string;
}

interface TicketsResponse {
  data: TicketItemResponse[];
}

interface Ticket {
  idTicket: number;
  idSituacao: number;
  idSite: number;
  idDepartamento: number;
  departamento: string;
  atendente: string;
  assunto: string;
  nivelTecnico: string;
  categoria: string;
  situacao: string;
  responsavel: string;
  abertura: string;
  fechamento: string;
  dadosSigilosos: string;
}

const initialData: Ticket[] = [];

function formatTicket(ticketItem: TicketItemResponse): Ticket {
  const {
    idTicket,
    idSituacao,
    idSite,
    idDepartamento,
    nivelTecnico,
    situacao,
    departamento,
    atendente,
    categoria,
    responsavel,
    assunto,
    abertura,
    fechamento,
    dadosSigilosos,
  } = ticketItem;

  return {
    idTicket,
    idSituacao,
    idSite,
    idDepartamento,
    nivelTecnico,
    situacao,
    departamento,
    atendente,
    categoria,
    responsavel,
    assunto,
    abertura,
    fechamento,
    dadosSigilosos,
  };
}
async function loadTickets(idRepresentante: number): Promise<Ticket[]> {
  const ticketsStatus = [1, 2, 3, 4, 5, 6];

  const ticketsResponse = await api.get<TicketsResponse>(
    `helpdesk/v1/chamado`,
    {
      params: {
        idRepresentante,
        situacoes: ticketsStatus,
      },
    },
  );

  const tickets = ticketsResponse.data.data.map(formatTicket);

  return tickets;
}

export default function useTickets(idRepresentante: number) {
  const { data, isError, isLoading, isFetching, isFetched, refetch } = useQuery<
    Ticket[]
  >('tickets', () => loadTickets(idRepresentante), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    retry: false,
  });

  const tickets = data || initialData;

  return {
    tickets,
    error: isError,
    loading: isLoading,
    isFetching,
    isFetched,
    refetch,
  };
}
