import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';

import PanelButton from 'components/PanelButton';

import { Container } from './styles';

const Unavailable: React.FC = () => {
  const { t } = useTranslation();

  function redirectPanel() {
    window.location.href = 'http://painel.hostnet.com.br';
  }

  function redirectHostnetId() {
    const currentLocation = window.location.origin;
    const returnUrl = encodeURIComponent(
      `/login/authorize?clientId=painel4&state=${currentLocation}`,
    );

    window.location.href = `https://id.hostnet.com.br/login/?returnUrl=${returnUrl}`;
  }

  return (
    <Container>
      <Card className="mt-5 card w-75 mx-auto">
        <Card.Body>
          <Card.Title>{t('pages:unavailable.panelUnavailable')}</Card.Title>
          <Card.Text>{t('pages:unavailable.description')}</Card.Text>
          <PanelButton variant="primary" onClick={() => redirectPanel()}>
            {t('pages:unavailable.goToPanel')}
          </PanelButton>
          <PanelButton variant="link ml-2" onClick={() => redirectHostnetId()}>
            {t('pages:unavailable.accessWithOtherAccount')}
          </PanelButton>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Unavailable;
