import React from 'react';

import {
  Container,
  Description,
  IconBox,
  Title,
  TitleContainer,
} from './styles';

interface PageTitleProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const PageTitle: React.FC<PageTitleProps> = ({
  icon,
  title,
  description = '',
}) => {
  return (
    <Container>
      <IconBox>{icon}</IconBox>
      <TitleContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TitleContainer>
    </Container>
  );
};

export default PageTitle;
