import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { FaQuestionCircle } from 'react-icons/fa';
import i18n from 'i18n';

import {
  Container,
  Title,
  Description,
  TableHeaderButton,
  Help,
  HelpContent,
  HelpLinks,
  LinkList,
} from './styles';

interface HelpLink {
  text: string;
  url: string;
}

interface FormHeaderProps {
  title: string;
  description: string;
  startOpen?: boolean;
  helpContent?: React.ReactNode;
  helpLinks?: HelpLink[];
}

const FormHeader: React.FC<FormHeaderProps> = ({
  title,
  description,
  helpContent,
  startOpen = false,
  helpLinks = [
    {
      text: i18n.t('common:learnMoreInHelpCenter'),
      url: 'https://www.hostnet.com.br/info/',
    },
  ],
}) => {
  const { t } = useTranslation();

  const [help, setHelp] = useState(startOpen);

  return (
    <Container>
      <Card.Body>
        <Row>
          <Col xs={9}>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Col>
          <Col xs={3}>
            <Row className="justify-content-end mr-1">
              {helpContent && (
                <TableHeaderButton
                  onClick={() => setHelp(!help)}
                  className={help ? 'active' : ''}
                >
                  <FaQuestionCircle
                    color="#2c4593"
                    size={23}
                    className="mb-1"
                  />
                  <div>{t('common:help')}</div>
                </TableHeaderButton>
              )}
            </Row>
          </Col>
        </Row>
      </Card.Body>

      <Help className={help && 'active'}>
        <Row>
          <Col xs={12} md={7}>
            <HelpContent>
              <Trans>{helpContent}</Trans>
            </HelpContent>
          </Col>
          <Col xs={12} md={5} className="border-left">
            <HelpLinks>
              <LinkList>
                {helpLinks.map(link => (
                  <li key={link.url} className="mb-2">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.url}
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </LinkList>
            </HelpLinks>
          </Col>
        </Row>
      </Help>
    </Container>
  );
};

export default FormHeader;
