import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaServer } from 'react-icons/fa';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import PanelButton from 'components/PanelButton';
import FormHeader from 'components/FormHeader';
import FormWrapper from 'components/FormWrapper';
import FormSubtitle from 'components/FormSubtitle';
import Loading from 'components/Loading';

import { StoreState } from 'store/createStore';

import hashIds from 'services/hashIds';
import swal from 'services/swal';
import toast from 'services/toast';
import history from 'services/history';
import api from 'services/api';

import useCustomizedServices from 'hooks/useCustomizedServices';

import useCustomizedService from './hooks/useCustomizedService';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type EditProps = RouteComponentProps<MatchParams>;

interface EditServiceFormValues {
  name: string;
}

const Edit: React.FC<EditProps> = ({ match }) => {
  const { t } = useTranslation();

  const { id } = match.params;

  const idService = Number(hashIds.decode(id));

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { refetch: refetchServices } = useCustomizedServices(idRepresentante);

  const { customizedService, refetch, isLoading } = useCustomizedService(
    idRepresentante,
    idService,
  );

  async function editCustomizedService(values: EditServiceFormValues) {
    try {
      await api.put(
        `clientes/v1/representante/${idRepresentante}/servico/${customizedService.idServico}`,
        {
          servico: values.name,
        },
      );

      await refetchServices();

      await refetch();

      toast.fire(t('pages:customizedServicesEdit.success'));

      history.push(`/servicos/customizados`);
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <PageTitle
        icon={<FaServer color="#FFFFFF" size={24} />}
        title={t('titles:services.title')}
        description={t('titles:services.description')}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:customizedServicesEdit.title')}
          description={t('pages:customizedServicesEdit.description')}
          helpContent={
            <div>
              <p>{t('pages:customizedServicesEdit.helpContent.p1')}</p>
            </div>
          }
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            name: customizedService.servico,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={editCustomizedService}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:customizedServicesEdit.legend')}
                />

                <Form.Group as={Row} controlId="newService.name">
                  <Form.Label column sm={2} className="required">
                    {t('common:name')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="name"
                      value={props.values.name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.name && !!props.errors.name}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/servicos/customizados"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Edit);
