import styled, { css } from 'styled-components';
import SimpleBar from 'simplebar-react';
import { SiRedux } from 'react-icons/si';
import {
  FaHome,
  FaIdCard,
  FaCloud,
  FaEnvelope,
  FaDatabase,
  FaMoneyBillAlt,
  FaCubes,
  FaCogs,
  FaLink,
  FaServer,
  FaQuestion,
  FaGlobeAmericas,
  FaWindowRestore,
} from 'react-icons/fa';

export const Container = styled.nav`
  position: fixed;

  width: 250px;
  min-height: calc(100vh - 73px);

  overflow: hidden;
  z-index: 1028;

  background-color: var(--color-primary);
  transition: all 0.3s ease-in-out;

  &.closed {
    width: 0px;
    opacity: 0;
  }
`;

export const SideMenuScrollBar = styled(SimpleBar)`
  max-height: calc(100vh - 73px);
  padding-bottom: 30px;
`;

const iconCSS = css`
  margin-right: 10px;
`;

export const HomeIcon = styled(FaHome)`
  ${iconCSS}
`;

export const IdCardIcon = styled(FaIdCard)`
  ${iconCSS}
`;

export const LinkIcon = styled(FaLink)`
  ${iconCSS}
`;

export const CloudIcon = styled(FaCloud)`
  ${iconCSS}
`;

export const EnvelopeIcon = styled(FaEnvelope)`
  ${iconCSS}
`;

export const DatabaseIcon = styled(FaDatabase)`
  ${iconCSS}
`;

export const MoneyIcon = styled(FaMoneyBillAlt)`
  ${iconCSS}
`;

export const CubesIcon = styled(FaCubes)`
  ${iconCSS}
`;

export const ReduxIcon = styled(SiRedux)`
  ${iconCSS}
`;

export const ServicesIcon = styled(FaServer)`
  ${iconCSS}
`;

export const QuestionIcon = styled(FaQuestion)`
  ${iconCSS}
`;

export const GlobeIcon = styled(FaGlobeAmericas)`
  ${iconCSS}
`;

export const WindowIcon = styled(FaWindowRestore)`
  ${iconCSS}
`;

export const CogsIcon = styled(FaCogs)`
  ${iconCSS}
`;
