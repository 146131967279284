import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Loading from 'components/Loading';
import Error from 'components/Error';
import { StoreState } from 'store/createStore';

import api from 'services/api';
import history from 'services/history';

const NewCustomerActions: React.FC = () => {
  const [error, setError] = useState<boolean>(false);

  const { idCliente, idRepresentante, email1: email } = useSelector(
    (state: StoreState) => state.client.info,
  );

  useEffect(() => {
    async function getKey() {
      try {
        setError(false);
        const fooResponse = await api.post(`/assine/v1/chave`, {
          idCliente,
          idRepresentante,
        });
        const key = fooResponse.data.data.chave;
        if (key) {
          window.open(
            `https://assine.hostnet.com.br/?representante=${email}&rep=${idRepresentante}&CHAVE=${key}`,
            '_blank',
          );
        }
      } catch (err) {
        setError(true);
      } finally {
        history.goBack();
      }
    }
    getKey();
  }, [idCliente, idRepresentante, email]);

  if (error) {
    return <Error />;
  }

  return <Loading />;
};

export default NewCustomerActions;
