import { useQuery } from 'react-query';

import api from 'services/api';

type EmailItemResponse = {
  id: string;
  mailAddr: string;
  dominio: string;
  quota: string;
  quotaGB: number;
  bloqueado: boolean;
  mailBox: string;
  servidor: string;
  descricao: string;
  quotaWarning: boolean;
};

type EmailsResponse = {
  data: EmailItemResponse[];
};

type Email = {
  id: number;
  quota: number;
  mailAddr: string;
  dominio: string;
  quotaGB: number;
  bloqueado: boolean;
  mailBox: string;
  servidor: string;
  descricao: string;
  quotaWarning: boolean;
};

const initialData: Email[] = [];

function formatEmail(email: EmailItemResponse): Email {
  const { id, quota, ...emailRest } = email;

  return {
    id: parseInt(id, 10),
    quota: parseInt(quota, 10),
    ...emailRest,
  };
}

async function loadEmails(idSite?: number): Promise<Email[]> {
  if (!idSite) {
    return [];
  }

  const emailsResponse = await api.get<EmailsResponse>(
    `painel/v1/site/${idSite}/email`,
  );

  const emails = emailsResponse.data.data.map(formatEmail);

  return emails;
}

export default function useCustomerEmails(idSite?: number) {
  const { data, ...rest } = useQuery<Email[]>(
    ['emails', idSite],
    () => loadEmails(idSite),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const emails = data || initialData;

  return {
    emails,
    ...rest,
  };
}
