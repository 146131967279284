import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  Container,
  HomeIcon,
  IdCardIcon,
  SideMenuScrollBar,
  MoneyIcon,
  ServicesIcon,
  QuestionIcon,
  GlobeIcon,
  WindowIcon,
  CogsIcon,
} from './styles';

import SideMenuSession from './components/SideMenuSession';
import SideMenuLink from './components/SideMenuLink';
import SideMenuAccordion from './components/SideMenuAccordion';
import SideMenuAccordionLink from './components/SideMenuAccordionLink';

interface SideMenuProps {
  collapsed: boolean;
  siteOptions: { codigo: string }[];
}

const SideMenu: React.FC<SideMenuProps> = ({ collapsed }) => {
  const { t } = useTranslation();

  const franchiseSession = [
    <SideMenuLink
      text={t('components:sideMenu.accredited')}
      to="/home"
      notifications={0}
      icon={<HomeIcon size={15} />}
    />,
    <SideMenuLink
      text={t('components:sideMenu.registration')}
      to="/cadastro"
      notifications={0}
      icon={<IdCardIcon size={15} />}
    />,
    <SideMenuAccordion
      eventKey="3"
      text={t('components:sideMenu.financial')}
      icon={<MoneyIcon size={15} />}
      elements={[
        <SideMenuAccordionLink
          text={t('components:sideMenu.commissions')}
          to="/financeiro/comissoes"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.withdrawals')}
          to="/financeiro/saques"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.payRoyalties')}
          to="/financeiro/royalties"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.openCharges')}
          to="/financeiro/cobrancas"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.transfers')}
          to="/financeiro/transferencias"
        />,
      ]}
    />,
    <SideMenuAccordion
      eventKey="4"
      text={t('components:sideMenu.customers')}
      icon={<WindowIcon size={15} />}
      elements={[
        <SideMenuAccordionLink
          text={t('components:sideMenu.customers')}
          to="/clientes"
        />,
        <SideMenuAccordionLink text={t('common:site_plural')} to="/sites" />,
      ]}
    />,
    <SideMenuLink
      text={t('components:sideMenu.domains')}
      to="/dominios"
      notifications={0}
      icon={<GlobeIcon size={15} />}
    />,
    <SideMenuAccordion
      eventKey="9"
      text={t('components:sideMenu.services')}
      icon={<ServicesIcon size={15} />}
      elements={[
        <SideMenuAccordionLink
          text={t('components:sideMenu.customizedServices')}
          to="/servicos/customizados"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.activeServices')}
          to="/servicos/ativos"
        />,
      ]}
    />,
    <SideMenuLink
      text={t('components:sideMenu.helpdesk')}
      to="/helpdesk"
      notifications={0}
      icon={<QuestionIcon size={15} />}
    />,
    <SideMenuAccordion
      eventKey="10"
      text={t('components:sideMenu.marketingService')}
      icon={<CogsIcon size={15} />}
      elements={[
        <SideMenuAccordionLink
          text={t('common:product_plural')}
          to="/automacao-marketing/produtos"
        />,
        <SideMenuAccordionLink
          text={t('common:service_plural')}
          to="/automacao-marketing/servicos"
        />,
        <SideMenuAccordionLink
          text="Serviços ativos"
          to="/automacao-marketing/servicos-ativos"
        />,
      ]}
    />,
  ];

  return (
    <Container className={collapsed ? 'closed' : ''}>
      <SideMenuScrollBar>
        <Accordion defaultActiveKey="0">
          <SideMenuSession
            label={t('components:sideMenu.franchiseControl')}
            elements={franchiseSession}
          />
        </Accordion>
      </SideMenuScrollBar>
    </Container>
  );
};

export default SideMenu;
