import React from 'react';
import { Link } from 'react-router-dom';
import { FiMoreHorizontal, FiToggleLeft, FiToggleRight } from 'react-icons/fi';

import logo from 'assets/logo.png';

import {
  Container,
  MobileOptions,
  MobileMenuButton,
  MobileOptionsButton,
  LogoImage,
} from './styles';

interface HeaderLogoProps {
  sideMenuCollapsed: boolean;
  handleSideMenu: () => void;
  handleMobileMenu: () => void;
}

const HeaderLogo: React.FC<HeaderLogoProps> = ({
  sideMenuCollapsed,
  handleSideMenu,
  handleMobileMenu,
}) => {
  return (
    <Container>
      <MobileOptions>
        <MobileOptionsButton type="button" onClick={handleMobileMenu}>
          <FiMoreHorizontal size={22} color="#fff" />
        </MobileOptionsButton>
      </MobileOptions>

      <div>
        <Link to="/home">
          <LogoImage src={logo} alt="Logo Hostnet" width="180" height="40" />
          <span className="alpha d-block text-right">ALPHA</span>
        </Link>
      </div>

      <div className="d-flex align-items-center">
        <MobileMenuButton type="button" onClick={handleSideMenu}>
          {sideMenuCollapsed ? (
            <FiToggleLeft color="#fff" size={16} />
          ) : (
            <FiToggleRight color="#fff" size={16} />
          )}
        </MobileMenuButton>
      </div>
    </Container>
  );
};

export default HeaderLogo;
