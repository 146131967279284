import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  .info-boxes {
    .card-body {
      min-height: 150px;
    }
  }
`;

export const Info = styled.div`
  font-size: 24px;
  font-weight: 700;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--color-blue);
`;

export const StyledLink = styled(Link)`
  font-size: 14px;
  color: var(--color-midnight-blue);
  transition: all 0.25s ease-in-out;

  &:hover,
  &:focus {
    color: var(--color-blue);
    text-decoration: none;
  }
`;

export const StyledA = styled.a`
  font-size: 14px;
  color: var(--color-midnight-blue);
  transition: all 0.25s ease-in-out;

  &:hover,
  &:focus {
    color: var(--color-blue);
    text-decoration: none;
  }
`;

export const SupportServicesNav = styled(Nav)`
  text-align: center;
  font-size: 13px;

  margin-left: 20px;
  margin-right: 5px;
  margin-bottom: 20px;

  .nav-item {
    flex: 1;
    margin-right: 15px;
    position: relative;

    text-align: center;
  }
  .nav-item .nav-link {
    color: #aaa;
    background-color: #eee;

    padding: 15px 0;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }

  .nav-item .nav-link.active {
    color: white;
    background-color: #2c4593;
  }
`;
