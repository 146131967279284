import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';

import PageTitle from 'components/PageTitle';

import { Container } from './styles';

const Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />
    </Container>
  );
};

export default Home;
