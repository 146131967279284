import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import swal from 'services/swal';
import toast from 'services/toast';

import filter from 'utils/filter';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import TableButton from 'components/TableButton';
import Loading from 'components/Loading';
import Error from 'components/Error';
import TableNoData from 'components/TableNoData';

import formatCurrency from 'utils/formatCurrency';

import WithdrawActions from './components/WithdrawActions';

import { Container } from './styles';

interface Withdraw {
  idRepresentanteSaque: number;
  valor: string;
  status: string;
  dataSolicitacao: string;
}

interface WithdrawsResponse {
  data: Withdraw[];
}

const List: React.FC = () => {
  const { t } = useTranslation();

  const client = useSelector((state: StoreState) => state.client.info);

  const [filterText, setFilterText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [withdraws, setWithdraws] = useState<Withdraw[]>([]);

  const [filteredWithdraws, setFilteredWithdraws] = useState<Withdraw[]>([]);

  const [toggleCleared, setToggleCleared] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: Withdraw[];
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  useEffect(() => {
    setFilteredWithdraws(
      filter(withdraws, ['valor', 'status', 'dataSolicitacao'], filterText),
    );
  }, [withdraws, filterText]);

  const loadWithdraws = useCallback(async () => {
    try {
      setError(false);
      setLoading(true);

      const withdrawsResponse = await api.get<WithdrawsResponse>(
        `clientes/v1/representante/${client.idRepresentante}/saque`,
      );

      setWithdraws(withdrawsResponse.data.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [client]);

  useEffect(() => {
    loadWithdraws();
  }, [loadWithdraws]);

  const deleteWithdraws = useCallback(async () => {
    try {
      const { isConfirmed } = await swal.fire({
        title: t('common:warning'),
        text: t('pages:withdraw.warning', {
          count: selectedRowsInfo.selectedCount,
        }),
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setDeleting(true);

      await Promise.all(
        selectedRowsInfo.selectedRows.map(async item => {
          await api.put(
            `clientes/v1/representante/${client.idRepresentante}/saque/${item.idRepresentanteSaque}`,
            { status: 'CANCELADO' },
          );
        }),
      );

      setToggleCleared(!toggleCleared);
      loadWithdraws();

      toast.fire(
        selectedRowsInfo.selectedCount === 1
          ? t('pages:withdraw.withdrawRemoveSuccess')
          : t('pages:withdraw.withdrawRemoveSuccess_plural'),
      );
    } catch (err) {
      swal.fire({
        title: t('common:label.operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setDeleting(false);
    }
  }, [t, selectedRowsInfo, toggleCleared, client, loadWithdraws]);

  const columns = [
    {
      name: t('common:value'),
      selector: 'valor',
      sortable: true,
      width: '15%',
      cell: (row: Withdraw) => formatCurrency(parseFloat(row.valor)),
    },
    {
      name: t('pages:withdraw.status'),
      selector: 'status',
      sortable: true,
      width: '30%',
    },
    {
      id: 'dataSolicitacao',
      name: t('pages:withdraw.requestDate'),
      selector: 'dataSolicitacao',
      sortable: true,
      cell: (row: Withdraw) =>
        format(parseISO(row.dataSolicitacao), 'dd/MM/yyyy', {
          locale: pt,
        }),
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:withdraw.title')}
          description={t('pages:withdraw.description')}
          helpContent={
            <div>
              <p>{t('pages:withdraw.helpContent.p1')}</p>
              <p>
                <Trans i18nKey="pages:withdraw.helpContent.p2">
                  p2
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://painel4.hostnet.com.br/helpdesk"
                  >
                    access
                  </a>
                </Trans>
              </p>
              <p>{t('pages:withdraw.helpContent.p3')}</p>
            </div>
          }
        />
        <TableSubHeader>
          <div>
            <TableButton forwardedAs={Link} to="/financeiro/saques/novo">
              {t('pages:withdraw.request')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>
        <div className="datatables-table">
          <DataTable
            defaultSortField="dataSolicitacao"
            defaultSortAsc={false}
            noHeader={selectedRowsInfo.selectedCount === 0}
            dense
            selectableRows
            clearSelectedRows={toggleCleared}
            selectableRowDisabled={row => deleting || row.status !== 'PENDENTE'}
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            pagination
            columns={columns}
            data={filteredWithdraws}
            progressPending={loading}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            contextComponent={
              <WithdrawActions
                loading={loading || deleting}
                deleteWithdraws={() => deleteWithdraws()}
              />
            }
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default List;
