import home from './home.json';
import unavailable from './unavailable.json';
import registration from './registration.json';
import financial from './financial.json';
import customers from './customers.json';
import services from './services.json';
import helpdesk from './helpdesk.json';
import customizedServices from './customizedServices.json';
import customizedServicesNew from './customizedServicesNew.json';
import customizedServicesEdit from './customizedServicesEdit.json';
import activeServices from './activeServices.json';
import ticket from './ticket.json';
import withdraw from './withdraw.json';
import withdrawNew from './withdrawNew.json';
import sites from './sites.json';
import commission from './commission.json';
import openCharges from './openCharges.json';
import royalties from './royalties.json';
import creditTransfer from './creditTransfer.json';
import creditTransferNew from './creditTransferNew.json';
import domains from './domains.json';
import saleNew from './saleNew.json';
import serviceSale from './serviceSale.json';
import productSale from './productSale.json';
import marketingServiceSale from './marketingServiceSale.json';
import marketingProductSale from './marketingProductSale.json';

export default {
  home,
  unavailable,
  registration,
  financial,
  customers,
  services,
  helpdesk,
  customizedServices,
  customizedServicesNew,
  customizedServicesEdit,
  activeServices,
  ticket,
  withdraw,
  withdrawNew,
  sites,
  commission,
  openCharges,
  royalties,
  creditTransfer,
  creditTransferNew,
  domains,
  saleNew,
  serviceSale,
  productSale,
  marketingServiceSale,
  marketingProductSale,
};
