import styled from 'styled-components';

export const ButtonToggle = styled.button`
  width: 100%;
  display: flex;
  min-height: 44px;

  align-items: center;
  text-align: left;

  margin: 5px 0 0;
  padding: 7px 15px 7px 20px;

  color: var(--color-cadet-blue);

  border: none;
  background-color: transparent;
  outline: none !important;

  border-left: 3px solid transparent;

  &.open {
    border-left-color: #42a5f5;
    background: var(--color-midnight-blue);
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;
