import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaQuestion } from 'react-icons/fa';
import { Card, Col, Dropdown, Nav, Row, Spinner } from 'react-bootstrap';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import PanelCard from 'components/PanelCard';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import PanelDropdownButton from 'components/PanelDropdownButton';
import Loading from 'components/Loading';
import Error from 'components/Error';
import TableNoData from 'components/TableNoData';

import useTickets from 'hooks/useTickets';
import useSupportServices from 'hooks/useSupportServices';

import hashIds from 'services/hashIds';

import filter from 'utils/filter';

import { StoreState } from 'store/createStore';

import {
  Container,
  Info,
  StyledA,
  StyledLink,
  SupportServicesNav,
} from './styles';

type StatusFilter = 'ALL' | 'OPEN' | 'CLOSED';

const Home: React.FC = () => {
  const { t } = useTranslation();

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { supportServices } = useSupportServices();
  const { tickets, loading: loadingTickets, error } = useTickets(
    idRepresentante,
  );

  type Ticket = typeof tickets[number];

  const [openTicketsCount, setOpenTicketsCount] = useState(0);

  const [ticketsFilterText, setTicketsFilterText] = useState('');
  const [statusFilter, setStatusFilter] = useState<StatusFilter>('ALL');
  const [filteredTickets, setFilteredTickets] = useState<typeof tickets>([]);

  const [selectedService, setSelectedService] = useState<
    typeof supportServices[number]
  >({
    idAtendimentoTipo: 1,
    idDepartamento: 1,
    identificador: 'sac',
    nome: t('pages:helpdesk.tab.sac'),
    url: '',
    padrao: true,
  });

  useEffect(() => {
    let filtered = filter(tickets, ['assunto', 'idTicket'], ticketsFilterText);

    filtered = filtered.filter(ticket => {
      switch (statusFilter) {
        case 'OPEN':
          return ticket.idSituacao !== 4;
        case 'CLOSED':
          return ticket.idSituacao === 4;
        default:
          return ticket;
      }
    });

    filtered = filtered.filter(ticket => {
      const otherDepartments = [1, 8, 12, 24, 26];

      const idDepartamento =
        otherDepartments.indexOf(ticket.idDepartamento) !== -1
          ? 1
          : ticket.idDepartamento;
      return idDepartamento === selectedService.idDepartamento;
    });

    setFilteredTickets(filtered);
  }, [
    selectedService.idDepartamento,
    statusFilter,
    tickets,
    ticketsFilterText,
  ]);

  useEffect(() => {
    const totalOpenTickets = tickets.filter(ticket => ticket.idSituacao !== 4)
      .length;

    setOpenTicketsCount(totalOpenTickets);
  }, [tickets]);

  function handleChangeService(id: number) {
    const supportService = supportServices.find(
      service => service.idAtendimentoTipo === id,
    );
    if (supportService) {
      setSelectedService(supportService);
    }
  }

  const statusFilterTextName = useMemo(() => {
    switch (statusFilter) {
      case 'OPEN':
        return t('pages:helpdesk.filter.open');
      case 'CLOSED':
        return t('pages:helpdesk.filter.closed');
      default:
        return t('pages:helpdesk.filter.all');
    }
  }, [t, statusFilter]);

  const columns = [
    {
      name: t('pages:helpdesk.id'),
      selector: 'idTicket',
      sortable: true,
      minWidth: '120px',
      cell: ({ idTicket }: Ticket) => <>#{idTicket}</>,
    },
    {
      name: t('pages:helpdesk.subject'),
      selector: 'assunto',
      sortable: true,
      grow: 3,
      minWidth: '240px',
      cell: ({ idTicket, assunto }: Ticket) => (
        <StyledLink to={`helpdesk/ticket/${hashIds.encode(idTicket)}`}>
          {assunto}
        </StyledLink>
      ),
    },
    {
      name: t('pages:helpdesk.opening'),
      selector: 'abertura',
      sortable: true,
      minWidth: '120px',
      cell: ({ abertura }: Ticket) =>
        format(parseISO(abertura), 'dd/MM/yyyy', {
          locale: pt,
        }),
    },
    {
      name: t('pages:helpdesk.closing'),
      selector: 'fechamento',
      minWidth: '120px',
      sortable: true,
      cell: ({ fechamento }: Ticket) => {
        if (fechamento) {
          return format(parseISO(fechamento), 'dd/MM/yyyy', {
            locale: pt,
          });
        }

        return null;
      },
    },
    {
      name: t('pages:helpdesk.status'),
      selector: 'situacao',
      minWidth: '120px',
      sortable: false,
      cell: ({ idSituacao, situacao }: Ticket) =>
        idSituacao === 4 ? (
          <>
            {situacao} <FaCheck className="ml-1 text-success" />
          </>
        ) : (
          situacao
        ),
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        icon={<FaQuestion color="#FFFFFF" size={24} />}
        title={t('titles:helpdesk.title')}
        description={t('titles:helpdesk.description')}
      />

      <Row className="my-4 info-boxes">
        <Col xl={4} md={12} className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('pages:helpdesk.openTickets')}</h6>
                <Info>
                  {loadingTickets ? (
                    <Spinner animation="border" />
                  ) : (
                    openTicketsCount
                  )}
                </Info>
              </div>
              <div>{t('pages:helpdesk.totalOfAllCustomers')}</div>
            </Card.Body>
          </PanelCard>
        </Col>

        <Col xl={4} md={12} className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('pages:helpdesk.sac')}</h6>
                <Info>{t('pages:helpdesk.sacHelp')}</Info>
              </div>
              <StyledA
                target="_blank"
                href="https://www.hostnet.com.br/atendimento-ao-cliente"
                rel="noopener noreferrer"
              >
                {t('pages:helpdesk.sacOthers')}
              </StyledA>
            </Card.Body>
          </PanelCard>
        </Col>

        <Col xl={4} md={12} className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('common:help')}</h6>
                <Info>{t('pages:helpdesk.hostnetInfo')}</Info>
              </div>
              <StyledA
                target="_blank"
                href="https://www.hostnet.com.br/info/"
                rel="noopener noreferrer"
              >
                {t('pages:helpdesk.helpLink')}
              </StyledA>
            </Card.Body>
          </PanelCard>
        </Col>
      </Row>

      <TableWrapper>
        <TableHeader
          title={t('pages:helpdesk.ticketsTitle')}
          description={t('pages:helpdesk.ticketsSubtitle')}
          helpContent={
            <div>
              <p>{t('pages:helpdesk.helpContent.p1')}</p>
              <p>{t('pages:helpdesk.helpContent.p2')}</p>
              <ul>
                <li>{t('pages:helpdesk.helpContent.li1')}</li>
                <li>{t('pages:helpdesk.helpContent.li2')}</li>
                <li>{t('pages:helpdesk.helpContent.li3')}</li>
                <li>{t('pages:helpdesk.helpContent.li4')}</li>
              </ul>
            </div>
          }
        />

        <SupportServicesNav
          variant="pills"
          activeKey={selectedService.idAtendimentoTipo}
          onSelect={(id: string) => handleChangeService(Number(id))}
          className="py-2 flex-column flex-sm-row"
        >
          {supportServices.map(service => (
            <Nav.Item key={service.idAtendimentoTipo}>
              <Nav.Link eventKey={service.idAtendimentoTipo}>
                {service.nome}
              </Nav.Link>
            </Nav.Item>
          ))}
        </SupportServicesNav>

        <TableSubHeader>
          <div />

          <div className="d-flex">
            <PanelDropdownButton
              size="sm"
              title={statusFilterTextName}
              className="mr-2 mb-1"
            >
              <Dropdown.Item onSelect={() => setStatusFilter('ALL')}>
                {t('pages:helpdesk.filter.all')}
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => setStatusFilter('OPEN')}>
                {t('pages:helpdesk.filter.open')}
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => setStatusFilter('CLOSED')}>
                {t('pages:helpdesk.filter.closed')}
              </Dropdown.Item>
            </PanelDropdownButton>
            <FilterInput onChange={value => setTicketsFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            responsive
            pagination
            columns={columns}
            data={filteredTickets}
            progressPending={loadingTickets}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('pages:helpdesk.noData')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('pages:helpdesk.ticketsPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default Home;
