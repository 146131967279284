import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { FaWindowRestore } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';

import { StoreState } from 'store/createStore';

import useSites from 'hooks/useSites';

import filter from 'utils/filter';

import Loading from 'components/Loading';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import TableNoData from 'components/TableNoData';
import PanelDropdownButton from 'components/PanelDropdownButton';

import { Container } from './styles';
import SiteActions from './components/SiteActions';

const Sites: React.FC = () => {
  const { t } = useTranslation();

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { sites, loading } = useSites(idRepresentante);

  type Site = typeof sites[number];

  type StatusFilter = 'ALL' | 'ACTIVE' | 'CANCELED' | 'CONGELADO';

  const [filterText, setFilterText] = useState('');
  const [statusFilter, setStatusFilter] = useState<StatusFilter>('ACTIVE');
  const [filteredSites, setFilteredSites] = useState<Site[]>([]);

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: Site[];
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  useEffect(() => {
    let filtered = filter(sites, ['clienteEmail', 'site'], filterText);

    filtered = filtered.filter(site => {
      switch (statusFilter) {
        case 'ACTIVE':
          return site.ativo && !site.congelado;
        case 'CONGELADO':
          return site.congelado;
        case 'CANCELED':
          return site.status === 'CANCELADO';
        default:
          return site;
      }
    });

    setFilteredSites(filtered);
  }, [filterText, sites, statusFilter]);

  const statusFilterTextName = useMemo(() => {
    switch (statusFilter) {
      case 'ACTIVE':
        return t('pages:sites.filter.active');
      case 'CONGELADO':
        return t('pages:sites.filter.congelados');
      case 'CANCELED':
        return t('pages:sites.filter.canceled');
      default:
        return t('pages:sites.filter.all');
    }
  }, [statusFilter, t]);

  const columns = [
    {
      name: t('common:site'),
      selector: 'site',
      cell: (row: Site) => row.site,
      sortable: true,
    },
    {
      name: t('common:customer'),
      selector: 'clienteEmail',
      cell: (row: Site) => row.clienteEmail,
      sortable: true,
    },
    {
      name: t('common:status'),
      selector: 'statusTxt',
      sortable: true,
      wrap: true,
    },
    {
      name: t('common:registration'),
      selector: 'dataCadastro',
      cell: (row: Site) =>
        format(parseISO(row.dataCadastro), 'dd/MM/yyyy', {
          locale: pt,
        }),
      sortable: true,
      width: '120px',
    },
  ];

  return (
    <Container>
      <PageTitle
        icon={<FaWindowRestore color="#FFFFFF" size={24} />}
        title={t('common:site_plural')}
        description={t('titles:customers.description')}
      />

      <TableWrapper>
        <TableHeader
          startOpen="help"
          title={t('pages:sites.title')}
          description={t('pages:sites.description')}
          helpContent={
            <div>
              <p className="font-weight-bold">
                {t('pages:sites.helpContent.p1')}
              </p>
              <p>{t('pages:sites.helpContent.p2')}</p>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://assine.hostnet.com.br/?rep=${idRepresentante}`}
              >
                {`https://assine.hostnet.com.br/?rep=${idRepresentante}`}
              </a>
              <br />
              <br />
              <p>{t('pages:sites.helpContent.p3')}</p>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://assine.hostnet.com.br/?rep=${idRepresentante}`}
              >
                {`https://assine.hostnet.com.br/?rep=${idRepresentante}&url=http://URL_AQUI/`}
              </a>
            </div>
          }
        />
        <TableSubHeader>
          <div />
          <div className="d-flex">
            <PanelDropdownButton
              size="sm"
              title={statusFilterTextName}
              className="mr-2 mb-1"
            >
              <Dropdown.Item onSelect={() => setStatusFilter('ALL')}>
                {t('pages:sites.filter.all')}
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => setStatusFilter('ACTIVE')}>
                {t('pages:sites.filter.active')}
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => setStatusFilter('CONGELADO')}>
                {t('pages:sites.filter.congelados')}
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => setStatusFilter('CANCELED')}>
                {t('pages:sites.filter.canceled')}
              </Dropdown.Item>
            </PanelDropdownButton>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            dense
            pagination
            defaultSortField="dataCadastro"
            defaultSortAsc={false}
            selectableRows
            columns={columns}
            data={filteredSites}
            progressPending={loading}
            progressComponent={<Loading />}
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            contextComponent={
              <SiteActions
                selectedCount={selectedRowsInfo.selectedCount}
                selectedRows={selectedRowsInfo.selectedRows}
                loading={loading}
              />
            }
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default Sites;
