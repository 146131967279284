import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from 'components/Header';
import SideMenu from 'components/SideMenu';
import Overlay from 'components/Overlay';

import { StoreState } from 'store/createStore';

import { logout } from 'store/modules/auth/actions';

import { Container, Content, ContentMargin, PageContent } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const { email1 } = useSelector((state: StoreState) => state.client.info);

  const isMobile = window.innerWidth <= 991;

  const [sideMenuCollapsed, setSideMenuCollapsed] = useState(isMobile);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  return (
    <Container>
      <Header
        email={email1}
        sideMenuCollapsed={sideMenuCollapsed}
        mobileMenuIsOpen={mobileMenuIsOpen}
        logout={() => dispatch(logout())}
        handleSideMenu={() => setSideMenuCollapsed(!sideMenuCollapsed)}
        handleMobileMenu={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
      />

      <Overlay
        active={!sideMenuCollapsed}
        onClick={() => setSideMenuCollapsed(true)}
      />

      <Content>
        <ContentMargin>
          <SideMenu collapsed={sideMenuCollapsed} siteOptions={[]} />
          <PageContent className={sideMenuCollapsed ? 'closed' : ''}>
            {children}
          </PageContent>
        </ContentMargin>
      </Content>
    </Container>
  );
};

export default DefaultLayout;
