import { useQuery } from 'react-query';

import api from 'services/api';

interface TicketItemResponse {
  idTicket: number;
  idCliente: number;
  idSituacao: number;
  idSite: number;
  idDepartamento: number;
  departamento: string;
  atendente: string;
  assunto: string;
  nivelTecnico: string;
  categoria: string;
  situacao: string;
  responsavel: string;
  abertura: string;
  fechamento: string;
  dadosSigilosos: string;
}

interface TicketsResponse {
  data: TicketItemResponse[];
}

interface Ticket {
  idTicket: number;
  idSituacao: number;
  idSite: number;
  idDepartamento: number;
  departamento: string;
  atendente: string;
  assunto: string;
  nivelTecnico: string;
  categoria: string;
  situacao: string;
  responsavel: string;
  abertura: string;
  fechamento: string;
  dadosSigilosos: string;
}

const initialData: Ticket = {
  idTicket: 0,
  idSituacao: 0,
  idSite: 0,
  idDepartamento: 0,
  abertura: '1970-01-01 00:00:00',
  assunto: '',
  nivelTecnico: '',
  responsavel: '',
  categoria: '',
  situacao: '',
  departamento: '',
  atendente: '',
  fechamento: '',
  dadosSigilosos: '',
};

function formatTicket(ticketItem: TicketItemResponse): Ticket {
  const {
    idTicket,
    idSituacao,
    idSite,
    idDepartamento,
    nivelTecnico,
    situacao,
    departamento,
    atendente,
    categoria,
    responsavel,
    assunto,
    abertura,
    fechamento,
    dadosSigilosos,
  } = ticketItem;

  return {
    idTicket,
    idSituacao,
    idSite,
    idDepartamento,
    nivelTecnico,
    situacao,
    departamento,
    atendente,
    categoria,
    responsavel,
    assunto,
    abertura,
    fechamento,
    dadosSigilosos,
  };
}
async function loadTicket(
  idRepresentante: number,
  idTicket: number,
): Promise<Ticket> {
  const ticketsStatus = [1, 2, 3, 4, 5, 6];

  const ticketsResponse = await api.get<TicketsResponse>(
    `helpdesk/v1/chamado`,
    {
      params: {
        idRepresentante,
        idTicket,
        situacoes: ticketsStatus,
      },
    },
  );

  const tickets = ticketsResponse.data.data.map(formatTicket);

  return tickets[0];
}

export default function useTicket(idRepresentante: number, idTicket: number) {
  const {
    data,
    isError,
    isLoading,
    isFetching,
    isFetched,
    refetch,
  } = useQuery<Ticket>(
    ['ticket', idTicket],
    () => loadTicket(idRepresentante, idTicket),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const ticket = data || initialData;

  return {
    ticket,
    error: isError,
    loading: isLoading,
    isFetching,
    isFetched,
    refetch,
  };
}
