import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { Card, Col, Form, Row, InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { format } from 'date-fns';
import { Formik } from 'formik';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import swal from 'services/swal';

import { Container } from './styles';

interface PayRoyaltiesFormValues {
  royalties: string;
  description: string;
}

interface PayRoyaltiesResponse {
  data: {
    url: string;
  };
}

const PayRoyalties: React.FC = () => {
  const { t } = useTranslation();

  const client = useSelector((state: StoreState) => state.client.info);

  const selectedDate = new Date();
  const date = format(selectedDate, 'dd/MM/yyyy');

  const payRoyalties = useCallback(
    async (values: PayRoyaltiesFormValues, formikHelpers) => {
      try {
        const valor: number = parseFloat(
          values.royalties.replace(/\./, '').replace(/,/, '.'),
        );

        const descricao: string = values.description;

        const payRoyaltiesResponse = await api.post<PayRoyaltiesResponse>(
          `financeiro/v1/representante/${client.idRepresentante}/royalty`,
          {
            valor,
            descricao,
          },
        );

        window.open(payRoyaltiesResponse.data.data.url);
      } catch (err) {
        swal.fire({
          title: t('common:requestFailed'),
          html: err.response.data.error_description,
        });
      } finally {
        formikHelpers.resetForm();
      }
    },
    [client, t],
  );

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:royalties.title')}
          description={t('pages:royalties.description')}
          helpContent={
            <div>
              <p>{t('pages:royalties.helpContent.p1')}</p>
            </div>
          }
        />

        <Formik
          enableReinitialize
          initialValues={{
            royalties: '0,001',
            description: '',
          }}
          validationSchema={Yup.object().shape({
            royalties: Yup.string().required(t('validations:requiredField')),
            description: Yup.string()
              .required(t('validations:requiredField'))
              .min(10, 'Informe no mínimo 10 caracteres'),
          })}
          onSubmit={payRoyalties}
        >
          {props => {
            function handleOnValueChange(value: string | undefined): void {
              let royalties = '';
              if (value !== '' && value !== undefined) {
                royalties = value.search(/^[.,]/) === 0 ? `0${value}` : value;
              }
              props.setFieldValue('royalties', royalties);
            }

            return (
              <Form onSubmit={props.handleSubmit}>
                <Card.Body className="fieldset">
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('common:date')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        id="date"
                        name="date"
                        value={date}
                        plaintext
                        readOnly
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('common:value')}
                    </Form.Label>
                    <Col sm={10}>
                      <InputGroup className="mr-sm-2" size="sm">
                        <InputGroup.Prepend>
                          <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <CurrencyInput
                          intlConfig={{ locale: 'pt-br' }}
                          id="royalties"
                          className="form-control"
                          name="royalties"
                          placeholder="0,00"
                          disabled={props.isSubmitting}
                          onValueChange={handleOnValueChange}
                          onBlur={props.handleBlur}
                          decimalsLimit={2}
                          decimalScale={2}
                          maxLength={12}
                          allowNegativeValue={false}
                          value={props.values.royalties}
                        />
                      </InputGroup>
                      <Form.Text className="text-muted">
                        {t('pages:royalties.minValue', { min: '10,00' })}
                      </Form.Text>
                      {props.errors.royalties && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {props.errors.royalties}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('common:description')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        id="description"
                        name="description"
                        value={props.values.description}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.description &&
                          !!props.errors.description
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.description}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Card.Body>

                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton
                    type="submit"
                    className="mr-1"
                    disabled={props.isSubmitting || !props.isValid}
                  >
                    {props.isSubmitting
                      ? t('common:requesting')
                      : t('common:request')}
                  </PanelButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default PayRoyalties;
