import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FaCogs } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableButton from 'components/TableButton';
import FilterInput from 'components/FilterInput';
import TableNoData from 'components/TableNoData';
import Loading from 'components/Loading';

import { StoreState } from 'store/createStore';

import useMarketingServices from 'hooks/useMarketingServices';

import filter from 'utils/filter';
import formatCurrency from 'utils/formatCurrency';
import hashIds from 'services/hashIds';

const Services: React.FC = () => {
  const { t } = useTranslation();

  const [filterText, setFilterText] = useState('');

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { marketingServices, isLoading } = useMarketingServices(
    idRepresentante,
  );

  const filteredMarketingServices = filter(
    marketingServices,
    ['servico'],
    filterText,
  );

  return (
    <div>
      <PageTitle
        icon={<FaCogs color="#FFFFFF" size={24} />}
        title={t('titles:marketing.title')}
        description={t('titles:marketing.description')}
      />
      <TableWrapper>
        <TableHeader
          title="Serviços"
          description="Todos os serviços disponíveis para venda."
          helpContent={
            <div>
              <p>{t('pages:customizedServices.helpContent.p1')}</p>
            </div>
          }
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            dense
            noHeader
            pagination
            columns={[
              {
                width: '90px',
                name: '',
                cell: ({ idServico }) => (
                  <TableButton
                    forwardedAs={Link}
                    className="btn-in-table"
                    to={`/automacao-marketing/servicos/${hashIds.encode(
                      idServico,
                    )}/venda`}
                  >
                    Vender
                  </TableButton>
                ),
              },
              {
                name: 'Item',
                selector: 'servico',
              },
              {
                name: 'Categoria',
                selector: 'categoria',
              },
              {
                name: 'Valor sugerido',
                selector: 'valor',
                sortable: true,
                cell: row =>
                  formatCurrency(row.valor) +
                  (row.periodicidade === 'MENSAL' ? '/mês' : ''),
              },
            ]}
            data={filteredMarketingServices}
            progressPending={isLoading}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            className="datatables-table"
          />
        </div>
      </TableWrapper>
    </div>
  );
};

export default Services;
