import { useQuery } from 'react-query';

import api from 'services/api';

type CustomerItemResponse = {
  idRepresentanteCliente: number;
  idRepresentante: number;
  idCliente: number;
  ativo: boolean;
  dataAtivacao: string;
  dataDesativacao: string;
  cliente: {
    idCliente: number;
    email1: string;
    nome: string;
    responsavel: string;
    dataCadastro: string;
  };
};

type CustomerResponse = {
  data: CustomerItemResponse[];
};

type Customer = {
  idRepresentanteCliente: number;
  idRepresentante: number;
  idCliente: number;
  ativo: boolean;
  email1: string;
  dataAtivacao: string;
  dataDesativacao: string;
  cliente: {
    idCliente: number;
    email1: string;
    nome: string;
    responsavel: string;
    dataCadastro: string;
  };
};

const initialData: Customer[] = [];

function formatCustomer(customer: CustomerItemResponse): Customer {
  const {
    idRepresentanteCliente,
    idRepresentante,
    idCliente,
    cliente,
    ...customerRest
  } = customer;

  return {
    idCliente: Number(idCliente),
    idRepresentante: Number(idRepresentante),
    idRepresentanteCliente: Number(idRepresentanteCliente),
    email1: cliente.email1,
    cliente,
    ...customerRest,
  };
}

async function loadCustomers(idRepresentante: number): Promise<Customer[]> {
  const customersResponse = await api.get<CustomerResponse>(
    `clientes/v1/representante/${idRepresentante}/cliente?filter[ativo]=1`,
  );

  const customers = customersResponse.data.data.map(formatCustomer);

  return customers;
}

export default function useCustomers(idRepresentante: number) {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<
    Customer[]
  >('customers', () => loadCustomers(idRepresentante), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    retry: false,
  });

  const customers = data || initialData;

  return {
    customers,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
