import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';

import Error from 'components/Error';
import Loading from 'components/Loading';

import api from 'services/api';
import hashIds from 'services/hashIds';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type DashboardProps = RouteComponentProps<MatchParams>;

interface LoginResponse {
  data: {
    url: string;
  };
}

const Dashboard: React.FC<DashboardProps> = ({ match }) => {
  const { id } = match.params;

  const { search } = useLocation();

  const idCustomer = Number(hashIds.decode(id));

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const [error, setError] = useState(false);

  const getTarget = useCallback(() => {
    const urlSearchParams = new URLSearchParams(search);
    return urlSearchParams.get('target') ?? '';
  }, [search]);

  useEffect(() => {
    async function loadLoginUrl() {
      try {
        setError(false);

        const loginResponse = await api.post<LoginResponse>(
          'painel/v1/representante/login',
          {
            idRepresentante,
            idCliente: idCustomer,
            ...(getTarget() !== '' && { target: getTarget() }),
          },
        );

        window.location.replace(loginResponse.data.data.url);
      } catch (err) {
        setError(true);
      }
    }

    loadLoginUrl();
  }, [getTarget, idCustomer, idRepresentante]);

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default withRouter(Dashboard);
