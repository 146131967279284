import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaIdCard } from 'react-icons/fa';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';
import PhoneInput from 'components/PhoneInput';
import Error from 'components/Error';
import Loading from 'components/Loading';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import { Container } from './styles';

type bankAccountTypeOption = 'CORRENTE' | 'POUPANCA';

interface RepresentativeItemResponse {
  idCliente: number;
  idRepresentante: number;
  nome: string;
  empresa: boolean;
  razaoSocial: string;
  cnpj: number;
  internacional: boolean;
  estado: string;
  cidade: string;
  dataAdmissao: string;
  dataCadastro: string;
  dataInativacao: string;
  ativo: boolean;
  status: string;
  royaltyAvulso: boolean;
  comunicacaoCliente: boolean;
  email1: string;
  email2: string;
  tel1: string;
  tel2: string;
  tel3: string;
  banco1: string;
  agencia1: string;
  conta1: string;
  tipoConta1: bankAccountTypeOption;
  banco2: string;
  agencia2: string;
  conta2: string;
  tipoConta2: bankAccountTypeOption;
  chavePix: string;
  nomeChavePix: string;
  tipoChavePix: string;
}

interface RepresentativeResponse {
  data: RepresentativeItemResponse[];
}

interface EditRepresentativeResponse {
  data: RepresentativeItemResponse;
}

interface Representative {
  idCliente: number;
  idRepresentante: number;
  nome: string;
  empresa: boolean;
  razaoSocial: string;
  cnpj: number;
  internacional: boolean;
  estado: string;
  cidade: string;
  dataAdmissao: string;
  dataCadastro: string;
  dataInativacao: string;
  ativo: boolean;
  status: string;
  royaltyAvulso: boolean;
  comunicacaoCliente: boolean;
  email1: string;
  email2: string;
  tel1: string;
  tel2: string;
  tel3: string;
  banco1: string;
  agencia1: string;
  conta1: string;
  tipoConta1: bankAccountTypeOption;
  banco2: string;
  agencia2: string;
  conta2: string;
  tipoConta2: bankAccountTypeOption;
  chavePix: string;
  nomeChavePix: string;
  tipoChavePix: string;
}

interface EditRepresentativeFormValues {
  name: string;
  companyName: string;
  cnpj: number;
  state: string;
  city: string;
  email1: string;
  email2: string;
  countryCode1: string;
  phone1: string;
  countryCode2: string;
  phone2: string;
  countryCode3: string;
  phone3: string;
}

interface EditRepresentativeBankFormValues {
  bank1: string;
  bankBranch1: string;
  bankAccount1: string;
  bankAccountType1: bankAccountTypeOption;
  bank2: string;
  bankBranch2: string;
  bankAccount2: string;
  bankAccountType2: bankAccountTypeOption;
}

interface EditPixFormValues {
  pixKey: string;
  pixName: string;
  pixKeyType: string;
}

interface StatesResponse {
  data: {
    idEstado: number;
    estado: string;
    sigla: string;
  }[];
}

type State = {
  idEstado: number;
  estado: string;
  sigla: string;
};

interface CitiesResponse {
  data: {
    idCidade: number;
    cidade: string;
    ddd: number;
  }[];
}

type City = {
  idCidade: number;
  cidade: string;
  ddd: number;
};

const Home: React.FC = () => {
  const { t } = useTranslation();

  const client = useSelector((state: StoreState) => state.client.info);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removingPixData, setRemovingPixData] = useState(false);

  const [states, setStates] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState<String>('RJ');
  const [cities, setCities] = useState<City[]>([]);

  const [representative, setRepresentative] = useState<Representative>({
    idCliente: 0,
    idRepresentante: 0,
    nome: '',
    empresa: false,
    razaoSocial: '',
    cnpj: 0,
    internacional: false,
    estado: '',
    cidade: '',
    dataAdmissao: '',
    dataCadastro: '',
    dataInativacao: '',
    ativo: false,
    status: '',
    royaltyAvulso: false,
    comunicacaoCliente: false,
    email1: '',
    email2: '',
    tel1: '55',
    tel2: '55',
    tel3: '55',
    banco1: '',
    agencia1: '',
    conta1: '',
    tipoConta1: 'CORRENTE',
    banco2: '',
    agencia2: '',
    conta2: '',
    tipoConta2: 'CORRENTE',
    chavePix: '',
    nomeChavePix: '',
    tipoChavePix: '',
  });

  const formatRepresentative = useCallback(
    ({
      idCliente,
      idRepresentante,
      nome,
      empresa,
      razaoSocial,
      cnpj,
      internacional,
      estado,
      cidade,
      dataAdmissao,
      dataCadastro,
      dataInativacao,
      ativo,
      status,
      royaltyAvulso,
      comunicacaoCliente,
      email1,
      email2,
      tel1,
      tel2,
      tel3,
      banco1,
      agencia1,
      conta1,
      tipoConta1,
      banco2,
      agencia2,
      conta2,
      tipoConta2,
      chavePix,
      nomeChavePix,
      tipoChavePix,
    }: RepresentativeItemResponse) => ({
      idCliente,
      idRepresentante,
      nome,
      empresa,
      razaoSocial,
      cnpj,
      internacional,
      estado,
      cidade,
      dataAdmissao,
      dataCadastro,
      dataInativacao,
      ativo,
      status,
      royaltyAvulso,
      comunicacaoCliente,
      email1,
      email2,
      tel1,
      tel2,
      tel3,
      banco1,
      agencia1,
      conta1,
      tipoConta1,
      banco2,
      agencia2,
      conta2,
      tipoConta2,
      chavePix,
      nomeChavePix,
      tipoChavePix,
    }),

    [],
  );

  function getPixKeyInputMask(type: string) {
    const masks: { [char: string]: string } = {
      CPF: '999.999.999-99',
      CNPJ: '99.999.999/9999-99',
      EMAIL: '',
    };

    if (type in masks) {
      return masks[type];
    }

    return '';
  }

  useEffect(() => {
    async function loadRepresentative() {
      try {
        setError(false);
        setLoading(true);

        const representativeResponse = await api.get<RepresentativeResponse>(
          `clientes/v1/representante?filter[idCliente]=${client.idCliente}`,
        );

        const formattedRepresentative = formatRepresentative(
          representativeResponse.data.data[0],
        );

        setRepresentative(formattedRepresentative);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadRepresentative();
  }, [formatRepresentative, client.idCliente]);

  const editRepresentative = useCallback(
    async (values: EditRepresentativeFormValues) => {
      try {
        const params = {
          nome: values.name,
          empresa: true,
          razaoSocial: values.companyName,
          cnpj: values.cnpj,
          estado: values.state,
          cidade: values.city,
          email1: values.email1,
          ...(values.email2 && { email2: values.email2 }),
          telefone1:
            values.phone1 &&
            `${values.countryCode1}.${values.phone1.replace(/\D/g, '')}`,
          ...(values.phone2 && {
            telefone2: `${values.countryCode2}.${values.phone2.replace(
              /\D/g,
              '',
            )}`,
          }),
          ...(values.phone3 && {
            telefone3: `${values.countryCode3}.${values.phone3.replace(
              /\D/g,
              '',
            )}`,
          }),
        };

        const representativeResponse = await api.put<EditRepresentativeResponse>(
          `clientes/v1/representante/${representative.idRepresentante}`,
          params,
        );

        const formattedRepresentative = formatRepresentative(
          representativeResponse.data.data,
        );

        setRepresentative(formattedRepresentative);

        toast.fire(t('pages:registration.submitSuccess'));
      } catch (err) {
        swal.fire({
          title: t('pages:registration.submitFail'),
          html: err.response && err.response.data.error_description,
        });
      }
    },
    [representative.idRepresentante, formatRepresentative, t],
  );

  const editRepresentativeBank = useCallback(
    async (values: EditRepresentativeBankFormValues) => {
      try {
        const conta1 = {
          ...(values.bank1 && {
            banco: values.bank1.replace(/\D/g, ''),
          }),
          ...(values.bankBranch1 && {
            agencia: values.bankBranch1.replace(/\D/g, ''),
          }),
          ...(values.bankAccount1 && {
            conta: values.bankAccount1.replace(/\D/g, ''),
          }),
          ...(values.bankAccountType1 && {
            tipo: values.bankAccountType1,
          }),
        };

        const conta2 = {
          ...(values.bank2 && {
            banco: values.bank2.replace(/\D/g, ''),
          }),
          ...(values.bankBranch2 && {
            agencia: values.bankBranch2.replace(/\D/g, ''),
          }),
          ...(values.bankAccount2 && {
            conta: values.bankAccount2.replace(/\D/g, ''),
          }),
          ...(values.bankAccountType2 && {
            tipo: values.bankAccountType2,
          }),
        };

        const representativeResponse = await api.put<EditRepresentativeResponse>(
          `clientes/v1/representante/${representative.idRepresentante}`,
          { conta1, conta2 },
        );

        const formattedRepresentative = formatRepresentative(
          representativeResponse.data.data,
        );

        setRepresentative(formattedRepresentative);

        toast.fire(t('pages:registration.submitSuccess'));
      } catch (err) {
        swal.fire({
          title: t('pages:registration.submitFail'),
          html: err.response && err.response.data.error_description,
        });
      }
    },
    [representative.idRepresentante, formatRepresentative, t],
  );

  async function editPix(values: EditPixFormValues) {
    try {
      let { pixKey } = values;

      if (['CPF', 'CNPJ'].includes(values.pixKeyType)) {
        pixKey = pixKey.replace(/\D/g, '');
      }

      const pix = {
        nome: values.pixName,
        chave: pixKey,
        tipo: values.pixKeyType,
      };

      const representativeResponse = await api.put<EditRepresentativeResponse>(
        `clientes/v1/representante/${representative.idRepresentante}`,
        { pix },
      );

      const formattedRepresentative = formatRepresentative(
        representativeResponse.data.data,
      );

      setRepresentative(formattedRepresentative);

      toast.fire(t('pages:registration.submitSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:registration.submitFail'),
        html: err.response && err.response.data.error_description,
      });
    }
  }

  useEffect(() => {
    async function loadStates() {
      try {
        setError(false);
        setLoading(true);

        const statesResponse = await api.get<StatesResponse>(
          `regiao/v1/estado?idPais=23`,
        );

        const formattedStates = statesResponse.data.data.map(
          ({ idEstado, estado, sigla }) => ({
            idEstado,
            estado,
            sigla,
          }),
        );

        setStates(formattedStates);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadStates();
  }, []);

  useEffect(() => {
    async function loadCities() {
      try {
        setError(false);

        const citiesResponse = await api.get<CitiesResponse>(
          `regiao/v1/cidade?siglaEstado=${selectedState}`,
        );

        const formattedCities = citiesResponse.data.data.map(
          ({ idCidade, cidade, ddd }) => ({
            idCidade,
            cidade,
            ddd,
          }),
        );

        setCities(formattedCities);
      } catch (err) {
        setError(true);
      }
    }

    loadCities();
  }, [selectedState]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        icon={<FaIdCard color="#FFFFFF" size={24} />}
        title={t('titles:registration.title')}
        description={t('titles:registration.description')}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:registration.contactDataTitle')}
          description={t('pages:registration.contactDataDescription')}
          helpContent={
            <div>
              <p>{t('pages:registration.contactDataHelpContent.p1')}</p>
              <p>{t('pages:registration.contactDataHelpContent.p2')}</p>
            </div>
          }
          helpLinks={[
            {
              text: 'Conheça nosso modelo de negócio',
              url: 'https://www.hostnet.com.br/seja-uma-franquia-hostnet/',
            },
            {
              text: 'Contratos de Franquia',
              url: 'https://ajuda.hostnet.com.br/contratos-de-franquia/',
            },
          ]}
        />

        <Formik
          enableReinitialize
          initialValues={{
            name: representative.nome,
            companyName: representative.razaoSocial,
            cnpj: representative.cnpj,
            state: representative.estado,
            city: representative.cidade,
            email1: representative.email1,
            email2: representative.email2,
            countryCode1:
              representative.tel1 && representative.tel1.split('.')[0],
            phone1: representative.tel1 && representative.tel1.split('.')[1],
            countryCode2:
              representative.tel2 && representative.tel2.split('.')[0],
            phone2: representative.tel2 && representative.tel2.split('.')[1],
            countryCode3:
              representative.tel3 && representative.tel3.split('.')[0],
            phone3: representative.tel3 && representative.tel3.split('.')[1],
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .required(t('validations:requiredField'))
              .min(10, 'Informe no mínimo 10 caracteres'),
            companyName: Yup.string()
              .required(t('validations:requiredField'))
              .min(10, 'Informe no mínimo 10 caracteres'),
            cnpj: Yup.string()
              .required(t('validations:requiredField'))
              .matches(/^[0-9]+$/, t('common:onlyNumbers'))
              .min(14, 'Informe no mínimo 14 números')
              .max(16, 'Informe no máximo 16 números'),
            state: Yup.string().required(t('validations:requiredField')),
            city: Yup.string().required(t('validations:requiredField')),
            email1: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('validations:requiredField')),
            email2: Yup.string()
              .nullable()
              .email(t('validations:invalidEmail')),
            countryCode1: Yup.number()
              .required(t('validations:requiredField'))
              .typeError(t('validations:invalidNumber')),
            phone1: Yup.string()
              .test('len', t('validations:phone'), val => {
                return (
                  !!val &&
                  val.replace(/[^\d]/g, '').length > 0 &&
                  val.replace(/[^\d]/g, '').length >= 10 &&
                  val.replace(/[^\d]/g, '').length <= 11
                );
              })
              .required(t('validations:requiredField')),
            countryCode2: Yup.number()
              .nullable()
              .typeError(t('validations:invalidNumber')),
            phone2: Yup.string()
              .nullable()
              .matches(
                /^[(][0-9]{2}[)][ ][0-9]{4,5}[-][0-9]{4}$/,
                t('validations:phone'),
              ),
            countryCode3: Yup.number()
              .nullable()
              .typeError(t('validations:invalidNumber')),
            phone3: Yup.string()
              .nullable()
              .matches(
                /^[(][0-9]{2}[)][ ][0-9]{4,5}[-][0-9]{4}$/,
                t('validations:phone'),
              ),
          })}
          onSubmit={editRepresentative}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:name')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="name"
                      name="name"
                      value={props.values.name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.name && !!props.errors.name}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:companyName')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="companyName"
                      name="companyName"
                      value={props.values.companyName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.companyName &&
                        !!props.errors.companyName
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.companyName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:cnpj')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="cnpj"
                      name="cnpj"
                      maxLength={14}
                      value={props.values.cnpj}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.cnpj && !!props.errors.cnpj}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.cnpj}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:state')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      id="state"
                      name="state"
                      value={props.values.state}
                      onChange={e => {
                        props.handleChange(e);
                        setSelectedState(e.target.value);
                      }}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.state && !!props.errors.state}
                      disabled={props.isSubmitting}
                    >
                      {states.map(state => (
                        <option key={state.idEstado} value={state.sigla}>
                          {state.estado}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.state}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:city')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      id="city"
                      name="city"
                      value={props.values.city}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.city && !!props.errors.city}
                      disabled={props.isSubmitting}
                    >
                      {cities.map(city => (
                        <option key={city.idCidade} value={city.cidade}>
                          {city.cidade}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.city}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:email1')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="email"
                      id="email1"
                      name="email1"
                      value={props.values.email1}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.email1 && !!props.errors.email1
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.email1}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:email2')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="email"
                      id="email2"
                      name="email2"
                      value={props.values.email2}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.email2 && !!props.errors.email2
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.email2}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:phone1')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Row>
                      <Form.Group as={Col} className="ddi-container">
                        <Form.Control
                          type="string"
                          placeholder="DDI"
                          id="countryCode1"
                          name="countryCode1"
                          maxLength={3}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.countryCode1 &&
                            !!props.errors.countryCode1
                          }
                          value={props.values.countryCode1}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('common:countryCode')}
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.countryCode1}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <PhoneInput
                          name="phone1"
                          value={props.values.phone1}
                          handleChange={props.handleChange}
                          handleBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.phone1 && !!props.errors.phone1
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('common:number')}
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.phone1}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:phone2')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Row>
                      <Form.Group as={Col} className="ddi-container">
                        <Form.Control
                          type="string"
                          placeholder="DDI"
                          id="countryCode2"
                          name="countryCode2"
                          maxLength={3}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.countryCode2 &&
                            !!props.errors.countryCode2
                          }
                          value={props.values.countryCode2}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('common:countryCode')}
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.countryCode2}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <PhoneInput
                          name="phone2"
                          value={props.values.phone2}
                          handleChange={props.handleChange}
                          handleBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.phone2 && !!props.errors.phone2
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('common:number')}
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.phone2}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:phone3')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Row>
                      <Form.Group as={Col} className="ddi-container">
                        <Form.Control
                          type="string"
                          placeholder="DDI"
                          id="countryCode3"
                          name="countryCode3"
                          maxLength={3}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.countryCode3 &&
                            !!props.errors.countryCode3
                          }
                          value={props.values.countryCode3}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('common:countryCode')}
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.countryCode3}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <PhoneInput
                          name="phone3"
                          value={props.values.phone3}
                          handleChange={props.handleChange}
                          handleBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.phone3 && !!props.errors.phone3
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('common:number')}
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.phone3}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:saving') : t('common:save')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>

      <FormWrapper>
        <FormHeader
          title={t('pages:registration.bankAccountsTitle')}
          description={t('pages:registration.bankAccountsDescription')}
          helpContent={
            <div>
              <p>{t('pages:registration.bankAccountsHelpContent.p1')}</p>
              <p>{t('pages:registration.bankAccountsHelpContent.p2')}</p>
            </div>
          }
          helpLinks={[
            {
              text: 'Conheça nosso modelo de negócio',
              url: 'https://www.hostnet.com.br/seja-uma-franquia-hostnet/',
            },
            {
              text: 'Contratos de Franquia',
              url: 'https://ajuda.hostnet.com.br/contratos-de-franquia/',
            },
          ]}
        />

        <Formik
          enableReinitialize
          initialValues={{
            bank1: representative.banco1,
            bankBranch1: representative.agencia1,
            bankAccount1: representative.conta1,
            bankAccountType1: representative.tipoConta1,
            bank2: representative.banco2,
            bankBranch2: representative.agencia2,
            bankAccount2: representative.conta2,
            bankAccountType2: representative.tipoConta2,
          }}
          validationSchema={Yup.object().shape({
            bank1: Yup.string()
              .nullable()
              .required(t('validations:requiredField'))
              .matches(/^[0-9]+$/, t('common:onlyNumbers'))
              .length(3, 'Informe o código do banco de 3 números'),
            bankBranch1: Yup.string()
              .nullable()
              .required(t('validations:requiredField'))
              .matches(/^[0-9]+$/, t('common:onlyNumbers'))
              .min(3, 'Informe no mínimo 3 números')
              .max(4, 'Informe no máximo 4 números'),
            bankAccount1: Yup.string()
              .nullable()
              .required(t('validations:requiredField'))
              .matches(/^[0-9]+$/, t('common:onlyNumbers'))
              .min(5, 'Informe no mínimo 5 números')
              .max(12, 'Informe no máximo 12 números'),
            bankAccountType1: Yup.string()
              .nullable()
              .required(t('validations:invalidRadio')),
            bank2: Yup.string()
              .nullable()
              .matches(/^[0-9]+$/, t('common:onlyNumbers'))
              .length(3, 'Informe o código do banco de 3 números'),
            bankBranch2: Yup.string()
              .nullable()
              .matches(/^[0-9]+$/, t('common:onlyNumbers'))
              .min(3, 'Informe no mínimo 3 números')
              .max(6, 'Informe no máximo 4 números'),
            bankAccount2: Yup.string()
              .nullable()
              .matches(/^[0-9]+$/, t('common:onlyNumbers'))
              .min(5, 'Informe no mínimo 5 números')
              .max(12, 'Informe no máximo 12 números'),
            bankAccountType2: Yup.string()
              .nullable()
              .required(t('validations:invalidRadio')),
          })}
          onSubmit={editRepresentativeBank}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('pages:registration.mainAccount')} />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:registration.bank')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="bank1"
                      name="bank1"
                      maxLength={3}
                      value={props.values.bank1}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.bank1 && !!props.errors.bank1}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.bank1}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:registration.branch')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="bankBranch1"
                      name="bankBranch1"
                      value={props.values.bankBranch1}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.bankBranch1 &&
                        !!props.errors.bankBranch1
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.bankBranch1}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:registration.account')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="bankAccount1"
                      name="bankAccount1"
                      value={props.values.bankAccount1}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.bankAccount1 &&
                        !!props.errors.bankAccount1
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.bankAccount1}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:registration.accountType')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="bankAccountType1-corrente"
                      name="bankAccountType1"
                      label={t('pages:registration.checkingAccount')}
                      value="CORRENTE"
                      checked={props.values.bankAccountType1 === 'CORRENTE'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.bankAccountType1 &&
                        !!props.errors.bankAccountType1
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="bankAccountType1-poupanca"
                      name="bankAccountType1"
                      label={t('pages:registration.savingsAccount')}
                      value="POUPANCA"
                      checked={props.values.bankAccountType1 === 'POUPANCA'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.bankAccountType1 &&
                        !!props.errors.bankAccountType1
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.bankAccountType1}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:registration.secondaryAccount')}
                />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:registration.bank')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="bank2"
                      name="bank2"
                      maxLength={3}
                      value={props.values.bank2}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.bank2 && !!props.errors.bank2}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.bank2}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:registration.branch')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="bankBranch2"
                      name="bankBranch2"
                      value={props.values.bankBranch2}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.bankBranch2 &&
                        !!props.errors.bankBranch2
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.bankBranch2}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:registration.account')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="bankAccount2"
                      name="bankAccount2"
                      value={props.values.bankAccount2}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.bankAccount2 &&
                        !!props.errors.bankAccount2
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.bankAccount2}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:registration.accountType')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="bankAccountType2-corrente"
                      name="bankAccountType2"
                      label={t('pages:registration.checkingAccount')}
                      value="CORRENTE"
                      checked={props.values.bankAccountType2 === 'CORRENTE'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.bankAccountType2 &&
                        !!props.errors.bankAccountType2
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="bankAccountType2-poupanca"
                      name="bankAccountType2"
                      label={t('pages:registration.savingsAccount')}
                      value="POUPANCA"
                      checked={props.values.bankAccountType2 === 'POUPANCA'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.bankAccountType2 &&
                        !!props.errors.bankAccountType2
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.bankAccountType2}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:saving') : t('common:save')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>

      <FormWrapper>
        <FormHeader
          title={t('pages:registration.pixTitle')}
          description={t('pages:registration.pixDescription')}
          helpContent={
            <div>
              <p>{t('pages:registration.bankAccountsHelpContent.p1')}</p>
              <p>{t('pages:registration.bankAccountsHelpContent.p2')}</p>
            </div>
          }
        />

        <Formik
          enableReinitialize
          initialValues={{
            pixKey: representative.chavePix ?? '',
            pixName: representative.nomeChavePix ?? '',
            pixKeyType: representative.tipoChavePix,
          }}
          validationSchema={Yup.object().shape({
            pixName: Yup.string().required(t('validations:requiredField')),
            pixKey: Yup.string()
              .when('pixKeyType', {
                is: 'CNPJ',
                then: Yup.string().test(
                  'validDocument',
                  t('validations:invalidFormat'),
                  val => !!val && val.replace(/[^\d]/g, '').length === 14,
                ),
              })
              .when('pixKeyType', {
                is: 'CPF',
                then: Yup.string().test(
                  'validDocument',
                  t('validations:invalidFormat'),
                  val => !!val && val.replace(/[^\d]/g, '').length === 11,
                ),
              })
              .when('pixKeyType', {
                is: 'EMAIL',
                then: Yup.string().email(t('validations:invalidEmail')),
              })
              .required(t('validations:requiredField')),
          })}
          onSubmit={editPix}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row} controlId="registration.pixKeyType">
                  <Form.Label column sm={2}>
                    {t('pages:registration.keyType')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="pixCpf"
                      name="pixKeyType"
                      label="CPF"
                      value="CPF"
                      checked={props.values.pixKeyType === 'CPF'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.pixKeyType && !!props.errors.pixKeyType
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="pixCnpj"
                      name="pixKeyType"
                      label="CNPJ"
                      value="CNPJ"
                      checked={props.values.pixKeyType === 'CNPJ'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.pixKeyType && !!props.errors.pixKeyType
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.pixKeyType}
                    </Form.Control.Feedback>

                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="pixEmail"
                      name="pixKeyType"
                      label="E-mail"
                      value="EMAIL"
                      checked={props.values.pixKeyType === 'EMAIL'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.pixKeyType && !!props.errors.pixKeyType
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.pixKeyType}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="registration.pixKey">
                  <Form.Label column sm={2} className="required">
                    Chave PIX
                  </Form.Label>
                  <Col sm={10}>
                    <InputMask
                      name="pixKey"
                      mask={getPixKeyInputMask(props.values.pixKeyType)}
                      value={props.values.pixKey}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled={props.isSubmitting}
                      className={`form-control ${
                        !!props.touched.pixKey &&
                        !!props.errors.pixKey &&
                        'is-invalid'
                      }`}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.pixKey}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2} className="required">
                    {t('common:name')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      id="pixName"
                      name="pixName"
                      value={props.values.pixName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.pixName && !!props.errors.pixName
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.pixName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:saving') : t('common:save')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  type="button"
                  className="mr-1"
                  onClick={async () => {
                    setRemovingPixData(true);

                    props.setFieldValue('pixKey', '');
                    props.setFieldValue('pixName', '');
                    props.setFieldValue('pixKeyType', 'CPF');

                    await editPix({
                      pixKey: '',
                      pixName: '',
                      pixKeyType: 'CPF',
                    });

                    setRemovingPixData(false);
                  }}
                  disabled={removingPixData || props.isSubmitting}
                >
                  {removingPixData ? t('common:saving') : 'Remover dados PIX'}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default Home;
