import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaWindowRestore } from 'react-icons/fa';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import TableWrapper from 'components/TableWrapper';
import PageTitle from 'components/PageTitle';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import TableNoData from 'components/TableNoData';

import hashIds from 'services/hashIds';

import useCustomer from 'hooks/useCustomer';
import useServices from 'hooks/useServices';
import useMainProducts from 'hooks/useMainProducts';

import filter from 'utils/filter';
import formatCurrency from 'utils/formatCurrency';

import { StoreState } from 'store/createStore';

import ServiceActions from './components/ServiceActions';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type SaleProps = RouteComponentProps<MatchParams>;

const Sale: React.FC<SaleProps> = ({ match }) => {
  const { t } = useTranslation();

  const { id } = match.params;

  const idCustomer = Number(hashIds.decode(id));

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { services, isLoading: loadingServices } = useServices(idRepresentante);
  const { mainProducts, isLoading: loadingProducts } = useMainProducts(
    idRepresentante,
  );
  const { customer } = useCustomer(idRepresentante, idCustomer);

  const [selectedServicesRowsInfo, setSelectedServicesRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof services;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [servicesFilterText, setServicesFilterText] = useState('');
  const [filteredServices, setFilteredServices] = useState(services);

  const [productsFilterText, setProductsFilterText] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(mainProducts);

  useEffect(() => {
    const filtered = filter(
      services,
      ['categoria', 'servico'],
      servicesFilterText,
    );

    setFilteredServices(filtered);
  }, [servicesFilterText, services]);

  useEffect(() => {
    const filtered = filter(mainProducts, ['produto'], productsFilterText);

    setFilteredProducts(filtered);
  }, [mainProducts, productsFilterText]);

  function generatePainel3Link(name: string, code: string) {
    const basePainel3Url = `https://painel.hostnet.com.br/representante.painel.acessar?cliente=${customer.email1}&target=`;

    let target = '';

    switch (code) {
      case 'hospedagem':
        target = 'hospedagem.contratar';
        break;

      case 'dominio':
        target = 'dominio.registrar';
        break;

      case 'cdn-cloudflare':
        target = 'cdn';
        break;

      case 'academia-hostnet':
        target = 'servico.adicional#aba-9';
        break;

      case 'mobile-builder':
        target = 'mobilebuilder.contratar';
        break;

      default:
        target = '';
        break;
    }

    const productLink = (
      <a href={`${basePainel3Url}${target}`} rel="noreferrer" target="_blank">
        {name}
      </a>
    );

    return productLink;
  }

  function generatePainel4Link(name: string, code: string) {
    let productId = 0;

    switch (code) {
      case 'suporte-premium':
        productId = 306;
        break;

      case 'manutencao-site':
        productId = 321;
        break;

      default:
        break;
    }

    const hashIdCustomer = hashIds.encode(idCustomer);
    const hashIdProduct = hashIds.encode(productId);

    const url = `/venda/${hashIdCustomer}/produto/${hashIdProduct}`;

    return <Link to={url}>{name}</Link>;
  }

  function generateProductLink(name: string, code: string) {
    const painel4Products = ['manutencao-site', 'suporte-premium'];

    if (painel4Products.includes(code)) {
      return generatePainel4Link(name, code);
    }

    return generatePainel3Link(name, code);
  }

  return (
    <Container>
      <PageTitle
        icon={<FaWindowRestore color="#FFFFFF" size={24} />}
        title={t('titles:customers.title')}
        description={t('titles:customers.description')}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:saleNew.productsTitle')}
          description={`${t('pages:saleNew.productsDescription')} ${
            customer.email1
          }`}
        />

        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setProductsFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            columns={[
              {
                name: t('common:item'),
                selector: 'produto',
                sortable: true,
                cell: row => generateProductLink(row.produto, row.codigo),
              },
              {
                name: t('common:value'),
                selector: 'valor',
                cell: row =>
                  `${t('pages:saleNew.from')} ${formatCurrency(row.valor)}`,
                sortable: true,
              },
            ]}
            data={filteredProducts}
            progressPending={loadingProducts}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:saleNew.servicesTitle')}
          description={`${t('pages:saleNew.servicesDescription')} ${
            customer.email1
          }`}
        />

        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setServicesFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedServicesRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:item'),
                selector: 'servico',
                sortable: true,
              },
              {
                name: t('common:category'),
                selector: 'categoria',
                sortable: true,
              },
              {
                name: t('pages:saleNew:suggestedValue'),
                selector: row =>
                  row.periodicidade === 'MENSAL' ? row.valor : row.setup,
                sortable: true,
                cell: row =>
                  row.periodicidade === 'MENSAL'
                    ? `${formatCurrency(row.valor)}/${t(
                        'common:month',
                      ).toLowerCase()}`
                    : formatCurrency(row.setup),
                maxWidth: '180px',
              },
            ]}
            data={filteredServices}
            progressPending={loadingServices}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            onSelectedRowsChange={rows => setSelectedServicesRowsInfo(rows)}
            contextComponent={
              <ServiceActions
                selectedCount={selectedServicesRowsInfo.selectedCount}
                selectedRows={selectedServicesRowsInfo.selectedRows}
                idCustomer={idCustomer}
                loading={false}
              />
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default withRouter(Sale);
