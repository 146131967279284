import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const Container = styled.div``;

export const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

export const Description = styled.div`
  font-size: 0.9em;
`;

export const Help = styled(Card.Body)`
  display: none;

  background-color: #f5f6f7;

  &.active {
    display: block;
  }
`;

export const ExportTable = styled(Card.Body)`
  display: none;

  background-color: #f5f6f7;

  &.active {
    display: block;
  }
`;

export const HelpContent = styled.div`
  padding: 1.25rem;
`;

export const HelpLinks = styled.div`
  padding: 1.25rem;

  a {
    color: var(--color-midnight-blue);

    &:hover {
      text-decoration: none;
    }
  }
`;

export const TableHeaderButton = styled.button`
  border: none;
  background: none;
  outline: none;

  display: flex;
  flex-flow: column;
  align-items: center;

  color: #2c4593;

  &:focus {
    outline: none;
  }

  span {
    font-size: 0.7em;
  }

  &.active ::after {
    content: ' ';
    display: block;
    border-bottom: 4px solid #2c4593;
    position: relative;
    top: 21px;
  }
`;

export const LinkList = styled.ul`
  padding: 0;
  list-style: none;
`;
