import { createGlobalStyle } from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'simplebar-react/dist/simplebar.min.css';

import 'assets/css/border.css';
import 'assets/css/datatables.css';
import 'assets/css/toast.css';

export default createGlobalStyle`
  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
    background-color: #F3F3F3;
  }

  body, input, button {
    font-family: "Open Sans", sans-serif;
    font-size: 0.9375rem;
  }

  button {
    cursor: pointer;
  }

  :root {
    --color-primary: #0A1D3E;
    --color-blue: #2C4593;
    --color-violet: #95358B;
    --color-green: #30A96E;
    --color-yellow: #F3B800;
    --color-black: #333333;
    --color-light-blue: #42a5f5;
    --color-background-white: #f4f4f4;
    --color-alice-blue: #f1f7ff;
    --color-cadet-blue: #b7c0cd;
    --color-azure-blue: #3857ba;
    --color-midnight-blue: #122A56;
    --color-salmon-pink: #ff869a;
  }
`;
