import React from 'react';

import HeaderLogo from 'components/HeaderLogo';
import HeaderMenu from 'components/HeaderMenu';

import { Container, TopBarContainer } from './styles';

interface HeaderProps {
  email: string;
  sideMenuCollapsed: boolean;
  mobileMenuIsOpen: boolean;
  logout: () => void;
  handleSideMenu: () => void;
  handleMobileMenu: () => void;
}

const Header: React.FC<HeaderProps> = ({
  email,
  sideMenuCollapsed,
  mobileMenuIsOpen,
  logout,
  handleSideMenu,
  handleMobileMenu,
}) => {
  return (
    <Container fixed="top" className="p-0">
      <div className="d-flex justify-content-between w-100 mobile">
        <HeaderLogo
          sideMenuCollapsed={sideMenuCollapsed}
          handleSideMenu={handleSideMenu}
          handleMobileMenu={handleMobileMenu}
        />

        <TopBarContainer className="px-4">
          <HeaderMenu
            email={email}
            logout={logout}
            mobileMenuIsOpen={mobileMenuIsOpen}
          />
          <div className="d-none d-lg-block" />
        </TopBarContainer>
      </div>
    </Container>
  );
};

export default Header;
