import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FaWindowRestore } from 'react-icons/fa';
import { Formik } from 'formik';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { addDays, format } from 'date-fns';
import pt from 'date-fns/locale/pt/index';
import * as Yup from 'yup';

import { StoreState } from 'store/createStore';

import swal from 'services/swal';
import hashIds from 'services/hashIds';
import api from 'services/api';

import useSites from 'hooks/useSites';
import useCustomer from 'hooks/useCustomer';
import useProduct from 'hooks/useProduct';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Error from 'components/Error';
import Loading from 'components/Loading';

import formatCurrency from 'utils/formatCurrency';

import { Container } from './styles';

interface MatchParams {
  idCustomer: string;
  idProduct: string;
}

type ProductProps = RouteComponentProps<MatchParams>;

type DueDate = {
  date: string;
  formattedDate: string;
};

type FormValues = {
  idSite: number;
  dueDate: string;
};

type SaleProductResponse = {
  data: {
    url: string;
  };
};

const Product: React.FC<ProductProps> = ({ match }) => {
  const { t } = useTranslation();

  const idProduct = Number(hashIds.decode(match.params.idProduct));
  const idCustomer = Number(hashIds.decode(match.params.idCustomer));

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { sites, loading: loadingSites, error: sitesError } = useSites(
    idRepresentante,
  );

  const {
    product,
    isLoading: loadingProduct,
    isError: productError,
  } = useProduct(idProduct);

  const {
    customer,
    isLoading: loadingCustomer,
    isError: customerError,
  } = useCustomer(idRepresentante, idCustomer);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [dueDates, setDueDates] = useState<DueDate[]>([]);
  const [customerSites, setCustomerSites] = useState<typeof sites>([]);

  useEffect(() => {
    const someError = productError || customerError || sitesError;

    setError(someError);
  }, [customerError, productError, sitesError]);

  useEffect(() => {
    const someLoading = loadingProduct || loadingCustomer || loadingSites;

    setLoading(someLoading);
  }, [loadingCustomer, loadingProduct, loadingSites]);

  useEffect(() => {
    const filteredSites = sites.filter(
      site => site.idCliente === idCustomer && site.ativo,
    );

    setCustomerSites(filteredSites);
  }, [idCustomer, sites]);

  useEffect(() => {
    const currentDate = new Date();

    const dates = [];

    for (let i = 3; i <= 10; i += 1) {
      const dueDate = addDays(currentDate, i);

      const formattedDate = `${format(dueDate, 'dd/MM/yyyy', {
        locale: pt,
      })} daqui a ${i} dias`;

      const date = format(dueDate, 'yyyy-MM-dd');

      dates.push({
        date,
        formattedDate,
      });
    }

    setDueDates(dates);
  }, []);

  async function submitForm(values: FormValues) {
    try {
      let selectedSite = null;

      if (values.idSite !== 0) {
        selectedSite = sites.find(site => site.idSite === values.idSite);
      }

      const saleProductResponse = await api.post<SaleProductResponse>(
        'clientes/v1/representante/venda/produto',
        {
          idRepresentante,
          idProduto: idProduct,
          idCliente: idCustomer,
          vencimento: values.dueDate,
          ...(selectedSite && { site: selectedSite.site }),
        },
      );

      const { url } = saleProductResponse.data.data;

      swal.fire(
        t('pages:productSale.successRegisteredSale'),
        t('pages:productSale.willBeRedirected'),
        'success',
      );

      window.location.href = url;
    } catch (err) {
      swal.fire({
        title: t('pages:productSale.saleFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        icon={<FaWindowRestore color="#FFFFFF" size={24} />}
        title={t('titles:customers.title')}
        description={t('titles:customers.description')}
      />

      <FormWrapper>
        <FormHeader
          title={`${t('pages:productSale.title')} ${customer.email1}`}
          description={t('pages:productSale.description')}
          helpContent={t('pages:productSale.helpContent.p1')}
          startOpen
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            idSite: customerSites.length > 0 ? customerSites[0].idSite : 0,
            dueDate: dueDates.length > 0 ? dueDates[0].date : '',
          }}
          validationSchema={Yup.object().shape({
            dueDate: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={submitForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:item')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control value={product.produto} plaintext readOnly />
                  </Col>
                </Form.Group>

                {customerSites.length > 0 && (
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('common:site')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        as="select"
                        name="idSite"
                        onChange={e =>
                          props.setFieldValue('idSite', Number(e.target.value))
                        }
                      >
                        {customerSites.map(site => (
                          <option
                            key={`customer-site-${site.idSite}`}
                            value={site.idSite}
                          >
                            {site.site}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                )}

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:price')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      value={formatCurrency(product.valor)}
                      plaintext
                      readOnly
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:dueDate')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      name="dueDate"
                      onChange={props.handleChange}
                    >
                      {dueDates.map(dueDate => (
                        <option
                          key={`due-date-${dueDate.date}`}
                          value={dueDate.date}
                        >
                          {dueDate.formattedDate}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting
                    ? t('common:sending')
                    : t('pages:productSale.buttons.chargeNow')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to={`/venda/${match.params.idCustomer}`}
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Product);
