import { action } from 'typesafe-actions';

import { Site } from './types';

export function loadSitesRequest() {
  return action('@sites/LOAD_SITES_REQUEST');
}

export function sitesSuccess({ sites }: { sites: Site[] }) {
  return action('@sites/SITES_SUCCESS', { sites });
}

export function loadSitesFailed() {
  return action('@sites/LOAD_SITES_FAILED');
}
