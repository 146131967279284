import { useQuery } from 'react-query';

import api from 'services/api';

interface ProductItemResponse {
  idProduto: number;
  idProdutoCategoria: number;
  categoria: string;
  produto: string;
  setup: string;
  valor: string;
  periodicidade: string;
}

interface ProductResponse {
  data: ProductItemResponse[];
}

interface Product {
  idProduto: number;
  idProdutoCategoria: number;
  categoria: string;
  produto: string;
  setup: string;
  valor: number;
  periodicidade: string;
}

const initialData: Product = {
  idProduto: 0,
  idProdutoCategoria: 0,
  categoria: '',
  produto: '',
  setup: '',
  valor: 0,
  periodicidade: '',
};

function formatProduct(productItem: ProductItemResponse): Product {
  const { valor, ...rest } = productItem;

  return {
    valor: Number(valor),
    ...rest,
  };
}

async function loadProduct(idProduct: number): Promise<Product> {
  const productsResponse = await api.get<ProductResponse>(
    `clientes/v1/produto/${idProduct}`,
  );

  const products = productsResponse.data.data.map(formatProduct);

  return products[0];
}

export default function useProduct(idProduct: number) {
  const { data, ...rest } = useQuery<Product>(
    ['product', idProduct],
    () => loadProduct(idProduct),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const product = data || initialData;

  return { product, ...rest };
}
