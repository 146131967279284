import { useQuery } from 'react-query';

import api from 'services/api';

interface ServiceItemResponse {
  idServico: number;
  idServicoCategoria: number;
  servico: string;
  setup: string;
  valor: string;
  periodicidade: 'SETUP' | 'MENSAL';
  requisito: string;
  categoria: string;
}

interface ServicesResponse {
  data: ServiceItemResponse[];
}

interface Services {
  idServico: number;
  idServicoCategoria: number;
  servico: string;
  setup: number;
  valor: number;
  periodicidade: 'SETUP' | 'MENSAL';
  requisito: string;
  categoria: string;
}

const initialData: Services[] = [];

async function loadServices(idRepresentante: number): Promise<Services[]> {
  const servicesResponse = await api.get<ServicesResponse>(
    `clientes/v1/representante/${idRepresentante}/servico`,
  );

  const formattedServices = servicesResponse.data.data.map(
    ({
      idServico,
      idServicoCategoria,
      servico,
      setup,
      valor,
      periodicidade,
      requisito,
      categoria,
    }) => ({
      idServico,
      idServicoCategoria,
      servico,
      setup: Number(setup),
      valor: Number(valor),
      periodicidade,
      requisito,
      categoria,
    }),
  );

  return formattedServices;
}

export default function useServices(idRepresentante: number) {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<
    Services[]
  >(['services', idRepresentante], () => loadServices(idRepresentante), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    retry: false,
  });

  const services = data || initialData;

  return { services, isError, isLoading, isFetching, refetch };
}
