import { useQuery } from 'react-query';

import api from 'services/api';

import formatCurrency from 'utils/formatCurrency';

interface CustomizedServiceItemResponse {
  idServico: number;
  idServicoCategoria: number;
  servico: string;
  setup: string;
  valor: string;
  periodicidade: 'SETUP' | 'MENSAL';
  requisito: string;
}

interface CustomizedServicesResponse {
  data: CustomizedServiceItemResponse[];
}

interface CustomizedServices {
  idServico: number;
  idServicoCategoria: number;
  servico: string;
  setup: number;
  valor: number;
  setupFormatado: string;
  valorFormatado: string;
  periodicidade: 'SETUP' | 'MENSAL';
  requisito: string;
}

const initialData: CustomizedServices[] = [];

async function loadCustomizedServices(
  idRepresentante: number,
): Promise<CustomizedServices[]> {
  const customizedServicesResponse = await api.get<CustomizedServicesResponse>(
    `clientes/v1/representante/${idRepresentante}/servico`,
    {
      params: {
        'filter[idServicoCategoria]': 4,
      },
    },
  );

  const formattedCustomizedServices = customizedServicesResponse.data.data.map(
    ({
      idServico,
      idServicoCategoria,
      servico,
      setup,
      valor,
      periodicidade,
      requisito,
    }) => ({
      idServico,
      idServicoCategoria,
      servico,
      setup: Number(setup),
      valor: Number(valor),
      setupFormatado: formatCurrency(Number(setup)),
      valorFormatado: formatCurrency(Number(valor)),
      periodicidade,
      requisito,
    }),
  );

  return formattedCustomizedServices;
}

export default function useCustomizedServices(idRepresentante: number) {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<
    CustomizedServices[]
  >(
    ['customizedServices', idRepresentante],
    () => loadCustomizedServices(idRepresentante),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const customizedServices = data || initialData;

  return { customizedServices, isError, isLoading, isFetching, refetch };
}
