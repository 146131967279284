import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaFileCsv, FaFilePdf } from 'react-icons/fa';

import JsPdf from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Container, CsvButton, PdfButton } from './styles';

function convertToCsv<T>(
  data: T[],
  headers: { key: keyof T; label: string }[],
) {
  const csvRows = [];

  const headerRow = headers.map(header => header.label).join(',');
  csvRows.push(headerRow);

  data.forEach(dd => {
    const csvRow = headers
      .map(header => (dd[header.key] !== null ? `"${dd[header.key]}"` : ''))
      .join(',');
    csvRows.push(csvRow);
  });

  return csvRows.join('\n');
}

function downloadCsv(fileName: string, csvContent: string) {
  const link = document.createElement('a');

  const filename = `${fileName}.csv`;

  if (!csvContent.match(/^data:text\/csv/i)) {
    csvContent = `data:text/csv;charset=utf-8,${csvContent}`;
  }

  link.setAttribute('href', encodeURI(csvContent));
  link.setAttribute('download', filename);
  link.click();

  link.parentNode?.removeChild(link);
}

function downloadPdf<T>(
  fileName: string,
  data: T[],
  headers: { key: keyof T; label: string }[],
) {
  const doc = new JsPdf();

  const tableColumn = headers.map(header => header.label);
  const tableRows: string[][] = [];

  data.forEach(item => {
    const rowData = headers.map(header => String(item[header.key]));
    tableRows.push(rowData);
  });

  autoTable(doc, {
    head: [tableColumn],
    body: tableRows,
  });

  doc.save(`${fileName}.pdf`);
}

interface TableExportContentProps<T> {
  data: T[];
  // visibleData: T[];
  headers: { key: keyof T; label: string }[];
  fileName: string;
}

const TableExportContent = <T,>({
  data,
  headers,
  fileName,
}: TableExportContentProps<T>) => {
  return (
    <Container>
      <Card.Body>
        <Row>
          <Col className="border-right">
            <p className="d-flex flex-row">
              <FaFileCsv size={24} color="#30a96e" />
              <span className="mr-1 mt-1 font-weight-bold text-success export-type">
                Excel/CSV
              </span>
              <span className="mt-1 font-weight-bold text-secondary">
                (.csv)
              </span>
            </p>
            {/*
            <CsvButton
              type="button"
              className="mr-1"
              onClick={() => {
                downloadCsv(fileName, convertToCsv(visibleData, headers));
              }}
            >
              Linhas visíveis
            </CsvButton>
            */}
            <CsvButton
              type="button"
              onClick={() => {
                downloadCsv(fileName, convertToCsv(data, headers));
              }}
            >
              Todas as linhas
            </CsvButton>
          </Col>

          <Col className="border-left">
            <p className="d-flex flex-row">
              <FaFilePdf color="#FF5370" size={24} />
              <span className="mr-1 mt-1 font-weight-bold pdf-label export-type">
                PDF/Imprimir
              </span>
              <span className="font-weight-bold mt-1 text-secondary">
                (.pdf)
              </span>
            </p>
            <PdfButton
              type="button"
              className="btn-sm"
              onClick={() => downloadPdf(fileName, data, headers)}
            >
              Todas as linhas
            </PdfButton>
          </Col>
        </Row>
      </Card.Body>
    </Container>
  );
};

export default TableExportContent;
