import { useQuery } from 'react-query';

import api from 'services/api';

interface CustomizedServiceItemResponse {
  idServico: number;
  idServicoCategoria: number;
  servico: string;
  setup: string;
  valor: string;
  periodicidade: 'SETUP' | 'MENSAL';
  requisito: string;
}

interface CustomizedServicesResponse {
  data: CustomizedServiceItemResponse[];
}

interface CustomizedService {
  idServico: number;
  idServicoCategoria: number;
  servico: string;
  setup: number;
  valor: number;
  periodicidade: 'SETUP' | 'MENSAL';
  requisito: string;
}

const initialData: CustomizedService = {
  idServico: 0,
  idServicoCategoria: 0,
  servico: '',
  setup: 0,
  valor: 0,
  periodicidade: 'SETUP',
  requisito: '',
};

async function loadCustomizedService(
  idRepresentante: number,
  idService: number,
): Promise<CustomizedService> {
  const customizedServicesResponse = await api.get<CustomizedServicesResponse>(
    `clientes/v1/representante/${idRepresentante}/servico`,
    {
      params: {
        'filter[idServicoCategoria]': 4,
        'filter[idServico]': idService,
      },
    },
  );

  const formattedCustomizedServices = customizedServicesResponse.data.data.map(
    ({
      idServico,
      idServicoCategoria,
      servico,
      setup,
      valor,
      periodicidade,
      requisito,
    }) => ({
      idServico,
      idServicoCategoria,
      servico,
      setup: Number(setup),
      valor: Number(valor),
      periodicidade,
      requisito,
    }),
  );

  const service =
    formattedCustomizedServices.length > 0
      ? formattedCustomizedServices[0]
      : initialData;

  return service;
}

export default function useCustomizedService(
  idRepresentante: number,
  idService: number,
) {
  const {
    data,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<CustomizedService>(
    ['customizedService', idService],
    () => loadCustomizedService(idRepresentante, idService),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const customizedService = data || initialData;

  return { customizedService, isError, isLoading, isFetching, refetch };
}
