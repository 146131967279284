import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { FaServer } from 'react-icons/fa';
import { Card, Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';
import BrlCurrencyInput from 'components/BrlCurrencyInput';

import swal from 'services/swal';
import api from 'services/api';
import toast from 'services/toast';
import history from 'services/history';

import { StoreState } from 'store/createStore';

import useCustomizedServices from 'hooks/useCustomizedServices';

import { Container } from './styles';

interface NewServiceFormValues {
  name: string;
  price: string;
  frequency: 'MENSAL' | 'SETUP';
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const { idRepresentante } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { refetch: refetchServices } = useCustomizedServices(idRepresentante);

  async function createCustomizedService(values: NewServiceFormValues) {
    try {
      const formattedPrice = values.price.replace(/\./, '').replace(/,/, '.');

      await api.post(`clientes/v1/representante/${idRepresentante}/servico`, {
        servico: values.name,
        periodicidade: values.frequency,
        valor: formattedPrice,
      });

      refetchServices();

      toast.fire(t('pages:customizedServicesNew.success'));

      history.push(`/servicos/customizados`);
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  return (
    <Container>
      <PageTitle
        icon={<FaServer color="#FFFFFF" size={24} />}
        title={t('titles:services.title')}
        description={t('titles:services.description')}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:customizedServicesNew.title')}
          description={t('pages:customizedServicesNew.description')}
          helpContent={
            <div>
              <p>{t('pages:customizedServicesNew.helpContent.p1')}</p>
            </div>
          }
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            name: '',
            price: '00,001',
            frequency: 'SETUP',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(t('validations:requiredField')),
            frequency: Yup.string().oneOf(['SETUP', 'MENSAL']),
          })}
          onSubmit={createCustomizedService}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:customizedServicesNew.legend')}
                />

                <Form.Group as={Row} controlId="newService.name">
                  <Form.Label column sm={2} className="required">
                    {t('common:name')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="name"
                      value={props.values.name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.name && !!props.errors.name}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newService.price">
                  <Form.Label column sm={2}>
                    {t('pages:customizedServicesNew.price')}
                  </Form.Label>
                  <Col sm={10}>
                    <BrlCurrencyInput
                      id="price"
                      name="price"
                      value={props.values.price}
                      onValueChange={val => props.setFieldValue('price', val)}
                      onBlur={props.handleBlur}
                    />
                    <Form.Text className="text-muted" />
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {props.errors.price}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:customizedServicesNew.frequency')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      key="frequency-setup"
                      id="frequency-setup"
                      name="frequency"
                      label={t('pages:customizedServicesNew.uniqueValue')}
                      value="SETUP"
                      checked={props.values.frequency === 'SETUP'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.frequency && !!props.errors.frequency
                      }
                      disabled={props.isSubmitting}
                    />

                    <Form.Check
                      inline
                      custom
                      type="radio"
                      key="frequency-monthly"
                      id="frequency-monthly"
                      name="frequency"
                      label={t('pages:customizedServicesNew.monthly')}
                      value="MENSAL"
                      checked={props.values.frequency === 'MENSAL'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.frequency && !!props.errors.frequency
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.frequency}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/servicos/customizados"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
