import { action } from 'typesafe-actions';

export function authorizationRequest({
  token,
  scope,
}: {
  token: string;
  scope: string;
}) {
  return action('@auth/AUTHORIZATION_REQUEST', { token, scope });
}

export function authorizationSuccess({
  accessToken,
  refreshToken,
  userId,
  expiresIn,
}: {
  accessToken: string;
  refreshToken: string;
  userId: number;
  expiresIn: number;
}) {
  return action('@auth/AUTHORIZATION_SUCCESS', {
    accessToken,
    refreshToken,
    userId,
    expiresIn,
  });
}

export function authorizationFailure() {
  return action('@auth/AUTHORIZATION_FAILURE');
}

export function logout() {
  return action('@auth/LOGOUT');
}
