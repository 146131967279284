import React from 'react';
import { InputGroup } from 'react-bootstrap';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

const BrlCurrencyInput: React.FC<CurrencyInputProps> = ({
  onValueChange,
  ...rest
}) => {
  function formatValue(val: string | undefined) {
    let price = '';

    if (val !== '' && val !== undefined) {
      price = val.search(/^[.,]/) === 0 ? `0${val}` : val;
    }

    if (onValueChange) {
      onValueChange(price);
    }
  }

  return (
    <InputGroup className="mr-sm-2" size="sm">
      <InputGroup.Prepend>
        <InputGroup.Text>R$</InputGroup.Text>
      </InputGroup.Prepend>
      <CurrencyInput
        maxLength={12}
        decimalsLimit={2}
        decimalScale={2}
        onValueChange={formatValue}
        allowNegativeValue={false}
        className="form-control"
        intlConfig={{ locale: 'pt-br' }}
        {...rest}
      />
    </InputGroup>
  );
};

export default BrlCurrencyInput;
