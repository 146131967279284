import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaGlobeAmericas } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { format, parseISO } from 'date-fns';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import filter from 'utils/filter';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import Error from 'components/Error';
import TableNoData from 'components/TableNoData';
import TableIconButton from 'components/TableIconButton';

import useDomains from 'hooks/useDomains';

import { Container } from './styles';

interface Domain {
  contato: number;
  dominio: string;
  emailCliente: string;
  expiracao: string;
  idCliente: number;
  status: string;
}

const Home: React.FC = () => {
  const { t } = useTranslation();

  const client = useSelector((state: StoreState) => state.client.info);

  const [error, setError] = useState(false);

  const [filterEnomText, setFilterEnomText] = useState('');
  const [filteredEnom, setFilteredEnom] = useState<Domain[]>([]);

  const [filterExpiredText, setFilterExpiredText] = useState('');
  const [filteredExpired, setFilteredExpired] = useState<Domain[]>([]);

  const [filterRrpproxyText, setFilterRrpproxyText] = useState('');
  const [filteredRrpproxy, setFilteredRrpproxy] = useState<Domain[]>([]);

  const [filterRegistroBrText, setFilterRegistroBrText] = useState('');
  const [filteredRegistroBr, setFilteredRegistroBr] = useState<Domain[]>([]);

  const { domains, loading } = useDomains(client.idRepresentante);
  const { expirados, enom, rrpproxy, registrobr } = domains;

  useEffect(() => {
    if (expirados) {
      const filtered = filter(
        expirados,
        ['dominio', 'status', 'contato', 'expiracao', 'emailCliente'],
        filterExpiredText,
      );
      setFilteredExpired(filtered);
    }
  }, [expirados, filterExpiredText]);

  useEffect(() => {
    if (enom) {
      const filtered = filter(
        enom,
        ['dominio', 'status', 'contato', 'expiracao', 'emailCliente'],
        filterEnomText,
      );
      setFilteredEnom(filtered);
    }
  }, [enom, filterEnomText]);

  useEffect(() => {
    if (rrpproxy) {
      const filtered = filter(
        rrpproxy,
        ['dominio', 'status', 'contato', 'expiracao', 'emailCliente'],
        filterRrpproxyText,
      );
      setFilteredRrpproxy(filtered);
    }
  }, [rrpproxy, filterRrpproxyText]);

  useEffect(() => {
    if (registrobr) {
      const filtered = filter(
        registrobr,
        ['dominio', 'status', 'contato', 'expiracao', 'emailCliente'],
        filterRegistroBrText,
      );
      setFilteredRegistroBr(filtered);
    }
  }, [registrobr, filterRegistroBrText]);

  const loginRep = useCallback(
    async domain => {
      try {
        setError(false);
        const retorno = await api.post(`painel/v1/representante/login`, {
          idRepresentante: client.idRepresentante,
          idCliente: domain.idCliente,
          target: 'registro/dominios',
        });
        window.open(retorno.data.data.url, '_blank');
      } catch (err) {
        setError(true);
      }
    },
    [client],
  );

  const columns = [
    {
      name: t('common:domain'),
      selector: 'dominio',
      sortable: true,
      minWidth: '280px',
      cell: (row: Domain) => {
        const { dominio } = row;
        return (
          <div className="d-flex justify-content-between w-100 align-items-center">
            <div>{dominio}</div>
            <TableIconButton
              tooltipConfig={{
                id: 'displayDns',
                placement: 'right',
                content: t('pages:domains.register'),
              }}
              onClick={() => loginRep(row)}
            >
              <FaGlobeAmericas />
            </TableIconButton>
          </div>
        );
      },
    },
    {
      name: t('common:status'),
      selector: 'status',
      cell: (row: Domain) => {
        const { status } = row;
        const text =
          status === 'AGUARDANDO_PUBLICACAO'
            ? 'Aguardando publicação'
            : status.toLowerCase();
        return <span className="text-capitalize">{text}</span>;
      },
      sortable: true,
      maxWidth: '10%',
      minWidth: '100px',
    },
    {
      name: t('common:contact'),
      selector: 'contato',
      maxWidth: '10%',
      minWidth: '100px',
      sortable: true,
    },
    {
      name: t('common:expiration'),
      selector: 'expiracao',
      cell: (row: Domain) =>
        row.expiracao ? format(parseISO(row.expiracao), 'dd/MM/yyyy') : '',
      maxWidth: '10%',
      minWidth: '140px',
      sortable: true,
    },
    {
      name: t('common:customer'),
      selector: 'emailCliente',
      maxWidth: '15%',
      minWidth: '280px',
      sortable: true,
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
        title={t('titles:domains.title')}
        description={t('titles:domains.description')}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:domains.titleExpired')}
          description={t('pages:domains.descriptionExpired')}
          helpContent={
            <div>
              <p>{t('pages:domains.helpContent.p1')}</p>
            </div>
          }
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setFilterExpiredText(value)} />
          </div>
        </TableSubHeader>
        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            columns={columns}
            data={filteredExpired}
            progressPending={loading}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:domains.titleEnom')}
          description={t('pages:domains.descriptionEnom')}
          helpContent={
            <div>
              <p>{t('pages:domains.helpContent.p1')}</p>
            </div>
          }
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setFilterEnomText(value)} />
          </div>
        </TableSubHeader>
        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            columns={columns}
            data={filteredEnom}
            progressPending={loading}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:domains.titleRegistroBr')}
          description={t('pages:domains.descriptionRegistroBr')}
          helpContent={
            <div>
              <p>{t('pages:domains.helpContent.p1')}</p>
            </div>
          }
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setFilterRegistroBrText(value)} />
          </div>
        </TableSubHeader>
        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            columns={columns}
            data={filteredRegistroBr}
            progressPending={loading}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:domains.titleRrpproxy')}
          description={t('pages:domains.descriptionRrpproxy')}
          helpContent={
            <div>
              <p>{t('pages:domains.helpContent.p1')}</p>
            </div>
          }
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setFilterRrpproxyText(value)} />
          </div>
        </TableSubHeader>
        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            columns={columns}
            data={filteredRrpproxy}
            progressPending={loading}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default Home;
